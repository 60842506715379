import { StateChangeOptions } from 'downshift';
import { debounce } from 'lodash';
import * as React from 'react';

import { dropdownFetchesOptionsFor } from 'components/select/features';
import { Option, InputSelect } from 'components/select';

function dropdownInjectsValueTo<T>(Component: React.ComponentType<any>) {
  return ({
    value: injectedValue,
    options,
    optionsReady,
    loadOptions,
    onChange,
    wait = 700,
    ...restProps
  }: {
    value: T | null;
    onChange: (value: T | null) => void;
    options: Option<T>[];
    optionsReady: boolean;
    loadOptions: (key: string | null) => {};
    wait?: number;
  }) => {
    const [inputVal, setInputVal] = React.useState<string>('');
    const selectedItemRef = React.useRef<Option<T> | null>(null);

    const onPhraseChange = React.useMemo(
      () => debounce(loadOptions, wait),
      [loadOptions],
    );
    const handleInputChange = React.useCallback(
      (value: string) => {
        setInputVal(value);
        onPhraseChange(value);
      },
      [onPhraseChange],
    );

    const getItemLabel = React.useCallback(
      (item: Option<T> | null): string => item?.label || '',
      [],
    );
    const handleInputBlur = React.useCallback(() => {
      setInputVal(getItemLabel(selectedItemRef.current));
    }, [getItemLabel]);

    const selectItem = React.useCallback(
      (item: Option<T> | null) => {
        selectedItemRef.current = item;
        onChange(item?.value || null);
        setInputVal(getItemLabel(item));
      },
      [onChange, getItemLabel],
    );
    const handleStateChange = React.useCallback(
      (changes: StateChangeOptions<Option<T>>) => {
        const { selectedItem, isOpen } = changes;
        if (typeof selectedItem === 'object') {
          selectItem(selectedItem);
        }
        if (typeof isOpen === 'boolean') {
          if (isOpen) {
            setInputVal('');
            loadOptions('');
          }
        }
      },
      [loadOptions, selectItem],
    );

    React.useEffect(() => {
      const getItemFromValue = (value: T | null) =>
        options.find((option) => option.value === value);

      const monitorValueInjection = () => {
        if (!optionsReady) return;

        const selectedValue = selectedItemRef.current
          ? selectedItemRef.current.value
          : null;
        if (injectedValue === selectedValue) return;

        const itemToBeReplacedWith = getItemFromValue(injectedValue);
        if (itemToBeReplacedWith) {
          selectItem(itemToBeReplacedWith);
        } else {
          if (injectedValue === ('' as unknown as T)) {
            // value can be '' in form initially, which does not make sense,
            // we accept this for now but we should update initial value to null instead of '' in future
            selectedItemRef.current = null;
          } else {
            setInputVal('');
          }
        }
      };

      monitorValueInjection();
    }, [injectedValue, getItemLabel, options, optionsReady, selectItem]);

    return (
      <Component
        inputVal={inputVal}
        options={options}
        selectedItem={selectedItemRef.current}
        onStateChange={handleStateChange}
        optionsReady={optionsReady}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        {...restProps}
      />
    );
  };
}

const FetchSelect = dropdownFetchesOptionsFor(
  dropdownInjectsValueTo(InputSelect),
);

export default FetchSelect;
