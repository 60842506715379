import * as React from 'react';
import { FieldTemplate, Supplier, SupplierContact } from '@contractool/schema';
import { Dropdown } from 'components/Dropdown';
import FetchSelect from 'components/select/FetchSelect';
import { Form, FormContext } from 'components/Form';
import { SupplierContactModal } from 'components/form/SupplierContactModal';
import { dropdownPreSelectsFirstOptionOf } from 'features/form/dropdown';
import { usePrevious } from 'hooks';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { SupplierNew } from 'views/suppliers/SuppliersNew';
import ProjectFormContext from 'views/project/ProjectFormContext';
import { AppContext, GuardContext } from 'contexts';
import { userPermissionRequest } from 'utils/wildcard';
import { useContext } from 'react';
import { Workflow } from '../../../../_common/schema/Workflow';
import { EventEmitter } from 'utils/eventEmitter';

const getOptions = (entities: any[]) =>
  entities.map((entity) => ({
    label: entity.name,
    value: entity.id,
  }));

const SupplierContactDropdown = dropdownPreSelectsFirstOptionOf(Dropdown);

const SupplierSelect: React.FC<{
  name: string;
  required?: boolean;
  field?: FieldTemplate;
  workflow?: Workflow;
  apiParams?: any;
}> = ({ name, required, field, workflow, apiParams }) => {
  const { values: formValues, handleChange: handleFieldChange } =
    React.useContext(FormContext);
  const { config } = useContext(AppContext);
  const [supplier, setSupplier] = React.useState<Supplier | undefined>(
    undefined,
  );
  const [newSupplierModalOpened, setNewSupplierModalOpened] =
    React.useState<boolean>(false);
  const [pocPopupOpened, setPocPopupOpened] = React.useState<boolean>(false);
  const getSupplier = React.useCallback((supplier_id) => {
    if (!supplier_id) return;
    http.get<Supplier>(`/api/suppliers/${supplier_id}`).then((response) => {
      setSupplier(response.data);
    });
  }, []);

  function moveDefaultToFirst(array) {
    const index = array.findIndex((obj) => obj.is_default);
    if (index !== -1) {
      const obj = array[index];
      array.splice(index, 1);
      array.unshift(obj);
    }
    return array;
  }

  const [supplierContacts, setSupplierContacts] = React.useState<
    SupplierContact[]
  >([]);
  const getSupplierContacts = React.useCallback(
    (supplier_id) => {
      const newFormValues = JSON.parse(JSON.stringify(formValues));
      newFormValues.fields[`${name}`] = supplier_id;

      EventEmitter.dispatch(
        (formValues.id ? 'edit-project' : 'new-project') + '.set-form-values',
        newFormValues,
      );
      if (!supplier_id) return;
      http
        .get<SupplierContact[]>(`/api/suppliers/${supplier_id}/contacts`)
        .then((response) => {
          setSupplierContacts(moveDefaultToFirst(response.data));
        });
    },
    [formValues, name],
  );

  // re-fetches supplier and contacts when supplier_id changes
  React.useEffect(() => {
    if (formValues.supplier_id) {
      if (Object.keys(formValues?.supplier_id).length > 0) {
		// If supplier_id is an object, it means that the supplier was created in the same form
        getSupplier(formValues.supplier_id.value);
        getSupplierContacts(formValues.supplier_id.value);
      } else {
		// If supplier_id is a string, it means that the supplier was get by server and has only ID
        getSupplier(formValues.supplier_id);
        getSupplierContacts(formValues.supplier_id);
      }
    }
    // eslint-disable-next-line
  }, [formValues.supplier_id]);

  // reset supplier_contact_id to '' when supplier_id changes
  const previousSupplierId = usePrevious(formValues.supplier_id);
  React.useEffect(() => {
    if (previousSupplierId && previousSupplierId !== formValues.supplier_id) {
      setSupplierContacts([]);
      handleFieldChange('supplier_contact_id', '');
    }
  }, [previousSupplierId, formValues.supplier_id, handleFieldChange]);

  const { updateSupplierTitle } = React.useContext(ProjectFormContext);

  React.useEffect(() => {
    if (supplier?.title) {
      updateSupplierTitle(supplier.title);
    }
    // eslint-disable-next-line
  }, [supplier]);

  // set supplier title to textinput field when supplier_id changes
  React.useEffect(() => {
    if (previousSupplierId != undefined) {
      if (previousSupplierId !== formValues.supplier_id) {
        EventEmitter.dispatch('textinput.fields.' + name, supplier?.title);
      }
    }
  }, [handleFieldChange]);

  const allApiParams = React.useMemo(
    () => ({
      ...apiParams,
      phrase: supplier?.title || '',
    }),
    [supplier],
  );

  const { user } = useContext(GuardContext);
  const canCreateNew =
    userPermissionRequest(user, 'suppliers.new') &&
    field?.extra.disable_create !== true;

  // need to check supplier?.id === formValues.supplier_id because when user change supplier_id using dropdown, const supplier keeps old supplier data until response arrive

  function resolveRequired(required: boolean, poc_optional: boolean) {
    if (!required) {
      return false;
    } else return !(required && poc_optional);
  }


  return (
    <>
      <Form.Multiselect2
        endpoint="/api/suppliers-autocomplete"
		extra={allApiParams}
        className="mb-6"
        label={field && field?.label ? field.label : ''}
        legend={field && field.legend ? translate(field.legend) : ''}
        helptext={field && field.helptext ? translate(field.helptext) : ''}
        name="supplier_id"
        required={required}
		defaultValue={supplier?.title}
        isMulti={false}
        options={[]}
        placeholder="Select Supplier"
        right={
          canCreateNew ? (
            <span
              onClick={() => {
                setNewSupplierModalOpened(true);
              }}
              data-testid="new_supplier_button"
              className="text-blue-600 hover:text-blue-300 cursor-pointer"
            >
              {'+ ' + translate('New ' + config.config.supplier_label_singular)}
            </span>
          ) : null
        }
      />
      {workflow?.settings.supplier_poc && (
        <Form.Field
          autocomplete
          className="mb-6"
          component={SupplierContactDropdown}
          label={translate(
            config.config.supplier_label_singular + ' point of contact',
          )}
          legend={
            field && field.extra && field.extra.poc_legend
              ? translate(field.extra.poc_legend)
              : ''
          }
          helptext={
            field && field.extra && field.extra.poc_helptext
              ? translate(field.extra.poc_helptext)
              : ''
          }
          name="supplier_contact_id"
          options={getOptions(supplierContacts) || [{ label: '', value: '' }]}
          required={resolveRequired(required, field.extra.poc_optional)}
          right={
            formValues.supplier_id ? (
              <span
                onClick={() => {
                  setPocPopupOpened(true);
                }}
                data-testid="new_supplier_poc_button"
                className="text-blue-600 hover:text-blue-300 cursor-pointer"
              >
                {`+ ${translate('Add contact')}`}
              </span>
            ) : null
          }
        />
      )}
      {newSupplierModalOpened && (
        <SupplierNew
          onCreate={(supplier: Supplier | null) => {
            if (supplier?.id) {
              handleFieldChange('supplier_id', supplier.id);
            }
          }}
          setModalOpened={setNewSupplierModalOpened}
        />
      )}
      {formValues.supplier_id && supplier && pocPopupOpened && (
        <SupplierContactModal
          label={translate('Add contact')}
          contact={{
            name: '',
            email: '',
            phone: '',
            id: 0,
            supplier_id: formValues.supplier_id,
            url: '',
            fields: {},
            is_default: false,
          }}
          onSubmit={(supplierContactValues) =>
            http.post(supplier.contacts_url, supplierContactValues)
          }
          onCancel={() => {
            setPocPopupOpened(false);
          }}
          onSuccess={(contact: SupplierContact) => {
            handleFieldChange('supplier_contact_id', contact.id);
            getSupplierContacts(contact.supplier_id);
            setPocPopupOpened(false);
          }}
        />
      )}
    </>
  );
};
export default SupplierSelect;
