import { CustomReportFilter } from '@contractool/schema';
import * as React from 'react';

import { Context as ReportContext } from 'contexts/reports/tab/report';
import { Option } from 'components/Dropdown';
import { Icon } from 'components/Icon';
import { MultiselectDropdown } from 'components/MultiselectDropdown';
import FetchMultiSelect from 'components/select/FetchMultiSelect';
import { cx } from 'utils/classnames';
import { translate } from 'utils/translations';
import DateRangeSelect from './DateRangeSelect';
import Menu from './Menu';
import FiltersMap from './FiltersMap';
import DateRangeInputs from 'components/reports/tab/Report/Filters/DateRangeInputs';
import DateMoreLess from 'components/reports/tab/Report/Filters/DateMoreLess';

const ANIMATION_DURATION = 0.3;

const rightSidebarStyleCollapsed = {
  transition: `width ${ANIMATION_DURATION}s`,
};

const rightSidebarStyleOpened = {
  transition: `width ${ANIMATION_DURATION}s`,
  minHeight: 500,
  zIndex: 1000,
  position: 'relative',
};

const Filters = () => {
  const { config, update: updateReport } = React.useContext(ReportContext);
  const [mapMenu, setMapMenu] = React.useState(false);

  const collapsed = config.collapsed || false;
  const invertCollapse = React.useCallback(async () => {
    await updateReport({
      ...config,
      collapsed: !collapsed,
    });
  }, [collapsed, config, updateReport]);

  const handleFilterCreate = React.useCallback(async () => {
    setMapMenu(true);
    const filters = config.filters || [];
    await updateReport({
      ...config,
      filters: [
        ...filters,
        {
          property: '',
          values: null,
        },
      ],
    });
  }, [config, updateReport]);

  const handleFilterUpdate = React.useCallback(
    async (updatedFilter: CustomReportFilter, idx: number) => {
      const filters = config.filters || [];
      const updatedFilters = [...filters];
      updatedFilters[idx] = updatedFilter;

      await updateReport({
        ...config,
        filters: updatedFilters,
      });
    },
    [config, updateReport],
  );

  const handleFilterDelete = React.useCallback(
    async (idx: number) => {
      const filters = config.filters || [];
      const updatedFilters = [...filters];
      updatedFilters.splice(idx, 1);

      await updateReport({
        ...config,
        filters: updatedFilters,
      });
    },
    [config, updateReport],
  );

  const filterCount = (config.filters || []).length;

  return (
    <div className="flex absolute right-0 top-0 h-full py-3">
      <div className={cx('flex', 'pt-4', 'p-3', collapsed ? '' : 'opacity-0')}>
        <Icon
          name="drag_handle"
          size={6}
          className="drag-handle text-gray-600 mr-2 hover:text-blue-700 cursor-move"
        />
        <Menu />
      </div>
      <div
        className={cx(
          'border-l',
          'bg-white',
          'p-3',
          'overflow-hidden',
          'overflow-y-auto',
          'mr-1',
          collapsed ? 'w-15' : 'w-120',
          collapsed ? '' : '-mt-2',
          collapsed ? '' : 'border-b',
          collapsed ? '' : 'shadow-lg',
          collapsed ? '' : 'rounded-xl',
        )}
        style={collapsed ? rightSidebarStyleCollapsed : rightSidebarStyleOpened}
      >
        <div className="flex justify-between">
          <div onClick={invertCollapse} className="cursor-pointer">
            {collapsed ? (
              <FilterIcon filterCount={filterCount} />
            ) : (
              <Icon
                name="menu_open"
                className={`${
                  filterCount > 0 ? 'text-blue-700' : 'text-gray-600'
                } rotate-180 hover:text-blue-700`}
                size={8}
              />
            )}
          </div>
          <div onClick={handleFilterCreate} className="cursor-pointer">
            {!collapsed && (
              <Icon
                name="add"
                className="text-gray-600 hover:text-blue-700"
                size={8}
              />
            )}
          </div>
        </div>
        <ul className={`${collapsed ? 'hidden' : 'block'}`}>
          {(config.filters || []).map(
            (filter: CustomReportFilter, idx: number) => (
              <li key={idx} className="mt-10">
                <FilterListItem
                  idx={idx}
                  filter={filter}
                  onUpdate={handleFilterUpdate}
                  onDelete={handleFilterDelete}
                />
              </li>
            ),
          )}
        </ul>
        {mapMenu && (
          <FiltersMap
            filters={config.filters || []}
            handleFilterUpdate={handleFilterUpdate}
            idx={config?.filters?.length ? config?.filters?.length - 1 : 0}
            setOpenModal={setMapMenu}
            workflow={config.workflow}
          />
        )}
      </div>
    </div>
  );
};

export default Filters;

const FilterListItem: React.FC<{
  filter: CustomReportFilter;
  idx: number;
  onUpdate: (updatedFilter: CustomReportFilter, idx: number) => void;
  onDelete: (idx: number) => void;
}> = ({ filter, idx, onUpdate, onDelete }) => {
  const handleUpdate = React.useCallback(
    (updatedFilter: CustomReportFilter) => {
      onUpdate(updatedFilter, idx);
    },
    [onUpdate, idx],
  );

  const handleDelete = React.useCallback(() => {
    onDelete(idx);
  }, [onDelete, idx]);

  return (
    <Filter
      filter={filter}
      handleFilterUpdate={onUpdate}
      idx={idx}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

const Filter: React.FC<{
  filter: CustomReportFilter;
  onUpdate: (updatedFilter: CustomReportFilter) => void;
  onDelete: () => void;
  handleFilterUpdate: (updatedFilter: CustomReportFilter, idx: number) => void;
  idx: number;
}> = ({ filter, idx, onUpdate, onDelete, handleFilterUpdate }) => {
  const { config, groupedFilterTemplates } = React.useContext(ReportContext);

  const [filterValue, setFilterValue] = React.useState<string | null>(
    filter.values && filter.values?.length > 0 ? filter.values[0] : '',
  );
  const [mapMenu, setMapMenu] = React.useState(false);

  const { property, values } = filter;
  const valueOptions =
    property in groupedFilterTemplates
      ? groupedFilterTemplates[property][0].values || []
      : [];
  const propertyLabel =
    property in groupedFilterTemplates
      ? groupedFilterTemplates[property][0].label
      : '';
  const getFilterType = (property: string) =>
    property in groupedFilterTemplates
      ? groupedFilterTemplates[property][0].type
      : '';

  const filterType = getFilterType(property);

  const [propertyDropdownVisible, setPropertyDropdownVisible] =
    React.useState<boolean>(!property);

  // lock property when property is changed
  React.useEffect(() => {
    setPropertyDropdownVisible(!property);
  }, [property]);

  const handleValuesChange = (values: Option<string>[]) => {
    setFilterValue(values[0]?.value);
    onUpdate({
      ...filter,
      values: values.map((option: Option<string>) => option.value),
    });
  };

  const cachedValues = React.useMemo(() => values || [], [values]);

  return (
    <div className="flex items-start custom-report__filter">
      <div className="flex-auto">
        <div
          className={cx(
            'custom-report__filter-property',
            !propertyDropdownVisible ? 'bg-gray-150' : '',
          )}
        >
          {propertyDropdownVisible ? (
            <>
              <div
                className="h-16 w-full cursor-pointer hover:bg-gray-150 flex items-center focus:outline-none"
                onClick={() => {
                  setMapMenu(true);
                }}
              >
                {propertyLabel?.length ? propertyLabel : 'Property'}
              </div>
            </>
          ) : (
            <div
              className="cursor-pointer h-16 py-5"
              onClick={() => {
                setMapMenu(true);
              }}
            >
              {propertyLabel?.length ? propertyLabel : 'Property'}
            </div>
          )}
          {mapMenu && (
            <FiltersMap
              filters={config.filters ?? []}
              handleFilterUpdate={handleFilterUpdate}
              idx={idx}
              setOpenModal={setMapMenu}
              workflow={config.workflow}
            />
          )}
        </div>
        <div className="custom-report__filter-values">
          {property === 'supplier_id' ? (
            <FetchMultiSelect
              api="/api/suppliers"
              values={cachedValues}
              placeholder={translate('Values')}
              searchPlaceholder={`${translate('Search values')}...`}
              onChange={handleValuesChange}
              usePortal={true}
            />
          ) : 'DATE_RANGE' === filterType ? (
            <>
              <DateRangeSelect
                values={cachedValues}
                onChange={handleValuesChange}
              />
              {filterValue === 'custom_range' && (
                <DateRangeInputs onUpdate={onUpdate} filter={filter} />
              )}
              {(filterValue === 'more_than' || filterValue === 'less_than') && (
                <DateMoreLess onUpdate={onUpdate} filter={filter} />
              )}
            </>
          ) : property && property.match(/teamMemberships/) ? (
            <>
              <FetchMultiSelect
                api="/api/users"
                name="values"
                values={cachedValues}
                placeholder={translate('Values')}
                searchPlaceholder={`${translate('Search users')}...`}
                onChange={handleValuesChange}
                usePortal={true}
              />
            </>
          ) : (
            <MultiselectDropdown
              name="values"
              onChange={handleValuesChange}
              options={valueOptions}
              placeholder={translate('Values ' + property)}
              searchPlaceholder={`${translate('Search values')}...`}
              useSearch={true}
              values={cachedValues}
            />
          )}
        </div>
      </div>
      <div className="ml-3 custom-report__filter-close" onClick={onDelete}>
        <Icon
          className="cursor-pointer mt-3 text-gray-600"
          name="close"
          size={4}
        />
      </div>
    </div>
  );
};

const FilterIcon: React.FC<{ filterCount: number }> = ({ filterCount }) => {
  return (
    <div className="relative">
      <Icon
        name="filter_list"
        className={`${
          filterCount ? 'text-blue-700' : 'text-gray-600'
        } hover:text-blue-700`}
        size={8}
      />
      {filterCount > 0 && (
        <div
          className="absolute text-blue-700"
          style={{ fontSize: 11, right: 0, top: '12px' }}
        >
          <div>{filterCount}</div>
        </div>
      )}
    </div>
  );
};
