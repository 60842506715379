import React from 'react';

import { getColors } from './IconCircle';

export type Props = {
  icon?: React.ReactNode;
  color?: string;
  colors?: {
    color: string;
    backgroundColor: string;
  };
};

const Badge: React.FC<Props> = ({ icon, color = 'blue', colors, children }) => {
  let classes = '';
  let style = {};
  if (colors) {
    style = colors;
  } else {
    const colorsFromColor = getColors(color);
    classes = `${colorsFromColor.bgColor} ${colorsFromColor.textColor}`;
  }

  return (
    <div
      style={style}
      className={`${classes} relative inline-flex flex-auto box-border justify-center items-center rounded-full px-4 py-1`}
    >
      {icon && <div className="flex shrink-0 pr-2">{icon}</div>}
      <div className="pt-2 pb-2 leading-none">{children}</div>
    </div>
  );
};

export default Badge;
