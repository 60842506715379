import React from 'react';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { useLocalStorage } from '@rehooks/local-storage';
import { translate } from 'utils/translations';

export function ProjectCreatedPopup() {
  const continueHandler = () => {
    setCreatedPopup('');
  };

  const [createdPopup, setCreatedPopup] = useLocalStorage('_createdPopup');

  if (!createdPopup) {
    return null;
  }

  return (
    <Modal onClose={continueHandler}>
      <div
        dangerouslySetInnerHTML={{
          __html: createdPopup + '',
        }}
      />
      <Button onClick={continueHandler} className="mt-8">
        {translate('Continue')}
      </Button>
    </Modal>
  );
}
