import React, { useContext } from 'react';
import { Task, Project } from '@contractool/schema';
import { Form } from '../../components/Form';
import { http } from '../../utils/http';
import { translate } from 'utils/translations';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'hooks';
import { GuardContext } from 'contexts';

export function TaskNew({
  onCreate,
  project,
}: {
  onCreate: () => void;
  project: Project;
}) {
  const history = useHistory();
  const { success } = useToasts();

  const { user } = useContext(GuardContext);

  const close = () => {
    history.push(`/projects/${project.id}`);
  };

  return (
    <Modal heading={translate('Add task')} onClose={close}>
      <Form
        initialValues={{
          title: '',
          body: '',
          deadline: null,
          assignee_id: user.id,
        }}
        onSubmit={(values) => http.post<Task>(project.tasks_url, values)}
        onSuccess={(task: Task) => {
          close();
          onCreate();
          success(
            `${translate('Task :title was added successfully', {
              title: task.title,
            })}`,
          );
        }}
      >
        <Form.TextInput
          name="title"
          label={translate('Title')}
          className="mb-6"
          autoFocus={true}
        />
        <Form.DatePicker
          name="deadline"
          label={translate('Due date')}
          className="mb-6"
          formatInput="dd MMM yyyy"
        />
        <Form.UserDropdown
          api="api/users?deleted=false"
          name="assignee_id"
          label={translate('Assignee')}
          className="mb-6"
          showDelegations
          // right={<span className="text-blue-600 hover:text-blue-300 cursor-pointer">Assign to me</span>}
          autocomplete
        />

        <Form.TextArea name="body" label={translate('Description')} rows={6} />

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={close}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Create Task')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
