import { Dictionary, isNil, omitBy } from 'lodash';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { Modal as ComponentModal } from 'components/Modal';
import { Button } from 'components/Button';
import { Menu, MenuItem } from 'components/Menu';
import { TextInput } from 'components/TextInput';
import { Field as FieldWrapper } from 'components/Field';
import { Icon } from 'components/Icon';

import { translate } from 'utils/translations';

type Props<T> = {
  onSubmit: (payload: Dictionary<T>) => void;
  onDelete?: (id: number | undefined) => void;
  onClose: () => void;
  data?: T;
  isEditMode?: boolean;
  isOpen?: boolean;
  title: string;
};

function Modal<T extends { id?: number; title: string }>({
  onSubmit,
  onDelete,
  onClose,
  data,
  isEditMode,
  isOpen,
  title,
}: Props<T>) {
  const notFound = data === undefined && isEditMode ? true : false;

  const handleDelete = () => {
    onDelete && onDelete(data?.id);
    onClose();
  };

  return (
    <ComponentModal
      heading={
        isEditMode ? translate(`Edit ${title}`) : translate(`New ${title}`)
      }
      isOpen={isOpen}
      onClose={onClose}
      corner={
        isEditMode && (
          <Menu
            handle={({ toggleMenu }) => (
              <div
                onClick={toggleMenu}
                className="flex items-center cursor-pointer"
              >
                <Icon
                  name="more_horiz"
                  className="text-gray-600 cursor-pointer"
                  size={6}
                />
              </div>
            )}
          >
            <MenuItem icon="remove_circle" onClick={handleDelete}>
              {translate('Delete')}
            </MenuItem>
          </Menu>
        )
      }
      size="small"
    >
      <Formik<T>
        // @ts-ignore
        initialValues={{
          id: data?.id,
          title: data?.title,
        }}
        onSubmit={async (values: T) => {
          onSubmit(omitBy(values, isNil));
          onClose();
        }}
        validationSchema={yup.object().shape({
          title: yup.string().required('Required'),
        })}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, isSubmitting }) => (
          <Form>
            <Field name="title">
              {({ field, meta }: any) => (
                <FieldWrapper
                  {...field}
                  className="mb-12"
                  label={translate('Name')}
                  errorMessage={meta.touched && meta.error}
                >
                  <TextInput
                    {...field}
                    onChange={(value) => {
                      setFieldValue(field.name, value);
                    }}
                    hasError={meta.touched && meta.error}
                  />
                </FieldWrapper>
              )}
            </Field>
            <div className="flex justify-between pt-6">
              <Button color="white" onClick={onClose}>
                {translate('Cancel')}
              </Button>
              <Button
                onClick={() => handleSubmit()}
                disabled={notFound || isSubmitting}
              >
                {translate('Save')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ComponentModal>
  );
}

export default Modal;
