import { groupBy } from 'lodash';
import * as React from 'react';
import { FieldInputProps } from 'formik';
import { Route, Redirect, useHistory, useParams } from 'react-router-dom';

import { AppContext, GuardContext } from 'contexts';
import { Menu, MenuItem } from 'components/Menu';
import { Page } from 'components/layout/Page';
import { Tab, Tabs } from 'components/Tabs';
import ManageCategory from 'components/Modal/ManageCategory';

import { ReportTab, FilterTemplate } from '@contractool/schema';

import { useRequest } from 'hooks/useRequest';
import { Context as ReportsContext } from 'contexts/reports';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { useToggle } from 'hooks';
import DellJiReportsView from 'integrations/dell_ji/components/views/reports';

import ReportTabView from './tab';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { CategoryOrderID } from 'components/Modal/ManageCategory/types';
import { isUserInGroup } from 'utils/auth';

const getTabName = (tab: ReportTab, idx: number) =>
  tab ? `${tab.heading} ${idx}` : '';

const ReportTabs: React.FC = ({ children }) => {
  const { tabs } = React.useContext(ReportsContext);
  // todo: refactor Tabs component and remove this block
  const { tabIdx } = useParams<{ tabIdx?: string }>();
  const idx = Number(tabIdx);
  const tab = tabs[idx];
  const selectedTabName = getTabName(tab, idx);
  // end block

  const history = useHistory();
  const handleSelectTab = React.useCallback(
    (name: string) => {
      const segments = name.split(' ');
      const idx = Number(segments[segments.length - 1]);
      history.push(`/reports-library/${idx}`);
    },
    [history],
  );

  return tabs.length > 0 ? (
    <Tabs
      selected={selectedTabName}
      onSelect={handleSelectTab}
      fixedHeight={false}
    >
      {tabs
        .sort((a, b) => a.order - b.order)
        .map((tab: ReportTab, idx: number) => (
          <Tab
            key={getTabName(tab, idx)}
            name={getTabName(tab, idx)}
            heading={tab.heading}
            className="pt-4"
          >
            <div className={'-mx-6'}>{children}</div>
          </Tab>
        ))}
    </Tabs>
  ) : null;
};

const Layout = () => {
  const { tabs, createTab, updateTab, removeTab, orderTabs, refreshTabs } =
    React.useContext(ReportsContext);
  const { user } = React.useContext(GuardContext);
  const [tabsOverlayVisible, tabsOverlayDisplayToggle] = useToggle(false);
  return (
    <div className="bg-gray-000 min-h-screen pb-100">
      <Page
        heading={translate('Reports Library')}

        right={<div id="create-button" />}
      >
		<p className='text-gray-600'>{translate('Reports Library includes a set of generic reports that you can select from to customize reports for your specific requirements.')}</p>
		<p className='text-gray-600'>{translate('Use \'Add to my reports\' option to add report to your own Reports workspace where you can customize it.')}</p>
        {isUserInGroup(user, 'admin') && (
          <div className="mb-5 relative">
            <div
              title={translate('Manage tabs')}
              className="absolute top-0 right-0 text-gray-500 cursor-pointer"
            >
              
              <Menu handle="settings">
                <MenuItem
                  icon="list"
                  onClick={tabsOverlayDisplayToggle.on}
                  nowrap
                >
                  {translate('Manage reports')}
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}
        <ReportTabs>
          <ReportTabView />
        </ReportTabs>
        {tabsOverlayVisible && (
          <ManageCategory
            tabs={tabs}
            title={translate('Manage reports')}
            onClose={tabsOverlayDisplayToggle.off}
            onOrder={async (ids: CategoryOrderID[]) => {
              await orderTabs(ids);
              refreshTabs();
            }}
            onDelete={async (index, parentField: FieldInputProps<any>) => {
              await removeTab(parentField.value.id);
              refreshTabs();
            }}
            onCreate={async (
              parentField: FieldInputProps<any>,
              field: FieldInputProps<any>,
            ) => {
              const payload: ReportTab = {
                heading: field.value,
                reports: parentField.value.reports,
                library: true,
              };
              await createTab(payload);
              refreshTabs();
            }}
            onUpdate={async (
              index: number,
              parentField: FieldInputProps<any>,
              field: FieldInputProps<any>,
            ) => {
              const payload: ReportTab = {
                heading: field.value,
                id: parentField.value.id,
                reports: parentField.value.reports,
              };
              await updateTab(index, payload);
              refreshTabs();
            }}
          />
        )}
      </Page>
    </div>
  );
};

const Provider: React.FC = ({ children }) => {
  const [filterTemplates] = useRequest<FilterTemplate[]>(
    '/api/report-groups',
    [],
  );
  const groupedFilterTemplates = groupBy(filterTemplates, 'name');

  const [tabs, { refresh: refreshTabs }] = useRequest<ReportTab[]>(
    '/api/report-tabs-library',
    [],
  );
  const createTab = React.useCallback(async (payload: ReportTab) => {
    const { data: newTab } = await http.post<ReportTab>(
      '/api/report-tabs',
      payload,
    );

    return newTab;
  }, []);

  const updateTab = React.useCallback(
    async (idx: number, payload: ReportTab) => {
      const { data: updatedTab } = await http.put<ReportTab>(
        `/api/report-tabs/${idx}`,
        payload,
      );

      return updatedTab;
    },
    [],
  );

  const removeTab = React.useCallback(async (idx: number) => {
    const { data: deletedTab } = await http.delete<ReportTab>(
      `/api/report-tabs/${idx}`,
    );

    return deletedTab;
  }, []);

  const orderTabs = React.useCallback(async (order: CategoryOrderID[]) => {
    const { data: tabs } = await http.post<ReportTab[]>(
      '/api/report-tabs-order',
      {
        order,
      },
    );

    return tabs;
  }, []);

  const value = React.useMemo(
    () => ({
      groups: filterTemplates,
      groupedFilterTemplates,
      tabs,
      createTab,
      updateTab,
      removeTab,
      orderTabs,
      refreshTabs,
    }),
    [
      filterTemplates,
      groupedFilterTemplates,
      tabs,
      createTab,
      updateTab,
      removeTab,
      orderTabs,
      refreshTabs,
    ],
  );

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};

const Routes = () => (
  <>
    <Route path="/reports-library" exact>
      <Redirect to={'/reports-library/0'} />
    </Route>
    <Route path="/reports-library/:tabIdx">
      <Layout />
    </Route>
  </>
);

const View = () => (
  <Provider>
    <Routes />
  </Provider>
);

const ReportsLibraryPage = () => {
  const { config } = React.useContext(AppContext);
  const { integration } = config;

  return 'dell_ji' === integration ? (
    <DellJiReportsView />
  ) : 'scantraxx' === integration ? null : (
    <View />
  );
};

export default ReportsLibraryPage;
