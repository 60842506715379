import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Integrations from './Integrations';
import Integration from './Integration';
import Workflow from './Workflow';
import {
  URL_CREATE_INTEGRATION,
  URL_EDIT_INTEGRATION,
  URL_INTEGRATIONS,
  URL_INTEGRATION_DETAIL,
} from '@constants/integration';
import { URL_WORKFLOW_DETAIL } from '@constants/workflow';

const IntegrationPage: React.FC = () => {
  return (
    <Switch>
      <Route path={URL_WORKFLOW_DETAIL}>
        <Workflow />
      </Route>
      <Route path={[URL_CREATE_INTEGRATION, URL_EDIT_INTEGRATION]}>
        <Integrations />
      </Route>
      <Route path={URL_INTEGRATION_DETAIL}>
        <Integration />
      </Route>
      <Route path={URL_INTEGRATIONS}>
        <Integrations />
      </Route>
    </Switch>
  );
};

export default IntegrationPage;
