import React, { useContext } from 'react';
import { Field } from 'formik';
import { generatePath, Route, Switch, useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { camelCase, set } from 'lodash';

import MultiselectDropdown2 from 'components/MultiselectDropdown2';
import { Field as FieldWrapper } from 'components/Field';
import { DocumentComponent } from 'components/documents/Document';
import { TextInput } from 'components/TextInput';
import { Icon } from 'components/Icon';

import Selection from './Selection';

import { Config, FieldType } from 'types/output/rules';
import { getWorkflow } from 'hooks/workflows';
import { useRequest } from 'hooks/useRequest';

import { AppContext } from 'contexts';

import { Document, User } from '@contractool/schema';
import { URL_WORKFLOW_RULE_ATTACHMENTS } from '@constants/workflow';

import { createOption } from 'utils/integration/rules';
import { translate } from 'utils/translations';
import { AddFiles } from 'views/projects/AddFiles';
import { SidePanel } from 'views/projects/ProjectDetail';
import Remirror from 'components/Remirror';
import {Switch as SwitchForm} from 'components/Switch';

type Props = {
  path: string;
  value: any;
  onChange: (result: any) => void;
  dependencies: Config[];
};

const Dynamic: React.FC<Props> = ({ path, value, onChange, dependencies }) => {
  const { assessmentWorkflow } = useContext(AppContext);
  const history = useHistory();
  const { id, wid } = useParams<{ id: string; wid: string }>();

  const workflow = getWorkflow(assessmentWorkflow);
  const action = camelCase(value.action);

  const config = dependencies.find(
    (dependency) => dependency.action === value.action,
  );

  return (
    <>
      {config?.fields.map((field) => {
        const name = `${path}.${field.path}`;

        if (field.name === FieldType.SWITCH) {
	
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <SwitchForm
                    {...formikField}
                    onChange={(result) => {
                      onChange(set(value, field?.path, result));
                    }}
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }
        if (field.name === FieldType.METADATA) {
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <MultiselectDropdown2
                    {...formikField}
                    onChange={(result) =>
                      onChange(set(value, field?.path, result))
                    }
                    isMulti={field.isMulty}
                    options={workflow.fields.meta_data.map(createOption)}
                    hasError={meta.touched && meta.error}
                    menuPortalTarget
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }
        if (field.name === FieldType.DAYS || field.name === FieldType.TITLE) {
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <TextInput
                    {...formikField}
                    onChange={(result) =>
                      onChange(set(value, field?.path, result))
                    }
                    hasError={meta.touched && meta.error}
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }
        if (field.name === FieldType.ROLE) {
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <MultiselectDropdown2
                    {...formikField}
                    onChange={(result) =>
                      onChange(set(value, field?.path, result))
                    }
                    isMulti={field.isMulty}
                    options={workflow.roles.map((roles: any) =>
                      createOption({ label: roles.label, name: roles.key }),
                    )}
                    hasError={meta.touched && meta.error}
                    menuPortalTarget
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }
        if (field.name === FieldType.SELECTION) {
          return (
            <Selection
              key={name}
              path={`${path}.${action}`}
              value={value}
              field={field}
              onChange={onChange}
            />
          );
        }
        if (
          field.name === FieldType.TEXTAREA_IN_APP ||
          field.name === FieldType.TEXTAREA_EMAIL ||
          field.name === FieldType.TEXTAREA_SINGLE ||
          field.name === FieldType.SUBJECT
        ) {
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <Remirror
                    type="textarea"
                    {...formikField}
                    onChange={(json) => {
                      onChange(set(value, field?.path, json));
                    }}
                    hasError={meta.touched && meta.error}
					hideButton={true}
					workflow={workflow}
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }
        if (field.name === FieldType.ATTACH_FILE) {
          return (
            <div className="w-full" key={name}>
              <Field name={name}>
                {({ field: formikField, form, meta }: any) => (
                  <FieldWrapper
                    {...field}
                    errorMessage={meta.touched && meta.error}
                  >
                    <SidePanel
                      heading={translate('Attachments')}
                      right={
                        <div className="flex">
                          <Link
                            to={generatePath(URL_WORKFLOW_RULE_ATTACHMENTS, {
                              id,
                              wid,
                            })}
                            className="flex items-center text-blue-700"
                          >
                            <Icon name="add" size={5} />
                            <span className="ml-2">
                              {translate('Add files')}
                            </span>
                          </Link>
                        </div>
                      }
                    >
                      <Switch>
                        <Route exact path={URL_WORKFLOW_RULE_ATTACHMENTS}>
                          <AddFiles
                            onSelectFiles={(selectedFiles) => {
                              onChange(set(value, field?.path, selectedFiles));
                              history.goBack();
                            }}
                            onClose={history.goBack}
                            hasPermission
                            hideUploadFile
                          />
                        </Route>
                      </Switch>
                      {formikField.value.map((value: Document) => (
                        <DocumentComponent
                          key={name}
                          see={false}
                          download={false}
                          document={value}
                          onDelete={(_document) => {
                            const value = formikField.value.filter(
                              (value: Document) => value.id !== _document.id,
                            );
                            form.setFieldValue(formikField.name, value);
                          }}
                        />
                      ))}
                    </SidePanel>
                  </FieldWrapper>
                )}
              </Field>
            </div>
          );
        }
        if (field.name === FieldType.USER) {
          const users = useRequest<User[]>('/api/users?dropdown=1', []);
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <MultiselectDropdown2
                    {...formikField}
                    onChange={(result) =>
                      onChange(set(value, field?.path, result))
                    }
                    isMulti={field.isMulty}
                    options={users[0].map(createOption)}
                    hasError={meta.touched && meta.error}
                    menuPortalTarget
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }
        if (field.name === FieldType.STATE) {
          const states = workflow?.states || [];
          const options = states.map((state: any) =>
            createOption({ label: state.label, name: state.key }),
          );
          return (
            <Field name={name} key={name}>
              {({ field: formikField, meta }: any) => (
                <FieldWrapper
                  {...field}
                  errorMessage={meta.touched && meta.error}
                >
                  <MultiselectDropdown2
                    {...formikField}
                    onChange={(result) =>
                      onChange(set(value, field?.path, result))
                    }
                    isMulti={field.isMulty}
                    options={options}
                    hasError={meta.touched && meta.error}
                    menuPortalTarget
                  />
                </FieldWrapper>
              )}
            </Field>
          );
        }

        return;
      })}
    </>
  );
};

export { Dynamic };
