import React, { FC, useContext, useState } from "react";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Project, FieldTemplate } from "@contractool/schema";
import { SidePanel } from "./ProjectDetail";
import { Icon } from "../../components/Icon";
import { ProjectDetailsEdit } from "./ProjectDetailsEdit";
import { AppContext, GuardContext } from "contexts";
import { isAllowedForUser } from "../../utils/form";
import { permissionRequest } from "../../utils/wildcard";
import { translate } from "utils/translations";
import "./ProjectDetailsPanel.css";
import { Workflow } from "../../../../_common/schema/Workflow";
import { ImportLog } from "views/projects/Widgets/ImportLog";
import { matchCondition } from "features/form/field/fieldDependsOnOtherThan";
import { FieldShowCondition } from "../../../../_common/schema/FieldShowCondition";
import { getWorkflow } from "hooks/workflows";
import { Components, FormRenderer } from "components/Formio";
import components from "components/Formio/Custom";
import { http } from "utils/http";
import { useToasts } from "hooks";

export const ProjectDetailsPanel: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}> = ({ project, onUpdate, onClose }) => {
    const [showMore, setShowMore] = useState(false);
    const { config } = useContext(AppContext);
    const { user } = useContext(GuardContext);
    const { success } = useToasts();

    const fields: FieldTemplate[] = getWorkflow(project.workflow).fields.meta_data.filter((field: FieldTemplate) => {
        let show = true;
        if (field.showOnlyIf) {
            field.showOnlyIf.forEach((condition: FieldShowCondition) => {
                const form = {
                    values: project.fields
                };
                const projectContext = {
                    resource: null
                };
                if (!matchCondition(condition, form, projectContext)) {
                    show = false;
                }
            });
        }

        return (
            isAllowedForUser(field, user) &&
            show &&
            field.workflow === project.workflow &&
            field.type !== "SUPPLIER" &&
            field.type !== "ATTACHMENT"
        );
    });

    const workflow = getWorkflow(project.workflow)

    const editModal = (
        <Switch>
            <Route
                path={`/projects/${project.id}/projectdetails/edit`}
                render={() => (
                    <ProjectDetailsEdit
                        onClose={onClose}
                        onUpdate={() => {
                            onUpdate();
                            onClose();
                        }}
                        project={project}
                    />
                )}
            />
        </Switch>
    );

    if (workflow.fields_config) {
        Components.setComponents(components);
        return (
            <SidePanel
                heading={<span className={`px-4`}>{translate('Project Details')}</span>}
                className={'formio-read-only p-4 project-details'}
                right={
                    permissionRequest(project, 'project.edit') ? (
                        <Link
                            to={`/projects/${project.id}/projectdetails/edit`}
                            className="flex items-center text-blue-700 mx-4"
                        >
                            <Icon name="edit" size={5} />
                            <span className="ml-2">{translate('Edit')}</span>
                        </Link>
                    ) : null
                }
            >
                <FormRenderer
                    submission={{ data: project.fields }}
                    form={workflow.fields_config}
                    options={{ readOnly: true }}
                    onSubmit={({ data }) => {
                        http
                            .put<Project>('/api/projects/' + project.id + '/formio', data)
                            .then(() => {
                                onUpdate();
                                success(
                                    translate(
                                        'Project Details of :title were updated successfully',
                                        {
                                            title: project.title,
                                        },
                                    ),
                                );
                            });
                    }}
                />
                {editModal}
            </SidePanel>
        );
    }


    return (
        <SidePanel
            heading={translate("Project Details")}
            right={
                <>
                    {config.integration === "dell_swp" && (
                        <a
                            href="https://dellcommunitiespmo.force.com/ndaportal/s/"
                            className="text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NDA Tool
                        </a>
                    )}
                    {config.integration === "ceq_dell" && (
                        <a
                            href={config.config.envoyer_dev_deploy}
                            className="text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Deploy do DEV.
                        </a>
                    )}
                    {config.integration === "dell_ip" && (
                        <a
                            href="https://globaloperations.onecloud.dell.com/sites/GO4/IPO/SitePages/ProcurePro.aspx"
                            className="text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Procure Pro
                        </a>
                    )}
                    {permissionRequest(project, "project.edit") ? (
                        <Link
                            to={`/projects/${project.id}/projectdetails/edit`}
                            className="flex items-center text-blue-700"
                        >
                            <Icon name="edit" size={5} />
                            <span className="ml-2">{translate("Edit")}</span>
                        </Link>
                    ) : null}
                </>
            }
        >
            {permissionRequest(project, "project.edit") && editModal}
            {project.import_log && <ImportLog project={project} />}
            <Detail left="Project ID" right={project.instance_id} />
            <Detail left="Created date" right={project.created_at} />
            <Detail left={translate(config.config.supplier_label_singular)} right={project.supplier?.name} />
            {workflow?.settings.supplier_poc && (
                <>
                    <Detail left={translate(config.config.supplier_label_singular + " POC")}
                            right={project.supplier_contact?.name} />
                    <Detail
                        left={translate(config.config.supplier_label_singular + " POC email")}
                        right={
                            <a
                                className="text-blue-700"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`mailto:${project.supplier_contact?.email}`}
                            >
                                {project.supplier_contact?.email}
                            </a>
                        }
                    />
                    <Detail left={translate(config.config.supplier_label_singular + " POC phone")}
                            right={project.supplier_contact?.phone} />
                </>
            )}
            <Detail left={translate(config.config.supplier_label_singular + " Country")}
                    right={project.supplier?.address_state} />
            <Detail left={translate("Requestor")} right={project.team.singular.requestor?.user.label} />
            {project.finishes_at && (
                <Detail left={translate("Target Date for completion")} right={project.finishes_at} />
            )}
            {project.types_human && <Detail left={translate("Types")} right={project.types_human} />}
            {fields
                ? !showMore
                    ? fields.slice(0, 1).map((field, index) => {
                        return (
                            !field.hideInProjectDetailPanel && (
                                <Detail
                                    field={field}
                                    key={field.label + index}
                                    left={(field.group ? field.group + ": " : "") + field.label}
                                    right={project.fields_human[field.name]}
                                />
                            )
                        );
                    })
                    : fields.map((field: FieldTemplate, index) => {
                        return (
                            !field.hideInProjectDetailPanel && (
                                <Detail
                                    field={field}
                                    key={field.label + index}
                                    left={(field.group ? field.group + ": " : "") + field.label}
                                    right={project.fields_human[field.name]}
                                />
                            )
                        );
                    })
                : null}
            {showMore ? (
                <div
                    className="flex justify-center items-center text-blue-700 cursor-pointer"
                    onClick={() => setShowMore(false)}
                >
                    <Icon name="arrow_downward" className="rotate-180" size={5} />
                    <span className="ml-2">{translate("Show less")}</span>
                </div>
            ) : (
                <div
                    className="flex justify-center items-center text-blue-700 cursor-pointer"
                    onClick={() => setShowMore(true)}
                >
                    <Icon name="arrow_downward" size={5} />
                    <span className="ml-2">{translate("Show more")}</span>
                </div>
            )}
        </SidePanel>
    );
};

const Detail: FC<{ field?: FieldTemplate; left: String; right: any }> = ({ field, left, right }) => {
    const { config } = useContext(AppContext);

    const formattedDateString = React.useMemo(() => {
        if (
            typeof right === "string" &&
            right.match(/-\d+-/) &&
            new Date(right) instanceof Date &&
            !isNaN(new Date(right).valueOf())
        ) {
            const date = new Date(right);
            const {
                date_format: { skeleton, day, month, year }
            } = config;

            return skeleton
                .replace("day", date.toLocaleDateString("default", { day }))
                .replace("month", date.toLocaleDateString("default", { month }))
                .replace("year", date.toLocaleDateString("default", { year }));
        }
    }, [right, config]);
    let value = formattedDateString || right || "---";
    if (field && (field.type === "WYSIWYG" || field.type === "CUSTOM_HTML")) {
        value = (
            <div
                dangerouslySetInnerHTML={{
                    __html: right
                }}
            />
        );
    }

    return (
        <div className="flex justify-between my-5">
            <div className="max-w-1/2 text-gray-600">{left}</div>
            <div className="max-w-1/2 text-gray-700 whitespace-pre-wrap project-detail-panel-value">
                {value}
            </div>
        </div>
    );
};
