import React from 'react';
import { useTable, useSortBy, Cell, Row, useBlockLayout } from 'react-table';

import Table from './components/Table';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import TableRow from './components/TableRow';
import NoResults from './components/NoResults';
import Loading from './components/Loading';

type Props = {
  columns: any;
  data: object[];
  isLoading: boolean;
  stickyHeader?: boolean;
  onClick?: (e: Cell<object, any>) => void;
};

const TableBasic: React.FC<Props> = ({
  columns,
  data,
  isLoading,
  stickyHeader,
  onClick,
}) => {
  const {
    headerGroups,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data,
      defaultColumn: {
        width: 'auto',
      },
    },
    useSortBy,
    useBlockLayout,
  );

  return (
    <Table getTableProps={getTableProps}>
      <TableHead stickyHeader={stickyHeader} headerGroups={headerGroups} />
      <TableBody getTableBodyProps={getTableBodyProps}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {rows.length === 0 ? (
              <NoResults />
            ) : (
              rows.map((row: Row) => (
                <TableRow
                  key={row.id}
                  row={row}
                  prepareRow={prepareRow}
                  onClick={onClick}
                />
              ))
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default TableBasic;
