function contentFilter<T>(object: T[], input: string, workflow?: string): T[] {
  return object
    .filter((prop: T & { workflow: string }) => prop.workflow === workflow)
    .filter((prop) => {
      const stringify = JSON.stringify(prop, (_, key) =>
        typeof key === 'string' ? key.toLowerCase() : key,
      );
      const searchInput = input.toLowerCase();
      return stringify.includes(searchInput);
    });
}

export { contentFilter };
