import React from 'react';

import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';

import { translate } from 'utils/translations';

type Props = {
  onChange: (value: string) => void;
  onClick?: () => void;
  value: string;
  buttonUrl: string;
  buttonName: string;
};

const Right: React.FC<Props> = ({
  onChange,
  onClick,
  value,
  buttonUrl,
  buttonName,
}) => {
  return (
    <div className="flex">
      <div className="mr-8 w-100">
        <TextInput
          placeholder={translate('Search...')}
          icon="search"
          onChange={onChange}
          value={value}
        />
      </div>
      {onClick ? (
        <Button color="blue" icon="add" onClick={onClick}>
          {buttonName}
        </Button>
      ) : (
        <Button.Link to={buttonUrl} color="blue" icon="add">
          {buttonName}
        </Button.Link>
      )}
    </div>
  );
};

export default Right;
