import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import SelectRole from './Role';

const config = () =>
  baseEditForm([
    {
      key: 'display',
      components: [
        {
          type: 'select',
          label: 'Role',
          weight: 0,
          key: 'role', // This will be available as component.role
          data: {
            values: SelectRole.roles,
          },
          validate: {
            required: true,
          },
        },
      ],
    },
    {
      key: 'data',
      components: [],
    },
    {
      key: 'validation',
      components: [],
    },
    {
      key: 'api',
      components: [],
    },
    {
      key: 'conditional',
      components: [],
    },
    {
      key: 'logic',
      components: [],
    },
    {
      key: 'layout',
      components: [],
    },
  ]);

export default config;
