import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Route, Switch, useParams } from 'react-router-dom';

import { Context as ReportsContext } from 'contexts/reports';
import { Context as ReportTabContext } from 'contexts/reports/tab';

import {
  CustomReport,
  ReportTab as ReportTabInterface,
} from '@contractool/schema';

import { http } from 'utils/http';

import { ReportCreateButton } from 'components/reports/tab';

import ReportCreateOverlay from './ReportCreateOverlay';
import ReportView from './report';

const ReactGridLayout = WidthProvider(RGL);

const PortedReportCreateButton = () => {
  const createButtonPort = document.getElementById('create-button');

  return createButtonPort !== null
    ? createPortal(<ReportCreateButton />, createButtonPort)
    : null;
};

const Layout: React.FC = () => {
  const { tab, idx } = useContext(ReportTabContext);

  const [tabState, setTabState] = useState<ReportTabInterface>(tab);
  useEffect(() => {
    setTabState(tab);
  }, [tab]);

  const handleReportUpdate = async (
    updatedConfig: CustomReport,
    reportIdx: number,
  ) => {
    console.log('handleReportUpdate', updatedConfig);
    const { data: updatedReport } = await http.put<CustomReport>(
      `/api/report-tabs/${tab.id}/${reportIdx}`,
      updatedConfig,
    );
    const updatedReportindex = tab.reports.findIndex(
      //@ts-ignore
      (report) => report.id === updatedReport.id,
    );
    tab.reports[updatedReportindex] = updatedReport;

    return updatedReport;
  };

  const handleReportDelete = async (reportIdx: number) => {
    await http.delete<ReportTabInterface>(
      `/api/report-tabs/${tab.id}/${reportIdx}`,
    );
    const deleteReportIndex = tab.reports.findIndex(
      (item: CustomReport) => item.id === reportIdx,
    );

    tab.reports.splice(deleteReportIndex, 1);
    setTabState({ ...tab });
  };

  const saveGrid = async (grid: any, tabIdx: number) => {
    grid.map((item: any) => {
      item['id'] = Number(item['i'].split('-')[1]);
    });
    return http.post<CustomReport[]>(`/api/report-tabs/${tabIdx}/grid`, grid);
  };
  const handleSaveGrid = async (grid: any) => {
    // silent update of reports without rerender
    await saveGrid(grid, tab.id);
  };

  return (
    <>
      <PortedReportCreateButton />
      {tabState.reports.length > 0 && (
        <ReactGridLayout
          className="layout"
          rowHeight={70}
          cols={12}
          onDragStop={handleSaveGrid}
          onResizeStop={handleSaveGrid}
          draggableHandle=".drag-handle"
        >
          {tabState.reports.map((config: any, reportIdx: number) => (
            <div
              key={`report-${config.id}-${config.title}`}
              data-grid={config.grid}
              style={{ ...(!config.collapsed ? { zIndex: 1 } : null) }}
            >
              <ReportView
                config={config}
                idx={config.id}
                onUpdate={handleReportUpdate}
                onDelete={handleReportDelete}
              />
            </div>
          ))}
        </ReactGridLayout>
      )}
    </>
  );
};

const Routes = () => (
  <>
    <Route path="/reports/:tabIdx">
      <Layout />
    </Route>
    <Switch>
      <Route path="/reports/:tabIdx/create">
        <ReportCreateOverlay />
      </Route>
    </Switch>
  </>
);

const Provider: React.FC = ({ children }) => {
  const { tabs } = useContext(ReportsContext);

  const { tabIdx } = useParams<{ tabIdx?: string }>();
  const idx = Number(tabIdx);
  const tab = tabs[idx];

  const value = {
    tab,
    idx,
  };

  return (
    <ReportTabContext.Provider value={value}>
      {children}
    </ReportTabContext.Provider>
  );
};

const View = () => (
  <Provider>
    <Routes />
  </Provider>
);

export default View;
