import React from 'react';
import {Project} from '@contractool/schema';
import {useParams} from 'react-router-dom';
import {DocumentDetail} from '../../components/documents/DocumentDetail';

export function ProjectDocumentDetail({
    project,
    onUpdate,
    onClose,
}: {
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}) {
    const {documentId} = useParams<{documentId?: string}>();

    const document = project.attachments.find((t) => documentId && t.id === parseInt(documentId));
    if (!document) return null;

    return <DocumentDetail document={document} categories={null} onUpdate={onUpdate} onClose={onClose} />;
}
