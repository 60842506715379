// URL - Integrations
export const URL_INTEGRATIONS = '/integration';
export const URL_CREATE_INTEGRATION = '/integration/create';
export const URL_EDIT_INTEGRATION = '/integration/:id/edit';
export const URL_INTEGRATION_DASHBOARD = [
  URL_INTEGRATIONS,
  URL_CREATE_INTEGRATION,
  URL_EDIT_INTEGRATION,
];

// URL - Integration Detail
export const URL_INTEGRATION_DETAIL = '/integration/:id';
// URL - Integration Settings
export const URL_INTEGRATION_SETTINGS = '/integration/:id/settings';
// URL - Integration Workflows
export const URL_INTEGRATION_WORKFLOW = '/integration/:id/workflow';
export const URL_CREATE_INTEGRATION_WORKFLOW =
  '/integration/:id/workflow/create';
export const URL_EDIT_INTEGRATION_WORKFLOW =
  '/integration/:id/workflow/:wid/edit';
export const URL_INTEGRATION_WORKFLOW_DASHBOARD = [
  URL_INTEGRATION_WORKFLOW,
  URL_CREATE_INTEGRATION_WORKFLOW,
  URL_EDIT_INTEGRATION_WORKFLOW,
];
// URL - Integration Groups
export const URL_INTEGRATION_GROUP = '/integration/:id/group';

// API
export const URL_API_INTEGRATIONS = '/api/integrations';
export const URL_API_INTEGRATION = '/api/integration';
