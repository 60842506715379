import {
  BasicFormattingButtonGroup,
  HeadingLevelButtonGroup,
  HistoryButtonGroup,
  EditorComponent,
  useHelpers,
  Toolbar,
} from '@remirror/react';

import Mention from './Mention';
import SendButton from './SendButton';
import { useEffect, useState } from 'react';
import { Workflow } from '@contractool/schema/Workflow';

type Props = {
  onSubmit?: (json: any) => void;
  hideButton?: boolean;
  isFocused?: boolean;
  workflow: Workflow;
};

const Editor: React.FC<Props> = ({
  onSubmit,
  hideButton,
  isFocused,
  workflow,
}) => {
  const [getMentions, setGetMentions] = useState(false);

  const { getCharacterCount } = useHelpers(true);

  const isTyping = getCharacterCount() > 0;

  useEffect(() => {
    if (isFocused) {
      setGetMentions(true);
    }
  }, [isFocused]);

  return (
    <>
      <Toolbar>
        {/* <HistoryButtonGroup />
        <BasicFormattingButtonGroup />
        <HeadingLevelButtonGroup showAll /> */}
      </Toolbar>
      {getMentions && <Mention workflow={workflow} />}
      <div className="flex items-center bg-gray-000 rounded-lg mt-4">
        <div
          className="w-full"
          onClick={() => {
            if (getMentions === false) {
              setGetMentions(true);
            }
          }}
        >
          <EditorComponent />
        </div>
        {isTyping && !hideButton && <SendButton onSubmit={onSubmit} />}
      </div>
    </>
  );
};

export default Editor;
