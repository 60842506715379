import { LogFull } from '@contractool/schema';
import React, { FC } from 'react';
import { Icon } from '../../../components/Icon';
import {
  ActivityContainer,
  ActivityHeader,
  ActivityBody,
} from '../ActivityPanel';

export const DocumentLog: FC<{
  desc: string;
  log: LogFull;
}> = ({ desc, log }) => {
  const icon = iconHandler(log.message.extra.title);

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={desc}
        created_at={log.created_at}
		delegator={log?.delegator}
      />
      <ActivityBody className="bg-gray-100 rounded-lg p-5 inline-block">
        <div className="flex items-center">
          <span className="mr-4">{icon}</span>
          {log.message.extra.title}
        </div>
      </ActivityBody>
    </ActivityContainer>
  );
};

export const iconHandler = (icon: string | undefined) => {
    switch (icon) {
        case 'file-pdf':
            return <Icon name="pdf" size={6} className="text-red-600" />;
        case 'file-word':
            return <Icon name="word" size={6} className="text-blue-600" />;
        case 'file-powerpoint':
            return <Icon name="file-powerpoint" size={6} className="text-red-700" />;
        case 'file-excel':
            return <Icon name="file-excel" size={6} className="text-green-800" />;
        default:
            return <Icon name="description" size={6} className="text-blue-600" />;
    }
};
