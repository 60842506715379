import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { sign } from 'utils/auth';
import { Modal } from 'components/Modal';
import './PreviewModal.css';
import { MsgContent } from 'components/documents/MsgContent';
import { translate } from 'utils/translations';
import { http } from 'utils/http';
import { Document } from '@contractool/schema';

export function PreviewIcon({
  fileUrl,
  modalPreview,
  type,
  documentId,
  versionId,
  dataTip
}: {
  fileUrl: string;
  modalPreview: boolean;
  type: string;
  documentId?: number;
  versionId?: number;
  dataTip?: string
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const getUrlWithToken = (
    documentId?: number,
    versionId?: number,
  ): Promise<Document> => {
    return new Promise((resolve, reject) => {
      if (documentId && !versionId) {
        http
          .get<Document>(`/api/library/document/${documentId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else if (versionId) {
        http
          .get<Document>(`/api/library/version/${versionId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('Invalid document ID or version ID'));
      }
    });
  };

  useEffect(() => {
    if (modalOpen && type !== 'msg') {
      getUrlWithToken(documentId, versionId).then((response) => {
        setPreviewUrl(response.preview_url);
      });
    }
  }, [modalOpen]);

  if (!modalPreview && fileUrl) {
    return (
      <a href={sign(fileUrl)} target="_blank" rel="noopener noreferrer">
        <Icon name="remove_red_eye" size={6} className="ml-3 text-gray-500" />
      </a>
    );
  }

  return (
    <>
      {modalOpen && (
        <Modal
          corner={
            <span
              className="text-blue-700 cursor-pointer"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {translate('Close')}
            </span>
          }
          onClose={() => {}}
          size="w-full w-max-1400"
        >
          {type == 'msg' ? (
            <MsgContent fileUrl={fileUrl} />
          ) : (
            previewUrl && (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?embedded=true&src=${encodeURIComponent(previewUrl)}`}
                style={{ width: '100%', height: '95vh' }}
                frameBorder="0"
              ></iframe>
            )
          )}
        </Modal>
      )}
      <span  data-tip={dataTip || ""}
             onClick={() => {
          setModalOpen(true);
        }}
        className="flex"
      >
        <Icon
          name="remove_red_eye"
          size={6}
          className="ml-3 text-gray-500 cursor-pointer"
        />
      </span>
    </>
  );
}
