import React, { useState } from 'react';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { Switch } from 'components/Switch';
import Search from 'components/Search';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { useSmallLoader } from 'components/Loader';

const titleColumnStyle = {
  maxWidth: '12rem',
};

function CruncherListModal({
  phrase,
  onClose,
}: {
  phrase: string;
  onClose: () => void;
}) {
  const [exact, setExact] = useState(false);
  const [result, setResult] = useState<any[]>([]);
  const [cruncherPhrase, setCruncherPhrase] = useState<string>(phrase);
  const { start, stop } = useSmallLoader();
  const cruncher = () => {
    if (cruncherPhrase.length >= 2) {
      start('Searching in files, please wait...');
      http
        .get('api/cruncher-search?fuzzy=' + (exact ? 0 : 1), {
          phrase: cruncherPhrase,
        })
        .then((result: any) => {
          stop();
          console.log('cruncher result', result);
          // alert(result);
          setResult(result.data);
        });
    }
  };

  return (
    <Modal
      heading={
        <div className="w-full">
          <Search
            placeholder={translate('Search in files')}
            value={cruncherPhrase}
            onChange={setCruncherPhrase}
            onKeyUp={cruncher}
          />
        </div>
      }
      corner={
        <Switch name="exact" value={exact} onChange={setExact}>
          {' '}
          {translate('Exact match')}{' '}
        </Switch>
      }
      isOpen={true}
      onClose={onClose}
      size="w-10/12"
      compact={true}
      containerStyle={{ height: '80vh' }}
    >
      <table className="table">
        <tbody>
          {result.map((row: any, idx: number) => {
            let result = row.occurrences[0];
            // let index = result.search('%%%');
            // let count = 0;
            // while (index !== -1) {
            //     if (count % 2 === 0) {
            //         result = result.split('');
            //         result.splice(index, 3, ...'<span style="background-color: yellow">'.split(''));
            //         result = result.join('');
            //     } else {
            //         result = result.split('');
            //         result.splice(index, 3, ...'</span>'.split(''));
            //         result = result.join('');
            //     }
            //     count++;
            //     index = result.search('%%%');
            // }

            return (
              <>
                <tr key={idx + '-1'} style={{ borderBottom: 'none' }}>
                  <td colSpan={4}>
                    <div className="font-bold text-lg overflow-hidden">
                      {row.title}
                    </div>
                  </td>
                </tr>
                <tr
                  key={idx + '-2'}
                  className="flex items-center pb-7 border-b text-gray-700"
                >
                  <td>
                    <Icon name={row.icon} className="text-gray-500" size={12} />
                  </td>
                  <td style={titleColumnStyle}>
                    <div className="text-gray-700 text-sm">
                      {row.category?.title}
                    </div>
                    <div className="text-gray-700 text-sm">
                      {row.supplier?.title}
                    </div>
                  </td>
                  <td className="flex-1">
                    <div dangerouslySetInnerHTML={{ __html: result }} />
                  </td>
                  <td>
                    <a
                      href={row.document_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon
                        name="external_link"
                        className="text-gray-500 m-auto"
                        size={8}
                      />
                      {translate('Show more')}
                    </a>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const MemorizedCruncherListModal = React.memo(CruncherListModal);

export default MemorizedCruncherListModal;
