import React, { useState } from 'react';
import { generatePath, Route, useHistory } from 'react-router-dom';

import { Page } from 'components/layout/Page';
import Right from 'components/Integration/Right';
import List from 'components/Integration/List';
import Modal from 'components/Integration/Modal';

import { mutation } from 'utils';
import { translate } from 'utils/translations';
import { contentFilter } from 'utils/search';

import {
  URL_API_INTEGRATION,
  URL_API_INTEGRATIONS,
  URL_CREATE_INTEGRATION,
  URL_EDIT_INTEGRATION,
  URL_INTEGRATIONS,
  URL_INTEGRATION_DETAIL,
} from '@constants/integration';
import { useRequest } from 'hooks/useRequest';
import { IntegrationResponseApiType } from 'types/api/integration';
import { IntegrationForm } from 'types/output/integration';

const IntegrationsPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const [integrations, { refresh, isLoading }] = useRequest<
    IntegrationResponseApiType[]
  >(URL_API_INTEGRATIONS, []);

  const handleEdit = (id: number) =>
    history.push(generatePath(URL_EDIT_INTEGRATION, { id }));

  const handleClick = (id: number) =>
    history.push(generatePath(URL_INTEGRATION_DETAIL, { id }));

  const handleClose = () => history.push(URL_INTEGRATIONS);

  return (
    <Page
      heading={translate('Integrations')}
      right={
        <Right
          onChange={setSearchQuery}
          value={searchQuery}
          buttonUrl={URL_CREATE_INTEGRATION}
          buttonName={translate('New integration')}
        />
      }
    >
      <>
        <List
          data={contentFilter(integrations, searchQuery)}
          isLoading={isLoading}
          onDelete={(id) =>
            mutation({
              url: `${URL_API_INTEGRATION}/${id}`,
              method: 'DELETE',
              refresh,
              message: 'The integration has been deleted',
            })
          }
          onEdit={handleEdit}
          onClick={handleClick}
        />
        <Route exact path={URL_CREATE_INTEGRATION}>
          <Modal<IntegrationForm>
            title="Integration"
            onSubmit={(payload) => {
              mutation({
                url: URL_API_INTEGRATION,
                method: 'POST',
                payload,
                refresh,
                message: 'The integration has been created',
              });
            }}
            onClose={handleClose}
          />
        </Route>
        <Route
          exact
          path={URL_EDIT_INTEGRATION}
          component={({ match }) => {
            const [integration] = useRequest<IntegrationResponseApiType>(
              `${URL_API_INTEGRATION}/${match.params.id}`,
              null,
            );

            return (
              <Modal<IntegrationForm>
                isEditMode
                title="Integration"
                data={integration}
                onSubmit={(payload) =>
                  mutation({
                    url: `${URL_API_INTEGRATION}/${payload.id}`,
                    payload,
                    method: 'PUT',
                    refresh,
                    message: 'The integration has been edited',
                  })
                }
                onDelete={(id) =>
                  mutation({
                    url: `${URL_API_INTEGRATION}/${id}`,
                    method: 'DELETE',
                    refresh,
                    message: 'The integration has been deleted',
                  })
                }
                onClose={handleClose}
              />
            );
          }}
        />
      </>
    </Page>
  );
};

export default IntegrationsPage;
