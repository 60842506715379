import { useContext } from 'react';

import { Workflow } from '../../../../_common/schema/Workflow';
import { AppContext } from 'contexts';

export const getWorkflow = (key: string) => {
  const { config } = useContext(AppContext);
  return config.workflows.find((workflow: Workflow) => workflow.key === key);
};
export const getWorkflowById = (id: number) => {
  const { config } = useContext(AppContext);
  return config.workflows.find((workflow: Workflow) => workflow.id === id);
};
