import ButterToast, {
  POS_BOTTOM,
  POS_CENTER,
  POS_RIGHT,
  POS_TOP,
} from 'butter-toast/dist/lean.min.js';
import { AuthContext, PusherContext } from 'contexts';
import { useToasts } from 'hooks';
import React, { FC } from 'react';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { Modal } from 'components/Modal';

interface ToastPayload {
  message: string;
  status: number;
  closeTimeout?: number;
}

interface PopupPayload {
  content: string;
}

const positions = ['top-center', 'top-right', 'bottom-center', 'bottom-right'];
// PositionType should include all values from positions
export type TPosition =
  | 'top-center'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-right';

const positionMap: {
  [key in TPosition]: {
    vertical: string;
    horizontal: string;
  };
} = {
  'top-center': {
    vertical: POS_TOP,
    horizontal: POS_CENTER,
  },
  'top-right': {
    vertical: POS_TOP,
    horizontal: POS_RIGHT,
  },
  'bottom-center': {
    vertical: POS_BOTTOM,
    horizontal: POS_CENTER,
  },
  'bottom-right': {
    vertical: POS_BOTTOM,
    horizontal: POS_RIGHT,
  },
};

const ToastContainer: FC<{ position: TPosition }> = ({ position }) => {
  return (
    <ButterToast
      spacing={15}
      namespace={position}
      position={positionMap[position]}
    />
  );
};

interface INotificationProps {}

const Notifications: FC<INotificationProps> = () => {
  const pusher = React.useContext(PusherContext);
  const { user } = React.useContext(AuthContext);
  const { success, error } = useToasts();

  const [popupMessage, setPopupMessage] = React.useState('');

  console.log('PusherChannel', user);
  if (pusher && pusher.private && user && user.id) {
    const channel = 'user.' + user.id;
    pusher.leave(channel);
    pusher
      .private(channel)
      .listen('.success', (payload: ToastPayload) => {
        let toastParams = {
          closeTimeout: payload.closeTimeout,
        };
        if (payload.status === 200) {
          success(payload.message, toastParams);
        } else {
          error(payload.message, toastParams);
        }
      })
      .listen('.popup', (payload: PopupPayload) => {
        console.log('PopupPayload', payload);
        setPopupMessage(payload.content);
      });
  }

  return (
    <>
      <Modal
        heading={''}
        onClose={() => {
          setPopupMessage('');
        }}
        isOpen={popupMessage !== ''}
        corner={
          <span
            className="text-blue-700 cursor-pointer"
            onClick={() => {
              setPopupMessage('');
            }}
          >
            {translate('Close')}
          </span>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: popupMessage + '',
          }}
        ></div>
      </Modal>
      {positions.map((position: string) => (
        <ToastContainer key={position} position={position as TPosition} />
      ))}
    </>
  );
};

export default Notifications;
