import React, { useState } from 'react';
import MsgReader from '@kenjiuno/msgreader';
import Axios from 'axios';
import { translate } from 'utils/translations';

export function MsgContent({ fileUrl }: { fileUrl: string }) {
  const [messageContent, setMessageContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const axios = Axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Application-Release': localStorage.getItem('_app_version'),
    },
    withCredentials: true,
  });

  React.useEffect(() => {
    axios
      .get(fileUrl, { responseType: 'blob' })
      .then((response) => loadData(response.data));
  }, []);

  function loadData(blob: Blob) {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);
    fileReader.onload = function (event) {
      // @ts-ignore
      const message = new MsgReader(event.target.result);
      const messageContent = message.getFileData();
      setMessageContent(messageContent);
      setLoading(false);
    };
  }

  return (
    <>
      {!loading && messageContent != '' && (
        <div className={'container mx-auto'}>
          <div className={'flex justify-center'}>
            <div
              className={
                'box-content w-250 bg-gray-100 p-10 rounded-lg shadow-2xl'
              }
            >
              <h1 className={'text-center text-xl pb-5'}>
                {messageContent?.subject}
              </h1>
              <div className={'flex items-baseline'}>
                <div className={'text-lg'}>
                  <div className={'mb-2'}>
                    <b>{translate('From:')}</b>
                  </div>
                  <div>
                    <b>{translate('To:')}</b>
                  </div>
                </div>
                <div className={'text-md px-3'}>
                  <div className={'mb-3'}>
                    {messageContent?.senderSmtpAddress}
                  </div>
                  <div>
                    <ul>
                      {messageContent?.recipients?.map((recipient) => (
                        <li className={'text-md'}>{recipient.smtpAddress}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={'text-md'}>
                  <div className={'mb-3'}>
                    {'<' + messageContent?.senderName + '>'}
                  </div>
                  <div>
                    <ul>
                      {messageContent?.recipients?.map((recipient) => (
                        <li className={'text-md'}>
                          {'<' + recipient.name + '>'}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={'my-10'}>
                <p className={'whitespace-pre-wrap p-5 bg-gray-200 rounded-lg'}>
                  {messageContent?.body.replace(/(\r\n\r\n)/gm, '\n')}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
