import React, { FC } from "react";
import { Project, DocumentSignature } from "../../../../../_common/schema";
import { SidePanel } from "../ProjectDetail";
import { Icon } from "components/Icon";
import { translate } from "utils/translations";
import { permissionRequest } from "utils/wildcard";
import { Confirmation } from "components/Confirmation";
import { http } from "utils/http";
import { useBigLoader } from "components/Loader";

export const Esign: FC<{ project: Project, onUpdate: () => void }> = ({ project, onUpdate }) => {
    const color = (signature: DocumentSignature) => {
        switch (signature.status) {
            case "Pending":
                return "text-orange-500";
            case "Signed":
                return "text-green-500";
            case "Rejected":
                return "text-red-500";
        }

        return "";
    };
    const icon = (signature: DocumentSignature) => {
        switch (signature.status) {
            case "Pending":
                return "alarm";
        }

        return "done";
    };
    const loader = useBigLoader();

    const manualSign = (signature: DocumentSignature) => {
        console.log("manualSign", signature);
        loader.start(translate("Signing..."));
        http.put("/api/projects/" + project.id + "/manual_sign/" + signature.id).then(() => {
            onUpdate();
            loader.stop();
        });
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const targetDayMonth = date.toLocaleDateString("default", {
            day: "2-digit",
            month: "short"
        });
        const targetYear = date.toLocaleDateString("default", { year: "2-digit" });

        return targetDayMonth + " '" + targetYear;
    };

    return (
        <SidePanel heading={translate("Signatures")}>
            <table className="w-full">
                <tbody>
                {project.signatures.map((signature: DocumentSignature) => {
                    return (
                        <tr className="mb-2" key={`sign${signature.id}`}>
                            <td>{signature.email}<br />
                                <span className="text-gray-600 text-sm">({signature.document_title})</span>
                                <br /><br />
                            </td>
                            <td
                                className={"flex items-center " + color(signature)}
                                style={{ width: "100px" }}
                            >
                                <Icon name={icon(signature)} size={5} className="mr-2" />
                                <span>{signature.status_human}</span>
                            </td>
                            {signature.signed_at && (
                                <td title={signature.signed_at}>
                                    {formatDate(signature.signed_at)}<br /><br /><br />
                                </td>
                            )}
                            {signature.status === "Pending" && permissionRequest(project, "project.manual_sign") &&
                            <td><Confirmation
                                onConfirm={() => {
                                    manualSign(signature);
                                }}
                                trigger={({ onClick }) => (
                                    <span
                                        onClick={onClick}
                                        className="underline text-gray-500 cursor-pointer"
                                    >
                                            <Icon name="edit" size={5} className="mr-2" /><br /><br /><br />
                                        </span>
                                )}
                                heading={signature.document_title}
                                buttonText={translate("Yes, sign")}
                                color="yellow"
                            >
                                {translate("Are you sure you want to sign this signature on behalf of :email?", {
                                    email: signature.email
                                })}
                            </Confirmation>
                            </td>
                            }
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </SidePanel>
    );
};
