import React, { FC, useContext, useState } from 'react';
import { Button } from '../../components/Button';
import { Confirmation } from 'components/Confirmation';
import { Form } from '../../components/Form';
import { Menu, MenuItem } from 'components/Menu';
import { Modal } from 'components/Modal';
import { http } from '../../utils/http';
import { translate } from 'utils/translations';
import {
  ClientButton,
  FieldTemplate,
  Project,
  User,
} from '@contractool/schema';
import { CustomField } from 'components';
import { permissionRequest } from '../../utils/wildcard';
import { Clone } from './Actions/Clone';
import { Amend } from './Actions/Amend';
import { Reset } from './Actions/Reset';
import { AppContext, AuthContext, GuardContext } from 'contexts';
import { Delete } from 'views/projects/Actions/Delete';
import { Renew } from 'views/projects/Actions/Renew';
import { useBigLoader } from 'components/Loader';
import { RequestAccess } from './Actions/RequestAccess';
import { Message } from 'components/Message';
import { useToasts } from 'hooks';

export interface PreconditionResponse {
  form: [];
  input: string;
  title: string;
  text: string;
  state: Record<string, any>;
  width?: string;
}

export const ProjectActions: FC<{
  project: Project;
  onRefresh: () => void;
}> = ({ project, onRefresh }) => {
  const loader = useBigLoader();
  const [modal, setModal] = useState(false);
  const [modalAccess, setModalAccess] = useState(false);
  const [help, setHelpModal] = useState(false);
  const [response, setResponse] = useState<PreconditionResponse>();
  const [modelAction, setModalAction] = useState<string | null>(null);
  const secondaryStates = project.state.transitions.filter(
    (state: ClientButton) =>
      state.value !== 'progress' &&
      state.value !== 'regress' &&
      state.value &&
      !state.value.includes('reopen'),
  );
  const { config } = useContext(AppContext);
  const { user } = useContext(GuardContext);

  const { success } = useToasts();

  const bigLoader = useBigLoader();

  const progress = (state: ClientButton) => {
    loader.start(translate('Changing the state, please wait...'));

    return http
      .put(`/api/projects/${project.id}/state`, {
        input: state.value,
      })
      .then(() => {
        onRefresh();
        loader.stop();
      })
      .catch((err) => {
        setResponse(err.response.data.data);
        setModal(true);
        setModalAction(state.value);
        loader.stop();
      });
  };

  return (
    <div className="project-actions flex">
      <Menu
        autoClose={true}
        handle={({ toggleMenu }) => (
          <Button
            color="white"
            size="small"
            radius="full"
            icon="more_horiz"
            onClick={toggleMenu}
          ></Button>
        )}
      >
        <div className="p-2">
          {permissionRequest(project, 'project.clone') && (
            <Clone project={project} />
          )}
          {secondaryStates.map((state: ClientButton) => {
            if (!permissionRequest(project, 'project.' + state.value)) {
              return null;
            }
            if (state.extra.confirm) {
              return (
                <Confirmation
                  key={state.value}
                  onConfirm={() => progress(state)}
                  trigger={({ onClick }) => (
                    <MenuItem
                      icon={state.extra.icon ? state.extra.icon : null}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                      }}
                    >
                      {state.label}
                    </MenuItem>
                  )}
                  heading={translate(state.label)}
                  buttonText={translate('Yes, :value', {
                    value: translate(`${state.value}`),
                  })}
                  color="yellow"
                >
                  {translate('Are you sure you want to :action this project?', {
                    action: translate(`${state.value}`),
                  })}
                </Confirmation>
              );
            }

            return (
              <MenuItem
                icon={state.extra.icon ? state.extra.icon : null}
                key={state.value}
                onClick={() => {
                  progress(state);
                }}
              >
                {translate(state.label)}
              </MenuItem>
            );
          })}

          {(!project.parents || project.parents.length === 0) &&
              permissionRequest(project, 'project.amend') && (
                <Amend project={project} />
              )}

          {config.integration === 'demo' &&
            permissionRequest(project, 'project.renew') && (
              <Renew project={project} />
            )}
          {permissionRequest(project, 'project.reset') && (
            <Reset project={project} onRefresh={onRefresh} />
          )}
          {/*{permissionRequest(project, 'project.request_access') && (*/}
          {/*  <RequestAccess onClick={() => setModalAccess(true)} />*/}
          {/*)}*/}
          {permissionRequest(project, 'project.delete') && (
            <Delete project={project} />
          )}
        </div>
      </Menu>
      {config.help && (
        <>
          <Button
            color="white"
            size="small"
            radius="full"
            icon="help"
            onClick={() => setHelpModal(true)}
            className="ml-2"
          ></Button>
          <Modal
            isOpen={help}
            onClose={() => setHelpModal(false)}
            containerStyle={{
              height: '80vh',
            }}
            style={{
              content: {
                width: '80%',
                maxWidth: '1200px',
              },
            }}
            heading={config.help ? config.help.heading : translate('Help')}
          >
            {config.help &&
              config.help.items.map((item: any, index: number) => {
                return (
                  <div
                    key={`${item.title}${index}`}
                    className={`${index === 0 ? 'mb-12' : 'my-12'}`}
                  >
                    <div className="flex">
                      <h2 className="text-lg text-bold border-b-2 py-2">
                        {item.title}
                      </h2>
                    </div>
                    <p className="my-2">{item.subtitle}</p>
                  </div>
                );
              })}
            <Modal.Footer className="flex justify-end">
              <Button color="white" onClick={() => setHelpModal(false)}>
                {translate('Close')}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <Modal
        onClose={() => setModalAccess(false)}
        heading={translate('Reqest Access')}
        isOpen={modalAccess}
      >
        <Form
          initialValues={{ request_message: '' }}
          onSubmit={(values) => {
            bigLoader.start(translate('Sending request...'));

            return http.post('/api/access/create', {
              requester_id: user.id,
              project_id: project.id,
              request_message: values.request_message,
            });
          }}
          onError={() => {
            bigLoader.stop();
          }}
          onSuccess={() => {
            bigLoader.stop();
            setModalAccess(false);
            success(`${translate('Request was successfully send!')}`);
          }}
        >
          <div className="mb-6">
            <Message.Warning>
              {translate(
                'Request access, or switch to an account with access.',
              )}
            </Message.Warning>
          </div>

          <Form.TextInput
            name="request_message"
            label={translate('Reason for accesss')}
            className="mb-6"
            placeholder={translate('Message to your admin...')}
            required
            autoFocus={true}
          />

          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={() => setModalAccess(false)}>
              {translate('Cancel')}
            </Button>

            <Form.Submit color="yellow">
              {translate('Request access')}
            </Form.Submit>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        heading={
          response?.title
            ? response.title
            : `${translate('Before you continue')}...`
        }
        isOpen={modal}
        onClose={() => setModal(false)}
        size="regular"
      >
        <div>
          <Form
            initialValues={{ ...response?.state }}
            onSubmit={(values) =>
              http.put(`/api/projects/${project.id}/state`, {
                dialog: values,
                input: modelAction,
              })
            }
            onSuccess={() => {
              onRefresh();
              setModal(false);
            }}
            allowPristineSubmission
          >
            {response?.text && <div className="my-10">{response.text}</div>}
            {response &&
              response.form.map((field: FieldTemplate, index: number) => (
                <CustomField key={field.name + index} field={field} option="" />
              ))}

            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={() => setModal(false)}>
                {translate('Cancel')}
              </Button>
              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
