import React, { FC, useContext, useCallback, useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import { FieldTemplate, Project } from "@contractool/schema";
import { FormTab, WorklfowTab } from "@contractool/schema/FormTab";

import { DefaultForm } from "./DefaultForm";
import ProgressFormBadge from "./ProgressFormBadge";

import { Page } from "components/layout/Page";
import { Form } from "components/Form";
import { Button } from "components/Button";
import { FormTabs, Tab } from "components/Tabs";
import { InviteUserModal } from "components/form/InviteUserModal";
import { Mutable as ProjectTitle } from "components/form/ProjectTitle";

import { useToasts } from "hooks";
import { http } from "utils/http";
import { translate } from "utils/translations";
import { disableTab, goToNextTab } from "utils/newProject";
import { EventEmitter } from "utils/eventEmitter";
import { fromQueryString, toQueryString } from "utils/url";
import { useRequest } from "hooks/useRequest";
import { getWorkflow } from "hooks/workflows";

import { AppContext } from "contexts";

import ProjectFormProvider from "views/project/ProjectFormProvider";
import { useLocalStorage } from "@rehooks/local-storage";
import transformObject from "utils/transformOptionToValue";
import FormIoForm from "views/newproject/FormIoForm";

type Props = {
    initialProject: Project;
    tab?: string;
    onChange?: (field: FieldTemplate) => void;
};

const FormContainer: FC<Props> = ({ initialProject, tab, onChange }) => {
    return <DefaultForm project={initialProject} tab={tab} onChange={onChange} />;
};

export function NewProjectForm() {
    const history = useHistory();

    const close = () => {
        history.push(`/projects/new/form${history.location.search}`);
    };
    const { success } = useToasts();

    const searchParams = fromQueryString(history.location.search);

    let url = `api/projects/new${history.location.search}`;
    let clone = false;
    if (searchParams && searchParams.clone !== undefined) {
        url = `api/projects/new/${searchParams.clone}${history.location.search}`;
        clone = true;
    }
    let [initialProject] = useRequest<Project | undefined>(url, undefined);

    const workflow: string =
        initialProject?.workflow || (searchParams.workflow as string) || "default";

    const workflowObject = getWorkflow(workflow);

    const [draft, setDraft, clearDraft] =
        useLocalStorage<Project>("_projectDraft");
    const [, setCreatedPopup] = useLocalStorage("_createdPopup");

    const handleSuccess = (data: any) => {
        console.log("handleSuccess", data);
        if (data.id) {
            clearDraft();
            success(`${translate("Project was created")}.`);
            if (data.created_popup) {
                setCreatedPopup(data.created_popup);
            }
            history.push("/projects/" + data.id);
        } else {
            success(`${translate("Draft saved")}.`);
        }
    };

    if (workflowObject && workflowObject.fields_config) {
        return (
            <div className="new-project">
                <FormIoForm
                    workflow={workflowObject}
                    onSubmit={(values: object) => {
                        http
                            .post<Project>(
                                "/api/projects-formio?workflow=" + workflowObject.key,
                                values
                            )
                            .then((response) => handleSuccess(response.data));
                    }}
                />
            </div>
        );
    }

    const formTabs = workflowObject.form_tabs;
    const metaData: FieldTemplate[] = workflowObject.fields.meta_data;
    const settings = workflowObject.settings;
    const [workflowTabs, setWorkflowTabs] = useState<WorklfowTab[]>([]);

    const activeTab = workflowTabs.find((tab) => tab?.active);
    const isRegularForm = metaData.every(
        (field) => field.progressFormOnClick === false
    );

    if (!clone && draft && initialProject) {
        if (
            (searchParams.workflow && searchParams.workflow === draft.workflow) ||
            (initialProject && initialProject.workflow === draft.workflow)
        ) {
            if (searchParams.fields) {
                history.replace(
                    `/projects/new/form?workflow=${searchParams.workflow}&kind=Create%20project`
                );
            } else {
                initialProject = draft;
            }
        } else {
            setDraft(initialProject);
        }
    }

    useEffect(() => {
        if (formTabs) {
            setWorkflowTabs(
                formTabs.map((tab: FormTab, index: number): WorklfowTab => {
                    // Initial workflow setup based on URL param
                    if (searchParams.tab) {
                        const active = index === Number(searchParams.tab) ? true : false;
                        const valid = index <= Number(searchParams.tab);
                        return {
                            ...tab,
                            active,
                            validated: false,
                            valid
                        };
                    }

                    // Initial workflow setup based on config file
                    const active = index === 0 ? true : false;
                    const valid = active;
                    return {
                        ...tab,
                        active,
                        validated: false,
                        valid
                    };
                })
            );
        }
    }, [formTabs]);

    const handleSubmit = useCallback(
        async (values): Promise<any> => {
            const lastTab: FormTab =
                formTabs.length === 0 ? null : formTabs[formTabs.length - 1];

            const params = {
                tab: activeTab?.id
            };

            const validationResult = await http.post<Project>(
                `/api/projects/validate?${toQueryString(params)}`,
                transformObject(values)
            );

            goToNextTab({ formTabs, activeTab, setWorkflowTabs });

            // Call if validation went through
            // and user is on last tab
            if (activeTab?.id === lastTab?.id) {
                return http.post<Project>("/api/projects", transformObject(values));
            }

            if (validationResult) {
                return validationResult;
            }

            return null;
        },
        [formTabs, workflowTabs]
    );

    const handleUserModalClose = useCallback(() => {
        EventEmitter.dispatch("api/users.invalidated-no-current", []);
        close();
    }, [close]);

    const formName = "new-project";
    EventEmitter.unsubscribe("form.setValues." + formName);
    EventEmitter.subscribe("form.setValues." + formName, (values: Project) => {
        setDraft(values);
    });

    const handleSelectTab = (name: string) => {
        const titleElement = document.getElementById("page-title");
        if (titleElement) {
            titleElement.scrollIntoView({ behavior: "smooth" });
        }

        setWorkflowTabs(
            workflowTabs.map((tab) => {
                if (tab?.title === name) {
                    return {
                        ...tab,
                        active: true
                    };
                }
                return {
                    ...tab,
                    active: false
                };
            })
        );
    };

    return (
        <>
            <Switch>
                <Route
                    path={"/projects/new/form/new-user/:userGroup"}
                    render={() => <InviteUserModal onClose={handleUserModalClose} />}
                />
            </Switch>
            <Page
                heading={translate("New Project Request")}
                right={
                    <>
                        {!clone && draft && (
                            <Button
                                color="white"
                                onClick={() => {
                                    clearDraft();
                                    window.location.reload();
                                }}
                            >
                                {translate("Clear draft")}
                            </Button>
                        )}
                    </>
                }
            >
                {initialProject && (
                    <Form
                        name={formName}
                        initialValues={{
                            ...initialProject
                        }}
                        onSuccess={handleSuccess}
                        onSubmit={handleSubmit}
                        allowPristineSubmission={true}
                        guard={false}
                        loader={"big"}
                    >
                        <div style={{ maxWidth: "960px" }}>
                            <ProjectFormProvider create={true}>
                                <>
                                    {formTabs.length > 0 ? (
                                        <>
                                            <ProjectTitle
                                                className="mb-8 mt-8"
                                                legend={settings.title_legend}
                                            />
                                            <FormTabs
                                                selected={activeTab?.title}
                                                onSelect={handleSelectTab}
                                                onAnimateEnd={() =>
                                                    document
                                                        .querySelector("main")
                                                        .scroll({ top: 0, behavior: "smooth" })
                                                }
                                            >
                                                {formTabs.map(
                                                    (tab: FormTab, index: number, array: FormTab[]) => {
                                                        let nextTabIndex = index++;
                                                        nextTabIndex++;
                                                        const displayNextStepButton =
                                                            nextTabIndex !== array.length;
                                                        const displayCreateProjectButton =
                                                            displayNextStepButton === false;

                                                        return (
                                                            initialProject && (
                                                                <Tab
                                                                    key={tab?.id}
                                                                    name={tab?.title}
                                                                    heading={tab?.title}
                                                                    className="w-full h-auto overflow-auto mb-20"
                                                                    disabled={disableTab(workflowTabs, tab)}
                                                                >
                                                                    <ProgressFormBadge
                                                                        fields={metaData}
                                                                        initialProject={initialProject}
                                                                    />
                                                                    <FormContainer
                                                                        initialProject={initialProject}
                                                                        tab={tab?.id}
                                                                        onChange={(field: FieldTemplate) => {
                                                                            if (field.progressFormOnClick) {
                                                                                goToNextTab({
                                                                                    formTabs,
                                                                                    activeTab,
                                                                                    setWorkflowTabs
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                    {(isRegularForm ||
                                                                        displayCreateProjectButton) && (
                                                                        <Form.Submit mode="">
                                                                            {translate(
                                                                                displayNextStepButton
                                                                                    ? "Next Step"
                                                                                    : "Create Project"
                                                                            )}
                                                                        </Form.Submit>
                                                                    )}
                                                                </Tab>
                                                            )
                                                        );
                                                    }
                                                )}
                                            </FormTabs>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                <ProjectTitle
                                                    className="mb-8 mt-8"
                                                    legend={settings.title_legend}
                                                />
                                            }
                                            <FormContainer initialProject={initialProject} />
                                            <Form.Submit mode="">
                                                {translate("Create Project")}
                                            </Form.Submit>
                                        </>
                                    )}
                                </>
                            </ProjectFormProvider>
                        </div>
                    </Form>
                )}
            </Page>
        </>
    );
}
