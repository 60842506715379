import { useToasts } from 'hooks';
import { isArray, isObject, reduce, snakeCase } from 'lodash';

import { http } from './http';

const to_snake_case = (obj: any): any => {
  if (!isObject(obj)) {
    return obj;
  } else if (isArray(obj)) {
    return obj.map((value) => to_snake_case(value));
  }
  return reduce(
    obj,
    (result, value, key) => {
      return {
        ...result,
        [snakeCase(key)]: to_snake_case(value),
      };
    },
    {},
  );
};

type Props = {
  url: string;
  method: 'POST' | 'PUT' | 'DELETE' | 'GET';
  refresh: () => void;
  payload?: object;
  message?: string;
};

const mutation = async ({ url, method, refresh, payload, message }: Props) => {
  const { error, success } = useToasts();

  const sucessToast = (message: string) =>
    success(message, {
      position: 'top-center',
    });

  const errorToast = (action: string) =>
    error(action, {
      position: 'top-center',
    });

  try {
    if (method === 'GET') {
      await http.get(url);
    }
    if (method === 'POST') {
      await http.post(url, payload);
    }
    if (method === 'PUT') {
      await http.put(url, payload);
    }
    if (method === 'DELETE') {
      await http.delete(url);
    }
    refresh();

    if (message) {
      sucessToast(message);
    }
  } catch (e) {
    errorToast(e.response.data.message);
  }
};

export { to_snake_case, mutation };
