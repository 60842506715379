import React from 'react';
import { Field, FieldInputProps, FormikProps, FormikValues } from 'formik';

import { Confirmation } from 'components/Confirmation';
import { TextInput } from 'components/TextInput';
import { Icon } from 'components/Icon';

import { translate } from 'utils/translations';

type Props = {
  index: number;
  remove: (index: number) => void;
  onCreate: (
    parentField: FieldInputProps<any>,
    field: FieldInputProps<any>,
  ) => void;
  onUpdate: (
    index: number,
    parentField: FieldInputProps<any>,
    field: FieldInputProps<any>,
  ) => void;
  onDelete: (index: number, parentField: FieldInputProps<any>) => void;
};

const Item: React.FC<Props> = ({
  index,
  remove,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const handleBlur = async (
    parentField: FieldInputProps<any>,
    field: FieldInputProps<any>,
    form: FormikProps<FormikValues>,
  ) => {
    if (field.value) {
      form.setFieldValue(parentField.name, {
        ...parentField.value,
        heading: field.value,
        new: false,
      });

      if (parentField.value.new) {
        onCreate(parentField, field);
      } else {
        onUpdate(index, parentField, field);
      }
    }
  };

  const handleRemove = async (
    index: number,
    parentField: FieldInputProps<any>,
  ) => {
    onDelete(index, parentField);
    remove(index);
  };

  const handleChange = (
    form: FormikProps<FormikValues>,
    parentField: FieldInputProps<any>,
    value: string,
  ) =>
    form.setFieldValue(parentField.name, {
      ...parentField.value,
      heading: value,
    });

  return (
    <div className="flex items-center text-gray-700">
      <Field name={`categories.${index}`}>
        {({ field: parentField }: any) => (
          <>
            <div className="drag-handle cursor-pointer mr-6">
              <Icon name="reorder" size={6} className="text-gray-500" />
            </div>
            <div className="flex-auto">
              <Field name={`categories.${index}.heading`}>
                {({ field, form, meta }: any) => (
                  <TextInput
                    {...field}
                    hasError={meta.touched && meta.error}
                    input={{
                      className: 'leading-tighter',
                    }}
                    onBlur={() => handleBlur(parentField, field, form)}
                    onChange={(value: string) =>
                      handleChange(form, parentField, value)
                    }
                  />
                )}
              </Field>
            </div>
            <div className="flex w-6">
              {parentField?.value?.new === false && (
                <Confirmation
                  onConfirm={() => handleRemove(index, parentField)}
                  trigger={({ onClick }) => (
                    <div onClick={onClick} title={translate('Delete tab')}>
                      <Icon
                        name="delete"
                        size={6}
                        className="text-gray-500 cursor-pointer delete-report"
                      />
                    </div>
                  )}
                  heading={translate('Remove tab')}
                  buttonText={translate('Yes, remove')}
                  color="red"
                >
                  {translate(
                    "Are you sure you want to remove this tab, with all included data? This action can't be undone.",
                  )}
                </Confirmation>
              )}
            </div>
          </>
        )}
      </Field>
    </div>
  );
};

export default Item;
