export * from '@formio/react';
import { Form, FormBuilder as FormioBuilder } from '@formio/react';

const Hoc = (Component) => {
  const component = (props) => (
    <div className="formio">
      <Component {...props} />
    </div>
  );
  return component;
};

export const FormRenderer = Hoc(Form);
export const FormBuilder = Hoc(FormioBuilder);
