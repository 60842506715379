import baseEditForm from 'formiojs/components/_classes/component/Component.form';

const config = () =>
  baseEditForm([
    {
      key: 'display',
      components: [],
    },
    {
      key: 'data',
      components: [],
    },
    {
      key: 'validation',
      components: [],
    },
    {
      key: 'api',
      components: [],
    },
    {
      key: 'conditional',
      components: [],
    },
    {
      key: 'logic',
      components: [],
    },
    {
      key: 'layout',
      components: [],
    },
  ]);

export default config;
