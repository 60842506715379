import PropertyList from './PropertyList/PropertyList';
import SelectRole from './Select/Role';
import SelectSupplier from './Select/Supplier';
import SelectSupplierPOC from './Select/SupplierPOC';
import DataExtractionFileUpload from './DataExtractionFileUpload';
import DataExtraction from './DataExtraction';
import PropertyListUpload from './propertyListUpload';

export default {
  selectRole: SelectRole,
  selectSupplier: SelectSupplier,
  selectSupplierPOC: SelectSupplierPOC,
  dataExtractionFileUpload: DataExtractionFileUpload,
  dataExtraction: DataExtraction,
  propertyListUpload: PropertyListUpload,
  propertyList: PropertyList,
};
