import { EChartsOption } from 'echarts-for-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Form as CommonForm } from 'components/Form';
import { Context as ReportContext } from 'contexts/reports/tab/report';
import { Context as ReportTabContext } from 'contexts/reports/tab';
import { http } from 'utils/http';
import Chart from '../Report/Chart';
import Header from '../Report/Header';
import { Button } from 'components/Button';
import Filters from './Filters';
import { isUserInGroup } from 'utils/auth';
import { GuardContext } from 'contexts';
import { Modal } from 'components/Modal';
import { Form } from 'components/Form';
import { translate } from 'utils/translations';
import { useRequest } from 'hooks/useRequest';
import { ReportTab } from '@contractool/schema';
import { useToasts } from 'hooks';

interface LibraryReport {
  category: string;
}

const style = {
  padding: '10px 0',
  marginBottom: 40,
  height: '100%',
  border: '1px solid lightgray',
};

const ReportLibrary: React.FC = () => {
  const history = useHistory();
  const { idx: tabIdx } = useContext(ReportTabContext);
  const { idx, config } = useContext(ReportContext);
  const { user } = useContext(GuardContext);

  const { success } = useToasts();

  const [isModalOpen, setModalOpen] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const [tabs] = useRequest<ReportTab[]>('/api/report-tabs', []);

  const onReportList = useCallback(
    (data: any) => {
      let name = data.data?.name || data.name;
      name = name.split(' - (')[0]; //!!! hardcoded in App\Reports\Echart\Pie.php
      history.push({
        pathname: `/reports-library/${tabIdx}/${idx}/project-list`,
        search: name ? `name=${encodeURIComponent(name)}` : '',
      });
    },
    [history, idx, tabIdx],
  );

  const ref = useRef(null);
  // todo: refactor / resolve the issue without using DOM element
  // trick for filters panel overlap issue
  useEffect(() => {
    if (ref.current) {
      (ref.current || ({} as any)).parentNode.style.zIndex = config.collapsed
        ? 0
        : 1;
    }
  }, [config]);

  const { metric, groupBy, chartType, filters, grid, workflow } = config;
  const [chartOptions, setChartOptions] = useState<EChartsOption>(null);
  const serializedFilters = JSON.stringify(filters);
  const fetchChart = useCallback(() => {
    http
      .post(`/api/reports/generic-report?workflow=${workflow}`, {
        metric,
        groupBy,
        chartType,
        filters,
      })
      .then((response: any) => {
        const data = response?.data;
        if (
          data?.series?.type === 'pie' &&
          data?.series?.data.length > 12 &&
          data?.series?.data.length / ((grid?.h - 1) * 3) > 5
        ) {
          data.legend.width = 30;
          data.legend.left = '30%';
          data.legend.textStyle = {
            width: 1800 / (data?.series?.data.length / (grid?.h - 1)),
            ellipsis: '...',
            overflow: 'truncate',
          };
          data.series.center = ['15%', '50%'];
        }

        setChartOptions(response?.data);
      });
  }, [serializedFilters, workflow, metric, groupBy, chartType, grid.h]);

  const handleFormSubmit = React.useCallback(
    async (values: LibraryReport) => {
      return http.post<LibraryReport>(
        `/api/report-tabs-copy/${values.category}/${idx}`,
        values,
      );
    },
    [idx],
  );

  const handleClose = React.useCallback(() => {
    setModalOpen(false);
    history.push(`/reports-library/${tabIdx}`);
  }, [history, tabIdx]);

  useEffect(() => {
    setCategoriesOptions(
      tabs.map((tab) => ({
        label: tab.heading,
        value: tab.id,
      })),
    );
  }, [tabs]);

  const handleSuccess = React.useCallback(async () => {
    // TODO: add toast
    success(translate('Report added to your reports'));
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    fetchChart();
  }, [fetchChart]);

  return (
    chartOptions && (
      <div className="bg-white flex rounded-xl" style={style} ref={ref}>
        <div
          className="flex-auto flex flex-col pl-8 pr-18"
          style={{ minWidth: 0 }}
        >
          <div className="mr-16">
            <Header />
          </div>
          <div className="flex-auto overflow-auto">
            <Chart options={chartOptions} onReportList={onReportList} />
          </div>
        </div>
        {isUserInGroup(user, 'admin') ? (
          <Filters />
        ) : (
          <>
            <div className="flex absolute right-0 top-0 h-full py-3">
              <div className="flex pt-4 p-3 ">
                <div>
                  <Button
                    icon="add"
                    size="small"
                    color="lightblue"
                    onClick={() => setModalOpen(true)}
                  >
                    {translate('Add to my reports')}
                  </Button>
                </div>
              </div>
            </div>
            <Modal
              heading={translate('Add report to library')}
              onClose={handleClose}
              isOpen={isModalOpen}
              size="small"
            >
              <Form
                initialValues={{
                  category: '',
                }}
                onSubmit={handleFormSubmit}
                onSuccess={handleSuccess}
              >
                <CommonForm.Dropdown
                  name="category"
                  label={translate('Category')}
                  options={categoriesOptions}
                  className="mt-6"
                />
                <Modal.Footer className="flex justify-between">
                  <Button color="white" onClick={() => setModalOpen(false)}>
                    {translate('Cancel')}
                  </Button>
                  <Form.Submit>{translate('Submit')}</Form.Submit>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </div>
    )
  );
};

export default ReportLibrary;
