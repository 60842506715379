import React, {useContext} from 'react';
import {FilterTemplate} from '@contractool/schema';

import Search from 'components/Search';
import {
    MultiselectDropdown,
    RenderMultiselectProps,
    SelectedItems,
} from 'components/MultiselectDropdown';
import FetchMultiSelect from 'components/select/FetchMultiSelect';
import {Option} from 'components/Dropdown';
import {Icon} from 'components/Icon';

import {useRequest} from 'hooks/useRequest';

import {EventEmitter} from 'utils/eventEmitter';
import {translate} from 'utils/translations';

import {Cruncher} from './Cruncher';

import {AppContext} from 'contexts';

export function ProjectsFilters({params, setParam, setParams}: {params: any; setParam: any; setParams: any}) {
    const basic = [
        'workflows',
        'projects.state',
        'category_id',
        'fields->country',
        'supplier_id',
        'teamMemberships(user_id)[role=assignee]',
    ];
    const {assessmentWorkflow} = useContext(AppContext);
    const [templates] = useRequest<FilterTemplate[]>(
        `/api/project-filter-templates?workflow=${assessmentWorkflow}`,
        [],
    );

    const states = templates.find((item: FilterTemplate) => item.name === 'projects.state');
    const categories = templates.find((item: FilterTemplate) => item.name === 'category_id');
    const assignees = templates.find(
        (item: FilterTemplate) => item.name === 'teamMemberships(user_id)[role=assignee]',
    );
    const suppliers = templates.find((item: FilterTemplate) => item.name === 'supplier_id');

    const castValuesNumber = (values: any[]) => {
        return values.map(Number);
    };

    return (
        <div className="mb-8">
            <div className="flex w-full">
                <div className="flex-1">
                    <Search
                        value={params.phrase}
                        onChange={(phrase) => {
                            setParams({
                                'phrase': phrase,
                                'page': 1
                            });
                        }}
                        onClear={() => setParam('phrase', '')}
                        placeholder={`${translate('Search Projects')}...`}
                    />
                </div>
                <Cruncher phrase={params.phrase ? params.phrase : ''} />
            </div>
            <div className="flex justify-between">
                <div className="flex space-x-10 mt-4">
                    {categories && categories.panel && (
                        <MultiselectDropdown
                            values={castValuesNumber(params.categories)}
                            name="category"
                            options={categories.values as Option<number>[]}
                            placeholder={translate('Category')}
                            onChange={(res) => {
                                setParams({
                                    'categories': res.map((item) => item.value),
									'page': 1
								});
                                EventEmitter.dispatch('search-filter', {
                                    categories: res.map((val) => val.label),
                                });
                            }}
                            useSearch={true}
                            searchPlaceholder={`${translate('Search categories')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {states && states.panel && (
                        <MultiselectDropdown
                            values={params.states}
                            name="projects.state"
                            options={states.values}
                            placeholder={translate('State')}
                            onChange={(res) => {
                                setParams({
                                    'states': res.map((item) => item.value),
									'page': 1
								});
                                EventEmitter.dispatch('search-filter', {
                                    states: res.map((val) => val.label),
                                });
                            }}
                            useSearch={true}
                            searchPlaceholder={`${translate('Search states')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {assignees && (
                        <FetchMultiSelect
                            values={castValuesNumber(
                                params.team?.assignee ? params.team.assignee : [],
                            )}
                            name="assignee"
							api={assignees.props.api}
                            placeholder={translate('Assignee')}
                            onChange={(res) => {
                                const newParams = {
                                    assignee: res.map((item) => item.value),
                                };
                                setParams({
									'team': newParams,
									'page': 1
								});
                                EventEmitter.dispatch('search-filter', newParams);
                            }}
                       
                            searchPlaceholder={`${translate('Search assignees')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {suppliers && (
                        <FetchMultiSelect
                            api="/api/suppliers"
                            values={castValuesNumber(params.suppliers)}
                            name="supplier"
                            placeholder={translate('Supplier')}
                            onChange={(res: any) => {
                                setParams({
                                    'suppliers': res.map((item: any) => item.value),
									'page': 1
								});
                                EventEmitter.dispatch('search-filter', {
                                    suppliers: res.map((val: any) => val.label),
                                });
                            }}
                            searchPlaceholder={`${translate('Search suppliers')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {templates
                        .filter((f) => f.panel && basic.indexOf(f.name) === -1)
                        .filter((f) => {
                            //This removes workflow filter if there is only one workflow in the integration.
                            if (f.name !== 'workflow') {
                                return true;
                            }
                            return f.values.length > 1;
                        })
                        .map((filter: FilterTemplate) => {
                            return (
                                <MultiselectDropdown
                                    key={`filter-panel-${filter.name}`}
                                    values={params[filter.name] ? params[filter.name] : []}
                                    name={filter.name}
                                    options={filter.values}
                                    placeholder={filter.label}
                                    onChange={(res) => {
                                        setParams(
                                            filter.name,
                                            res.map((item) => item.value),
                                        );
                                    }}
                                    useSearch
                                    searchPlaceholder={`${translate('Search')}...`}
                                    renderInput={RenderMultiselect}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

function RenderMultiselect<T>({
    name,
    selectedItems,
    hasError,
    placeholder,
    isOpen,
    getToggleButtonProps,
    shownItemSize,
}: RenderMultiselectProps<T>) {
    return (
        <div
            className={`multiselect-${name} flex items-center justify-between text-gray-500 cursor-pointer focus:outline-none ${
                selectedItems.length && 'text-blue-500'
            } ${hasError && 'border-red-700'}`}
            {...getToggleButtonProps()}
        >
            <div className="mr-3">
                {!isOpen && selectedItems.length ? (
                    <SelectedItems selectedItems={selectedItems} shownSize={shownItemSize} />
                ) : (
                    placeholder
                )}
            </div>
            <Icon
                name="triangle"
                className={`inline-block align-middle text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
                size={2}
            />
        </div>
    );
}
