import React, { useContext, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { useQueryParams } from 'hooks/useParams';
import { useRequest } from 'hooks/useRequest';
import { translate } from 'utils/translations';
import { Page } from 'components/layout/Page';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import { Supplier } from '@contractool/schema';
import { SupplierEdit } from './SuppliersEdit';
import { SupplierNew } from './SuppliersNew';
import { Pagination } from 'components/Pagination';
import Search from '../../components/Search';
import { AppContext } from 'contexts';
import { useToasts } from 'hooks';
import { http } from 'utils/http';

export function SuppliersPage() {
  const history = useHistory();

  const urlParams = new URLSearchParams(history.location.search);
  const [searchInput, setPhrase] = useState<string>(
    urlParams.get('phrase') || '',
  );

  const [params, { setParam, setParams }] = useQueryParams({
    phrase: '',
    page: 1,
    per_page: 30,
  });
  const [suppliers, { refresh, pagination }] = useRequest<Supplier[]>(
    '/api/suppliers',
    [],
    {
      params: {
        ...params,
        phrase: encodeURIComponent(searchInput),
      },
    },
  );
  const { config } = useContext(AppContext);
  const [newSupplierModalOpened, setNewSupplierModalOpened] =
    React.useState<boolean>(false);

  const { error } = useToasts();

  const sendExportRequest = () => {
    http.post('/suppliers/exports?phrase=' + params.phrase).catch(() => {
      error(translate('Error in creating export. Please try again later.'));
    });
  };

  return (
    <Page
      heading={translate(config.config.supplier_label_plural)}
      right={
        <Button
          onClick={() => {
            setNewSupplierModalOpened(true);
          }}
          color="blue"
          icon="add"
        >
          {translate('Add ' + config.config.supplier_label_singular)}
        </Button>
      }
    >
      <div className="mb-8">
        <Search
          value={searchInput}
          onChange={(phrase) => {
            setParams({ phrase, page: 1 });
            setPhrase(phrase);
          }}
          onClear={() => setParam('phrase', '')}
          placeholder={`${translate('Search')}...`}
        />
      </div>

      <div className="-mx-6 mb-8 relative">
        <div className="flex justify-end">
          <span
            className="cursor-pointer flex items-center mr-6 text-gray-600"
            data-testid="export"
            onClick={sendExportRequest}
          >
            <Icon name="file-excel" size={5} className="mr-3" />
            {translate('Export')}
          </span>
        </div>
      </div>

      <div className="-mx-6">
        <SuppliersList suppliers={suppliers} />
      </div>

      <div className="flex justify-center pt-16">
        <Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          totalBlocks={7}
          onSelect={(page) => setParam('page', page)}
        />
      </div>

      {newSupplierModalOpened && (
        <SupplierNew
          onCreate={() => refresh()}
          setModalOpened={setNewSupplierModalOpened}
        />
      )}
      <Switch>
        <Route path="/suppliers/:id/edit">
          <SupplierEdit onUpdate={() => refresh()} />
        </Route>
      </Switch>
    </Page>
  );
}

function SuppliersList({ suppliers }: { suppliers: Supplier[] }) {
  const history = useHistory();
  const { config } = useContext(AppContext);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>{translate(config.config.supplier_label_singular)}</th>
            <th>{translate('Country')}</th>
            <th>{translate('Contacts')}</th>
            <th>{translate('Projects')}</th>
            <th className="w-16"></th>
          </tr>
        </thead>
        <tbody>
          {suppliers.map((supplier) => (
            <tr
              key={supplier.id}
              className="hover:bg-gray-000 cursor-pointer rounded"
              onClick={() => history.push(`/suppliers/${supplier.id}/edit`)}
            >
              <td>{supplier.title}</td>
              <td className="text-gray-600">{supplier.address_state}</td>
              <td className="text-gray-600">{supplier.contacts_count}</td>
              <td>{supplier.projects_count}</td>
              <td>
                {/* TODO: do we need this? */}
                <Icon name="more_horiz" className="text-gray-600" size={6} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
