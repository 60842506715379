import React, { useCallback, useContext, useState } from 'react';

import { Components, FormRenderer } from 'components/Formio';
import { Workflow } from '../../../../_common/schema/Workflow';
import { translate } from 'utils/translations';
import { Page } from 'components/layout/Page';

import components from 'components/Formio/Custom';
import { AppContext, PusherContext } from 'contexts';
import { http } from 'utils/http';
import { useSmallLoader } from 'components/Loader';

function checkExists(component): boolean {
  if (component.type === 'dataExtractionFileUpload') {
    return true;
  }

  if (component.components) {
    for (const subComponent of component.components) {
      if (checkExists(subComponent)) {
        return true;
      }
    }
  }

  return false;
}

function checkForm(form): boolean {
  for (const component of form.components) {
    if (checkExists(component)) {
      return true;
    }
  }

  return false;
}

type ObjectType = {
  [key: string]: any;
};

function replaceValues(object1: ObjectType, object2: ObjectType): ObjectType {
  const result: ObjectType = { ...object1 };

  for (const key in object1) {
    if (Object.prototype.hasOwnProperty.call(object2, key)) {
      result[key] = object2[key];
    }
  }

  return result;
}

type Props = {
  onSubmit: (values: object) => void;
  workflow: Workflow;
};

const FormIoField: React.FC<Props> = ({ onSubmit, workflow }) => {
  Components.setComponents(components);

  const pusher = React.useContext(PusherContext);
  const config = useContext(AppContext);

  const [isDataExtraction] = useState(checkForm(workflow.fields_config));
  const [listening, setListening] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [extractedData, setExtractedData] = useState(null);

  const [instanceForm, setInstanceForm] = useState(null);
  const { stop } = useSmallLoader();
  if (pusher && pusher.private) {
    if (listening && fileId) {
      pusher.leave(`${config.config.integration}-file-extraction-${fileId}`);
      pusher.listen(
        `${config.config.integration}-file-extraction-${fileId}`,
        '.DataExtracted',
        (payload: any) => {
          http
            .get(`/api/file-entity-extraction/result/${payload.documentId}`, {})
            .then((res: any) => {
              stop();
              // Merge two object with same keys
              const replacedValuesObject = replaceValues(instanceForm, res);
              setExtractedData(replacedValuesObject);
            });
        },
      );
    }
  }

  const handleKeyExtraction = useCallback(
    (e, form) => {
      if (isDataExtraction) {
        if (!instanceForm) {
          setInstanceForm(form?.changes?.[0]?.instance._data);
        }
        if (e.data['fileID']) {
          setFileId(e.data['fileID']);
          setListening(true);
        }
        setTimeout(() => {
          // @ts-ignore
          form?.changes?.[0]?.instance.root.triggerRedraw();
        }, 1000);
      }
    },
    [isDataExtraction],
  );

  return (
    <Page heading={translate('New Project Request')}>
      <div className="divide-y divide-gray-100">
        <div className="py-8 w-full">
          <FormRenderer
            submission={
              extractedData && {
                data: extractedData,
              }
            }
            form={workflow.fields_config}
            onChange={handleKeyExtraction}
            onSubmit={({ data }) => onSubmit(data)}
          />
        </div>
      </div>
    </Page>
  );
};

export default FormIoField;
