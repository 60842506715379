import { Role, TeamMember, User } from '@contractool/schema';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { ProjectTeamRoleSelect } from 'components';
import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { Dropdown, Option } from 'components/Dropdown';
import { Menu, MenuItem } from 'components/Menu';
import { Modal } from 'components/Modal';
import { useToasts } from 'hooks';
import { useRequest } from 'hooks/useRequest';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { useSmallLoader } from 'components/Loader';

import { permissionRequest } from 'utils/wildcard';
import ProjectContext from 'views/projects/ProjectContext';
import moment from 'moment';
import { getActiveDelegation, getActiveDelegator } from 'utils/delegation';

const getVisibleMembers = (teamMembers: TeamMember[]) =>
  teamMembers.filter(({ deleted }: TeamMember) => !deleted);

export const ProjectTeamModal: FC<{
  onUpdate: () => void;
  onClose: () => void;
}> = ({ onUpdate, onClose }) => {
  const {
    resource: project,
    teamMembers,
    workflow,
  } = React.useContext(ProjectContext);
  const { success, error } = useToasts();

  const smallLoader = useSmallLoader();

  const [teamMembersInList, setMembersInList] = useState(
    getVisibleMembers(teamMembers),
  );
  useEffect(() => {
    setMembersInList(getVisibleMembers(teamMembers));
  }, [teamMembers]);
  const [isAddingMember, toggleAddingMember] = useState(false);
  const assigneeUserId = project.team.assignee?.id;
  const [newUserRole, setNewUserRole] = React.useState<Role | null>(null);
  const [users] = useRequest<User[]>('/api/users?dropdown=1', []);
  const userOptions = useMemo(
    () =>
      users
        .filter((user: User) => {
          if (!newUserRole?.groups) {
            return true;
          }
          if (newUserRole?.groups.length === 0) {
            return true;
          }
          for (let userGroupObject of user.groups) {
            if (newUserRole.groups.indexOf(userGroupObject.key) > -1) {
              return true;
            }
          }

          return false;
        })
        .map(({ id, name, delegations }) => ({
          label: getActiveDelegator(delegations, id)
            ? `${name} (Absent - delegated to ${
                getActiveDelegation(delegations).backup.name
              })`
            : name,
          // todo: investigate and fix Dropdown to support T as number
          value: String(id),
        })),
    [users, newUserRole],
  );

  const newMemberAdded = (userId: string) => {
    http.get<User>(`api/users/${userId}`).then((data) => {
      const memberTemplate = {
        id: 0,
        note: '',
        role: newUserRole?.key ?? '',
        user: data.data,
        user_id: parseInt(userId),
        deleted: false,
      };

      toggleAddingMember(false);

      smallLoader.start('Adding member...');
      http
        .post(`/api/projects/${project.id}/team/add`, memberTemplate)
        .then(async () => {
          setMembersInList([...teamMembersInList, memberTemplate]);
          setNewUserRole(null);
          await onUpdate();
          success(`${translate('Member has been added')}`);
          smallLoader.stop();
        });
    });
  };

  const deleteMember = async (member: TeamMember) => {
    if (member.user_id === assigneeUserId) {
      error(`${translate('Cannot remove assignee')}`);

      return;
    }
    smallLoader.start('Deleting...');
    try {
      await http.delete(`/api/projects/${project.id}/team/${member.id}`);
    } catch (e) {
      console.log(e);

      return;
    }

    await onUpdate();
    smallLoader.stop();
    success(`${translate('Member has been deleted')}`);
  };

  const assign = (member: TeamMember) => {
    smallLoader.start(`Assigning to ${member.user.name}...`);
    http
      .put(`/api/projects/${project.id}/team/assignee/`, {
        assignee_user_id: member.user_id,
      })
      .then(async () => {
        await onUpdate();
        success(`${translate('Assignee has been changed')}`);
        smallLoader.stop();
      });
  };

  const handleRoleSelect = (role: Role) => {
    setNewUserRole(role);
  };

  const handleUpdateMember = async (member: TeamMember, userId: string) => {
    smallLoader.start(translate('Changing member...'));
    try {
      await http.post(
        `/api/projects/${project.id}/team/changeUser/${member.id}`,
        {
          user_id: userId,
        },
      );
    } catch (e) {
      console.log(e);
      smallLoader.stop();

      return;
    }
    await onUpdate();
    success(`${translate('User has been changed')}`);
    smallLoader.stop();
  };

  const roleOptions: Option<string>[] = React.useMemo(
    () =>
      workflow.roles.map((role: Role) => ({
        value: role.key,
        label: role.label,
      })),
    [workflow],
  );

  const canEdit = permissionRequest(project, 'project.team.edit');

  return (
    <Modal heading={translate('Team')} onClose={onClose}>
      {teamMembersInList.map((member) => (
        <div
          data-testid={member.id ? member.id : 0}
          key={member.id ? member.id : 999999}
          title={member.user.name}
          className="flex items-center my-5 justify-between"
        >
          <div className="flex items-center">
            <Avatar user={member.user} className="w-13 h-13 mr-5" />
            <div className="flex items-center">
              {canEdit && (
                <Dropdown
                  name={member.user.name}
                  value={String(member.user.id)}
                  options={userOptions}
                  autocomplete={true}
                  onChange={(userId) => handleUpdateMember(member, userId)}
                />
              )}
              {!canEdit && <span>{member.user.name}</span>}
              {assigneeUserId === member.user.id ? (
                <span className="text-green-900 bg-green-100 p-1 rounded mx-5">
                  {translate('Assignee')}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-5">
              {roleOptions.find(({ value }) => member.role === value)?.label}
            </div>
            {canEdit && (
              <Menu handle="more_vert" autoClose={true}>
                <MenuItem icon="delete" onClick={() => deleteMember(member)}>
                  {translate('Delete')}
                </MenuItem>
                {permissionRequest(project, 'project.assign') && (
                  <MenuItem icon="person" onClick={() => assign(member)}>
                    {translate('Assign')}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        </div>
      ))}
      {canEdit && (
        <>
          {!isAddingMember ? (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => toggleAddingMember(true)}
            >
              <Button color="white" size="small" radius="full" icon="add" />
              <span className="text-gray-500 ml-5">
                {translate('Add person')}
              </span>
            </div>
          ) : (
            <>
              <ProjectTeamRoleSelect
                value={newUserRole?.key}
                onChange={handleRoleSelect}
              />
              {newUserRole ? (
                <Dropdown
                  name={'new_member'}
                  value={''}
                  options={userOptions}
                  placeholder={`${translate('Start typing')}...`}
                  onChange={newMemberAdded}
                  hasError={false}
                  autocomplete={true}
                  autoFocus={true}
                />
              ) : null}
            </>
          )}
        </>
      )}
      <div className="h-25" />
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
