import React, { FC, useContext } from 'react';
import { Project } from '@contractool/schema';
import { SidePanel } from './ProjectDetail';
import { http } from '../../utils/http';
import { Icon, Icons } from '../../components/Icon';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { AddFiles } from './AddFiles';
import { sign } from '../../utils/auth';
import { AppContext, GuardContext } from 'contexts';
import { Document as AttachmentDocument } from '@contractool/schema/Document';
import { Document } from '@contractool/schema';
import { useToasts } from 'hooks';
import { permissionRequest, userPermissionRequest } from '../../utils/wildcard';
import { Confirmation } from 'components/Confirmation';
import { translate } from 'utils/translations';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { PreviewIcon } from 'components/documents/PreviewIcon';
import { addVersion } from 'features/office/office';
import ReactTooltip from 'react-tooltip';

export const DocumentsPanel: FC<{
  heading: string;
  fileAddLabel?: string;
  modalUrl: string;
  type?: string | null;
  project: Project;
  onCompletedUpload: (file: { path: string; name: string }) => void;
  onSelectedFiles: (selectedFiles: Document[]) => void;
  onDeleted?: () => void;
  onClose: () => void;
  onUpdate?: () => void;
}> = ({
  heading,
  fileAddLabel,
  modalUrl,
  type = null,
  project,
  onCompletedUpload,
  onSelectedFiles,
  onDeleted,
  onClose,
  onUpdate,
}) => {
  const { config } = useContext(AppContext);
  const { success } = useToasts();

  //hook for popup gallery
  const { openLightbox } = useLightbox();
  //get Images URLs for popup Gallery
  let images: any[] = project.attachments;
  images = images
    .filter((value) => value.icon === 'file-image')
    .map((value) => {
      return { src: sign(value.preview_url), id: value.id };
    });

  if (!config.config) {
    return null;
  }

  return (
    <SidePanel
      heading={heading}
      right={
        <div className="flex">
          {config.config.office365 && (
            <a
              href={project.new_document_url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-700 mr-4"
            >
              <Icon name="word" size={5} />
              <span className="ml-2">{translate('Create online')}</span>
            </a>
          )}
          {permissionRequest(project, 'documents.edit') && fileAddLabel && (
            <Link to={modalUrl} className="flex items-center text-blue-700">
              <Icon name="add" size={5} />
              <span className="ml-2">{fileAddLabel}</span>
            </Link>
          )}
        </div>
      }
    >
      {/* Popup Gallery */}
      <SRLWrapper
        elements={images}
        options={{
          buttons: {
            showThumbnailsButton: false,
            showAutoplayButton: false,
          },
          thumbnails: { showThumbnails: false },
        }}
      />
      <AttachementsList
        type={type}
        project={project}
        onDeleted={() => onDeleted && onDeleted()}
        openLightbox={openLightbox}
        images={images}
        onUpdate={onUpdate}
      />

      {permissionRequest(project, 'documents.edit') && (
        <Switch>
          <Route exact path={modalUrl}>
            <AddFiles
              onCompletedUpload={onCompletedUpload}
              onSelectFiles={async (selectedFiles) => {
                if (selectedFiles.length === 0) return;

                await Promise.all(
                  selectedFiles.map(async (selectedFile) => {
                    await http.post<Document>(project.library_attachments_url, {
                      document_id: selectedFile.id,
                      type: type,
                    });

                    success(
                      `${translate(
                        'Document :file was successfully added to project',
                        {
                          file: `${
                            selectedFiles.find(
                              (doc) => doc.id === selectedFile.id,
                            )?.title
                          }`,
                        },
                      )}.`,
                    );
                  }),
                );

                onClose();
                onSelectedFiles(selectedFiles);
              }}
              onClose={onClose}
              hasPermission={permissionRequest(project, 'library.view')}
            />
          </Route>
        </Switch>
      )}
    </SidePanel>
  );
};

export const AttachementsList: FC<{
  project: Project;
  onDeleted: () => void;
  type: string | null;
  openLightbox: any;
  images: any;
  onUpdate?: () => void;
}> = ({ project, onDeleted, type, openLightbox, images, onUpdate }) => {
  const { success, error } = useToasts();
  const { config } = useContext(AppContext);
  const { user } = useContext(GuardContext);

  if (project.attachments_url.length === 0) return null;
  const permissions = project.can;

  return (
    <>
      {project.attachments
        .filter((value) => project.document_id !== value.id)
        .filter(
          (value) =>
            value.type === type || (value.type == 'msg' && type == null),
        )
        .map((value, index) => {
          let icon: Icons;

          switch (value.icon) {
            case 'file-alt':
            case 'file-archive':
            case 'file-audio':
            case 'file-excel':
            case 'file-image':
            case 'file-pdf':
            case 'file-powerpoint':
            case 'file-video':
            case 'file-word':
              icon = value.icon;
              break;
            default:
              icon = 'description';
          }
          const deleteAttachment = (document: AttachmentDocument) => {
            http
              .delete(document.url)
              .then(() => {
                onDeleted();
                success(
                  `${translate(
                    'Document :file was successfully removed from project',
                    { file: document.title },
                  )}.`,
                );
              })
              .catch(() => {
                error(
                  `${translate('Error with removing document from project')}.`,
                );
              });
          };

          return (
            <div className="border-b border-gray-100 py-7 flex" key={index}>
              <Link
                to={`/projects/${project.id}/documents/${value.id}`}
                className="flex"
              >
                <Icon name={icon} size={6} />
                <div className="leading-tighter pl-4 text-gray-700">
                  {value.title}
                </div>
              </Link>
              <div className="grow flex justify-end">
                {/* Url for open files */}
                {value.icon !== 'file-image' && (
                  <PreviewIcon
                    fileUrl={value.preview_url}
                    modalPreview={value.modal_preview}
                    type={value.type}
					documentId={value.id}
                  />
                )}
                {/* Button for popup gallery */}
                {value.icon === 'file-image' && (
                  <button
                    onClick={() =>
                      openLightbox(
                        images.findIndex((image: any) => {
                          return image.id === value.id;
                        }),
                      )
                    }
                  >
                    <Icon
                      name="remove_red_eye"
                      size={6}
                      className="ml-3 text-gray-500"
                    />
                  </button>
                )}
                {permissionRequest(
                  project,
                  'documents.view',
                ) && (
                  <a
                    href={sign(value.download_url)}
                    download
                    data-tip={'Download file'}
                  >
                    <Icon
                      name="get_app"
                      size={6}
                      className="ml-3 text-gray-500"
                    />
                  </a>
                )}
                {config.config.office365 &&
                  permissionRequest(
                    project,
                    'documents.edit',
                  ) && (
                    <span className="flex">
                        <a
                          href={sign(value.edit_url)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon
                            name="edit"
                            size={6}
                            className="ml-3 text-gray-500"
                          />
                        </a>
                        <span
                            className="cursor-pointer"
                            data-tip={'New version online'}
                            onClick={() => {
                                addVersion(value, onUpdate);
                            }}
                        >
                            <Icon
                                name="duplicate"
                                size={6}
                                className="text-gray-500"
                            />
                        </span>
                    </span>
                  )}
                {userPermissionRequest(user, 'documents.delete') && (
                  <Confirmation
                    onConfirm={() => {
                      deleteAttachment(value);
                    }}
                    trigger={({ onClick }) => (
                      <span
                        onClick={onClick}
                        className="cursor-pointer focus:outline-none"
                        data-tip={'Delete file'}
                      >
                        <Icon
                          name="delete"
                          size={6}
                          className="ml-3 text-gray-500"
                        />
                      </span>
                    )}
                    heading={value.title}
                    buttonText={translate('Yes, remove')}
                    color="red"
                  >
                    {translate(
                      'Are you sure you want to remove an attachment :title?',
                      { title: value.title },
                    )}
                  </Confirmation>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};
