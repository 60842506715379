import React from 'react';

import { FormRenderer } from 'components/Formio';

type Props = {
  onSubmit: (values: object) => void;
  value: object;
};

const Settings: React.FC<Props> = ({ onSubmit, value }) => {
  return (
    <FormRenderer
      submission={{ data: value }}
      form={{
        display: 'form',
        components: [
          {
            label: 'Settings',
            labelPosition: 'top',
            description: '',
            tooltip: '',
            disableAddingRemovingRows: false,
            conditionalAddButton: '',
            reorder: false,
            addAnother: '',
            addAnotherPosition: 'bottom',
            layoutFixed: false,
            enableRowGroups: false,
            initEmpty: false,
            customClass: '',
            tabindex: '',
            hidden: false,
            hideLabel: false,
            autofocus: false,
            disabled: false,
            tableView: false,
            modalEdit: false,
            defaultValue: [
              {
                select: '',
                value: '',
              },
            ],
            persistent: true,
            protected: false,
            dbIndex: false,
            encrypted: false,
            redrawOn: '',
            clearOnHide: true,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            allowCalculateOverride: false,
            validateOn: 'change',
            validate: {
              required: false,
              minLength: '',
              maxLength: '',
              customMessage: '',
              custom: '',
              customPrivate: false,
              json: '',
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            errorLabel: '',
            errors: '',
            key: 'settings',
            tags: [],
            properties: {},
            conditional: {
              show: null,
              when: null,
              eq: '',
              json: '',
            },
            customConditional: '',
            logic: [],
            attributes: {},
            overlay: {
              style: '',
              page: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            type: 'datagrid',
            input: true,
            components: [
              {
                label: 'Setting',
                widget: 'choicesjs',
                tableView: true,
                data: {
                  values: [
                    {
                      label: 'Title editable',
                      value: 'edit_title',
                    },
                    {
                      label: 'Title legend',
                      value: 'title_legend',
                    },
                    {
                      label: 'Visible in dropdown',
                      value: 'visible_in_dropdown',
                    },
                    {
                      label: 'Disabled',
                      value: 'disabled',
                    },
                    {
                      label: 'Attachment types',
                      value: 'attachment_types',
                    },
                    {
                      label: 'Adobe Sign Key',
                      value: 'adobe_sign_key',
                    },
                    {
                      label: 'Adobe Sign API URL',
                      value: 'adobe_sign_api_url',
                    },
                    {
                      label: 'Adobe Sign Client ID',
                      value: 'adobe_sign_client_id',
                    },
                    {
                      label: 'Use approvals',
                      value: 'use_approvals',
                    },
                    {
                      label: 'Use parent projects',
                      value: 'use_parent_projects',
                    },
                  ],
                  json: '',
                  url: '',
                  resource: '',
                  custom: '',
                },
                allowCalculateOverride: true,
                key: 'select',
                type: 'select',
                input: true,
                id: 'ealk06pt',
                placeholder: '',
                prefix: '',
                customClass: '',
                suffix: '',
                multiple: false,
                defaultValue: null,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                refreshOn: '',
                redrawOn: '',
                modalEdit: false,
                dataGridLabel: false,
                labelPosition: 'top',
                description: '',
                errorLabel: '',
                tooltip: '',
                hideLabel: false,
                tabindex: '',
                disabled: false,
                autofocus: false,
                dbIndex: false,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                attributes: {},
                validateOn: 'change',
                validate: {
                  required: false,
                  custom: '',
                  customPrivate: false,
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                  onlyAvailableItems: false,
                },
                conditional: {
                  show: null,
                  when: null,
                  eq: '',
                },
                overlay: {
                  style: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                encrypted: false,
                showCharCount: false,
                showWordCount: false,
                properties: {},
                allowMultipleMasks: false,
                addons: [],
                idPath: 'id',
                clearOnRefresh: false,
                limit: 100,
                dataSrc: 'values',
                valueProperty: '',
                lazyLoad: true,
                filter: '',
                searchEnabled: true,
                searchDebounce: 0.3,
                searchField: '',
                minSearch: 0,
                readOnlyValue: false,
                authenticate: false,
                ignoreCache: false,
                template: '<span>{{ item.label }}</span>',
                selectFields: '',
                selectThreshold: 0.3,
                uniqueOptions: false,
                fuseOptions: {
                  include: 'score',
                  threshold: 0.3,
                },
                indexeddb: {
                  filter: {},
                },
                customOptions: {},
                useExactSearch: false,
              },
              {
                label: 'Value',
                tableView: true,
                key: 'value',
                type: 'textfield',
                input: true,
                id: 'ek7vi2c',
                placeholder: '',
                prefix: '',
                customClass: '',
                suffix: '',
                multiple: false,
                defaultValue: null,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                refreshOn: '',
                redrawOn: '',
                modalEdit: false,
                dataGridLabel: false,
                labelPosition: 'top',
                description: '',
                errorLabel: '',
                tooltip: '',
                hideLabel: false,
                tabindex: '',
                disabled: false,
                autofocus: false,
                dbIndex: false,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                widget: {
                  type: 'input',
                },
                attributes: {},
                validateOn: 'change',
                validate: {
                  required: false,
                  custom: '',
                  customPrivate: false,
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                  minLength: '',
                  maxLength: '',
                  pattern: '',
                },
                conditional: {
                  show: null,
                  when: null,
                  eq: '',
                },
                overlay: {
                  style: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                allowCalculateOverride: false,
                encrypted: false,
                showCharCount: false,
                showWordCount: false,
                properties: {},
                allowMultipleMasks: false,
                addons: [],
                mask: false,
                inputType: 'text',
                inputFormat: 'plain',
                inputMask: '',
                displayMask: '',
                spellcheck: true,
                truncateMultipleSpaces: false,
              },
            ],
            placeholder: '',
            prefix: '',
            suffix: '',
            multiple: false,
            unique: false,
            refreshOn: '',
            dataGridLabel: false,
            widget: null,
            showCharCount: false,
            showWordCount: false,
            allowMultipleMasks: false,
            addons: [],
            tree: true,
            lazyLoad: false,
            id: 'evgviy',
          },
          {
            type: 'button',
            label: 'Submit',
            key: 'submit',
            disableOnInvalid: true,
            input: true,
            tableView: false,
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            modalEdit: false,
            dataGridLabel: true,
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            addons: [],
            size: 'md',
            leftIcon: '',
            rightIcon: '',
            block: false,
            action: 'submit',
            theme: 'primary',
            id: 'ew60mb6',
          },
        ],
      }}
      onSubmit={({ data }) => onSubmit(data)}
    />
  );
};

export default Settings;
