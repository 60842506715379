import React, { FC, useContext } from 'react';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { http } from 'utils/http';
import {
  Delegation,
  FieldTemplate,
  User,
  UserGroup,
} from '@contractool/schema';
import { Tab, RouteTabs } from 'components/Tabs';
import { useToasts } from 'hooks';
import { Modal } from 'components/Modal';
import { useParams, useHistory } from 'react-router-dom';
import { timezones } from 'utils/timezones';
import { Field } from 'components/Field';
import { Switch } from 'components/Switch';
import { useRequest } from 'utils/hooks/useRequest';
import { translate } from 'utils/translations';
import { Menu, MenuItem } from 'components/Menu';
import { UserStateLabel } from './UserStateLabel';
import { Avatar, AvatarUpload } from 'components/Avatar';
import { Confirmation } from 'components/Confirmation';
import { AppContext, AuthContext } from 'contexts';
import CustomField from 'components/CustomField';
import { TeamParams } from './TeamPage';
import { useQueryParams } from 'hooks/useParams';
import { toQueryString } from 'utils/url';
import { useBigLoader } from 'components/Loader';
import { RequestAccess } from '@contractool/schema/RequestAccess';
import {
  getFilterDelegations,
  getStatusMessage,
  renderDelegationInfo,
  renderNotificationBadge,
} from 'utils/delegation';
import moment from 'moment';

export function TeamEdit({ onUpdate }: { onUpdate: () => void }) {
  const history = useHistory();
  const { success, error } = useToasts();
  const bigLoader = useBigLoader();
  const { id } = useParams<{ id?: string; params?: string }>();
  const [user, { refresh }] = useRequest<User | undefined>(
    `/api/users/${id}`,
    undefined,
  );

  const [params] = useQueryParams<TeamParams>({
    phrase: '',
    licence: '',
    page: 1,
    per_page: 30,
    groups_in: [],
    state: '',
  });

  const close = () => {
    history.replace({
      pathname: '/team',
      search: toQueryString(params),
    });
  };

  const getPendingRequests = (requests: RequestAccess[]) => {
    return requests.filter((r) => r.status === 'pending');
  };

  const delegations = user?.delegations?.filter(
    (item: Delegation) => item != null,
  );

  const delegatorDelegations = delegations?.filter(
    (item: Delegation) => item.delegator.id == user.id,
  );

  const formInitialValues = () => {
    const delegation = getFilterDelegations(delegations, user.id);
    const isPendingOrApproved =
      delegation?.state == 'pending' || delegation?.state == 'approved';

    return isPendingOrApproved
      ? {
          starts_at: delegation.starts_at,
          ends_at: delegation.ends_at,
          backup_id: delegation.backup?.id,
        }
      : {
          starts_at: null,
          ends_at: null,
          backup_id: null,
        };
  };

  const pendingDelegations = delegations?.filter(
    (item: Delegation) => item.backup.id == user.id && item.state == 'pending',
  );

  const approvedDelegations = delegations?.filter(
    (item: Delegation) =>
      item.backup.id == user.id &&
      item.state == 'approved' &&
      item.is_active == false,
  );

  const activeDelegations = delegations?.filter(
    (item: Delegation) =>
      item.backup.id == user.id &&
      item.state == 'approved' &&
      item.is_active == true,
  );

  const hasDelegationRequests =
    pendingDelegations?.length > 0 ||
    approvedDelegations?.length > 0 ||
    activeDelegations?.length > 0;

  if (typeof user === 'undefined') {
    return null;
  }

  return (
    <Modal
      heading={translate('User detail')}
      compact={true}
      onClose={close}
      contentClassName="h-149"
      corner={
        <StateMenu
          user={user}
          onRefresh={() => {
            refresh();
            onUpdate();
          }}
          onClose={close}
        />
      }
    >
      <div className="flex items-center mb-11">
        <div>
          <AvatarUpload
            user={user}
            onUpload={() => {
              refresh();
              success(
                `${translate(":name's avatar was successfully changed", {
                  name: user.name,
                })}`,
              );
            }}
            className="w-32 h-32"
          />
        </div>

        <div className="ml-11">
          <div className="flex items-center">
            <h2 className="text-xl inline">{user.name}</h2>
            <UserStateLabel user={user} className="mx-4" />
          </div>
          <p className="text-gray-600">{user.licence}</p>
        </div>
      </div>

      <RouteTabs match={`/team/${user.id}/edit/:tab`}>
        <Tab name="general" heading={translate('General')} className="pt-8">
          <GeneralTab
            user={user}
            onSuccess={() => {
              success(
                `${translate(":name's details were successfully updated", {
                  name: user.name,
                })}`,
              );
              onUpdate();
              close();
            }}
            onClose={close}
          />
        </Tab>
        <Tab name="password" heading={translate('Password')} className="pt-8">
          <PasswordTab
            user={user}
            onSuccess={() => {
              success(
                `${translate(":name's password was successfully updated", {
                  name: user.name,
                })}`,
              );
              onUpdate();
              close();
            }}
            onClose={() => close()}
          />
        </Tab>
        <Tab name="groups" heading={translate('Groups')} className="pt-8">
          <GroupsTab
            user={user}
            onSuccess={() => {
              success(
                `${translate(":name's groups were successfully updated", {
                  name: user.name,
                })}`,
              );
              onUpdate();
              close();
            }}
            onClose={close}
          />
        </Tab>
        {/*<Tab*/}
        {/*  name="access"*/}
        {/*  heading={*/}
        {/*    <>*/}
        {/*      {translate('Access requests')}*/}
        {/*      {getPendingRequests(user?.request_access)?.length > 0 && (*/}
        {/*        <span className="inline-flex items-center justify-center w-6 h-6 ml-2 text-xs font-semibold text-white bg-blue-600 rounded-full">*/}
        {/*          {getPendingRequests(user?.request_access)?.length}*/}
        {/*        </span>*/}
        {/*      )}*/}
        {/*    </>*/}
        {/*  }*/}
        {/*  className="pt-8"*/}
        {/*>*/}
        {/*  <AccessTab*/}
        {/*    user={user}*/}
        {/*    onSuccess={() => {*/}
        {/*      refresh();*/}
        {/*    }}*/}
        {/*    onClose={close}*/}
        {/*  />*/}
        {/*</Tab>*/}
        <Tab
          name="away_status"
          heading={`${translate('Delegate Authority')}`}
          className="pt-8"
        >
          <Form
            name="awayStatus"
            initialValues={formInitialValues()}
            onSubmit={(values) => {
              bigLoader.start(translate('Sending invitation...'));
              return http.post('/api/delegation', {
                starts_at: values.starts_at,
                ends_at: values.ends_at,
                backup_id: values.backup_id,
              });
            }}
            onSuccess={async () => {
              bigLoader.stop();
              success(
                `${translate('Your delegation was successfully created')}.`,
              );
              refresh();
            }}
            onError={(data) => {
              bigLoader.stop();
              if (data.message) {
                error(`${translate(data.message)}`);
              }
            }}
          >
            <Form.Context>
              {({ values }) => (
                <>
                  {getStatusMessage(delegatorDelegations)}

                  {!getStatusMessage(delegatorDelegations) ? (
                    <p>No Delegate Authority</p>
                  ) : (
                    <>
                      <Form.DatePicker
                        name="starts_at"
                        label={translate('Start date')}
                        placeholder={translate('Select a date')}
                        formatInput="dd MMM yyyy"
                        required
                        since={moment().format('YYYY-MM-DD')}
                        readOnly={true}
                      />
                      <Form.DatePicker
                        name="ends_at"
                        label={translate('End date')}
                        placeholder={translate('Select a date')}
                        formatInput="dd MMM yyyy"
                        since={
                          values.starts_at
                            ? moment(values.starts_at).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD')
                        }
                        required
                        readOnly={true}
                      />

                      <Form.UserDropdown2
                        endpoint="api/users/autocomplete"
                        params={{ deleted: false }}
                        name="backup_id"
                        label={translate('Backup user')}
                        placeholder={translate('Select a user')}
                        autocomplete
                        required
                        readOnly={true}
                        isMulti={false}
                        options={[]}
                      />
                    </>
                  )}

                  <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                      {translate('Cancel')}
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Form.Context>
          </Form>
        </Tab>

        <Tab
          name="delegation"
          heading={
            <>
              {translate('Delegation requests')}{' '}
              {pendingDelegations.length > 0 &&
                renderNotificationBadge(pendingDelegations.length)}
            </>
          }
          className="pt-8"
        >
          <div>
            {pendingDelegations.length > 0 &&
              renderDelegationInfo(pendingDelegations[0])}
          </div>

          {approvedDelegations.map((delegation: Delegation) => (
            <p key={delegation.id}>
              <strong>{user.name}</strong> confirmed to be a backup for{' '}
              <strong>{delegation.delegator.name}</strong> from{' '}
              <strong>{delegation.starts_at}</strong> until{' '}
              <strong>{delegation.ends_at}</strong>.
            </p>
          ))}

          {activeDelegations.map((delegation: Delegation) => (
            <p key={delegation.id}>
              <strong>{user.name}</strong> confirmed to be a backup for{' '}
              <strong>{delegation.delegator.name}</strong> from{' '}
              <strong>{delegation.starts_at}</strong> until{' '}
              <strong>{delegation.ends_at}</strong>. In case of any change
              needed, please contact your admin
            </p>
          ))}

          {hasDelegationRequests ? <></> : <>No delegation request</>}

          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={close}>
              {translate('Cancel')}
            </Button>
          </Modal.Footer>
        </Tab>
      </RouteTabs>
    </Modal>
  );
}

const GeneralTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  const { config } = useContext(AppContext);

  return (
    <Form
      initialValues={user}
      onSubmit={(values) => http.put<User>(user.url, values)}
      onSuccess={onSuccess}
    >
      <Form.TextInput
        name="name"
        label={translate('Name')}
        className="mb-6"
        autoFocus={true}
      />
      <Form.TextInput
        name="email"
        label={translate('Email')}
        className="mb-6"
      />
      <Form.Dropdown
        name="timezone"
        label={translate('Timezone')}
        options={timezones}
        autocomplete
      />
      {config.user_fields.map((field: FieldTemplate, index: number) => {
        return (
          <CustomField
            key={field.name + index}
            field={field}
            option="fields."
            className="mb-6"
          />
        );
      })}

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const PasswordTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  return (
    <Form
      initialValues={{ password: '', password_confirmation: '' }}
      onSubmit={(values) => http.put(user.password_url, values)}
      onSuccess={onSuccess}
    >
      <Form.TextInput
        name="password"
        type="password"
        label={translate('New password')}
        className="mb-6"
        autoFocus={true}
      />
      <Form.TextInput
        name="password_confirmation"
        type="password"
        label={translate('Confirm new password')}
      />

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const GroupsTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  const { config } = useContext(AppContext);
  const user_groups: UserGroup[] = config.user_groups;

  //TODO roles vypocitavat a zobrazit licenciu na zaklade zaskrtnutych grup
  return (
    <Form
      initialValues={{ groups: user.groups }}
      onSubmit={(values) => http.put(user.roles_url, values)}
      onSuccess={onSuccess}
    >
      <Field name="groups" label={translate('Groups')}>
        <Form.Context>
          {({ values, handleChange }) => {
            return (
              <Switch.Multiple
                name="groups"
                value={values.groups}
                items={user_groups}
                toKey={(group) => group.key}
                onChange={(values) => handleChange('groups', values)}
                className="flex flex-wrap py-4"
              >
                {(group, Switch) => (
                  <div key={group.key} className="w-1/2 p-2">
                    <Switch>{group.label}</Switch>
                  </div>
                )}
              </Switch.Multiple>
            );
          }}
        </Form.Context>
      </Field>

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const AccessTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  const getHistoryRequests = (requests: RequestAccess[]) => {
    return requests.filter((r) => r.status === 'done');
  };
  const getPendingRequests = (requests: RequestAccess[]) => {
    return requests.filter((r) => r.status === 'pending');
  };
  return (
    <div>
      <h4 className="text-md text-gray-600 mb-2">
        {translate('Active requests')}
      </h4>
      {getPendingRequests(user?.request_access)?.map((request) => (
        <ActiveRequest
          key={request.id}
          request={request}
          user={user}
          onSuccess={onSuccess}
        />
      ))}

      {getPendingRequests(user?.request_access)?.length == 0 && (
        <div className="pb-6">{translate('No active requests')}</div>
      )}

      <h4 className="text-md text-gray-600 mb-2">{translate('History')}</h4>
      {getHistoryRequests(user?.request_access)?.map((request) => (
        <HistoryRequest key={request.id} request={request} />
      ))}

      {getHistoryRequests(user?.request_access)?.length == 0 && (
        <div className="pb-6">{translate('No history requests')}</div>
      )}

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>
      </Modal.Footer>
    </div>
  );
};

const StateMenu: FC<{
  user: User;
  onRefresh: () => void;
  onClose: () => void;
}> = ({ user, onRefresh, onClose }) => {
  const { success } = useToasts();
  const { login, user: sessionUser } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Menu handle="more_vert">
      {sessionUser?.groups.some((role) => role.key === 'admin') && (
        <Confirmation
          onConfirm={() => {
            http.post(`/api/auth/loginUser/${user.id}`).then((user: any) => {
              onRefresh();
              success(
                `${translate('You logged in as :name', {
                  name: user.name,
                })}.`,
              );
              login(user);
              history.push('/');
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="person"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {`${translate('Login as')} ${user.name}`}
            </MenuItem>
          )}
          heading={translate('Login as user')}
          buttonText={translate('Yes, switch')}
          color="yellow"
        >
          {translate('Are you sure you want to switch to user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
      {user.state === 'active' && (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'deactivated' }).then(() => {
              onRefresh();
              success(
                `${translate('User :name was successfully deactivated', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="lock"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Deactivate')}
            </MenuItem>
          )}
          heading={translate('Deactivate user')}
          buttonText={translate('Yes, deactivate')}
          color="yellow"
        >
          {translate('Are you sure you want to deactivate user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
      {user.state === 'deactivated' && (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'active' }).then(() => {
              onRefresh();
              success(
                `${translate('User :name was successfully activated', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="lock_open"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Activate')}
            </MenuItem>
          )}
          heading={translate('Activate user')}
          buttonText={translate('Yes, activate')}
          color="yellow"
        >
          {translate('Are you sure you want to activate user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
      {user.state === 'deleted' ? (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'active' }).then(() => {
              onClose();
              onRefresh();
              success(
                `${translate('User :name was successfully undeleted', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="settings_restore"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Undelete')}
            </MenuItem>
          )}
          heading={translate('Undelete user')}
          buttonText={translate('Yes, undelete')}
          color="red"
        >
          {translate('Are you sure you want to undelete user :name?', {
            name: user.name,
          })}
        </Confirmation>
      ) : (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'deleted' }).then(() => {
              onClose();
              onRefresh();
              success(
                `${translate('User :name was successfully deleted', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="remove_circle"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Delete')}
            </MenuItem>
          )}
          heading={translate('Delete user')}
          buttonText={translate('Yes, delete')}
          color="red"
        >
          {translate('Are you sure you want to delete user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
    </Menu>
  );
};

export const ActiveRequest: FC<{
  request: RequestAccess;
  user: User;
  onSuccess: () => void;
}> = ({ request, onSuccess }) => {
  const { success } = useToasts();

  const bigLoader = useBigLoader();

  return (
    <div key={request.id} className="pb-4 border-b mb-6">
      <UserRequest
        projectId={request.project_id}
        title={request?.project_title}
        date={request?.created_at}
        message={request?.request_message}
      />
      <div className="px-4">
        <Form
          initialValues={{ admin_response: '' }}
          onSubmit={(values) => {
            bigLoader.start(translate('Sending request...'));
            return http.post('/api/access/approve/' + request.id, values);
          }}
          onError={() => {
            bigLoader.stop();
          }}
          onSuccess={() => {
            bigLoader.stop();
            success(`${translate('Request was successfully approved!')}`);
            onSuccess();
          }}
        >
          <Form.TextInput
            name="admin_response"
            placeholder={translate('Message to user...')}
            required
            autoFocus={true}
            className="py-2"
          />

          <Form.Submit>{translate('Submit')}</Form.Submit>
        </Form>
      </div>
    </div>
  );
};

export const HistoryRequest: FC<{ request: RequestAccess }> = ({ request }) => {
  return (
    <div className="border-b mb-6">
      <UserRequest
        projectId={request.project_id}
        title={request.project_title}
        message={request.request_message}
        date={request.created_at}
      />

      <AdminResponse
        date={request.resolved_at}
        user={request.admin}
        message={request.admin_response}
      />
    </div>
  );
};

export const UserRequest: FC<{
  projectId: number;
  title: string;
  message: string;
  date: string;
}> = ({ projectId, title, message, date }) => {
  return (
    <div className="bg-gray-100 p-6 rounded-lg mt-2">
      <div className="grid grid-cols-8 items-center text-sm">
        <div className="flex items-center col-span-6">
          <a
            className=" text-blue-600 font-bold block"
            href={`/#/projects/${projectId}`}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        </div>
        <div className="text-gray-600 col-span-2 text-right">{date}</div>
      </div>
      <div>{message}</div>
    </div>
  );
};

export const AdminResponse: FC<{
  user: User;
  message: string;
  date: string;
}> = ({ user, message, date }) => {
  return (
    <div className="bg-white rounded-lg my-4 px-4">
      <div className="flex justify-between items-center text-sm">
        <div className="flex items-center">
          <Avatar user={user} className="w-10 h-10 mr-4" />
          <div>{user?.name}</div>
        </div>
        <div className="text-gray-600">{date}</div>
      </div>
      <div className="mx-14 mt-3">
        <div className="whitespace-pre-wrap">
          <div>{message}</div>
        </div>
      </div>
    </div>
  );
};

export default TeamEdit;
