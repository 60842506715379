import { get, isObject, omitBy, isNull, isArray } from 'lodash';

import { FilterTemplate } from '@contractool/schema';

import {
  Tab as TabType,
  RulesForm,
  AskUserResult,
  AutomaticResult,
  UserRoleType,
  Tab,
  Condition,
} from 'types/output/rules';
import {
  ResultApiType,
  RuleApiType,
  RuleResponseApiType,
} from 'types/api/rules';
import {
  askUserOptions,
  automaticOptions,
  askUser as initAskUser,
  automatic as initAutomatic,
  userRoleOptions,
} from '@constants/rule';
import { to_snake_case } from 'utils';

const createOption = (field: any) => ({
  label: field?.label,
  value: field?.name,
});

const sanitizePayload = (values: RulesForm): RuleApiType => {
  const { name, workflow, conditions, results } = to_snake_case(values);
  const tab = results.tab;

  let payload: any = [];

  if (results.tab === TabType.ASK_USER) {
    const then = get(results, tab) as AskUserResult[];
    then.map((result) => {
      const key = result.action;
      const value = get(result, result.action) || true;

      payload.push({ [key]: value });
    });
  }

  if (results.tab === TabType.AUTOMATIC) {
    const then = get(results, tab) as AutomaticResult[];
    then.map((result) => {
      const key = result.action;
      const value = get(result, result.action);

      if (Boolean(isObject(value) && !Array.isArray(value))) {
        payload.push({
          [key]: omitBy(
            {
              ...value,
              ...(value?.selection?.value === UserRoleType.USER && {
                role: null,
              }),
              ...(value?.selection?.value === UserRoleType.ROLE && {
                user: null,
              }),
              ...(isObject(value) && { selection: null }),
            },
            isNull,
          ),
        });
      } else {
        payload.push({ [key]: value });
      }
    });
  }

  return {
    name,
    workflow,
    conditions,
    results: payload,
  };
};

const createRule = (rule: RuleResponseApiType): RulesForm => {
  const { results } = rule;

  const actionFilter = (result: ResultApiType, options: any) => {
    const key = Object.keys(result).toString();
    return options.flatMap((option: any) => option.value).includes(key);
  };
  const isAskUserAction = results.some((result) =>
    actionFilter(result, askUserOptions),
  );

  const askUser = results
    .filter((result) => actionFilter(result, askUserOptions))
    .map((result) => {
      const action = Object.keys(result).toString();
      return {
        action: action || '',
        fillSomeMetadata: result?.fill_some_metadata || [],
        chooseUserToRole: result?.choose_user_to_role || [],
      };
    });

  const automatic = results
    .filter((result) => actionFilter(result, automaticOptions))
    .map((result) => {
      const action = Object.keys(result).toString();

      const createSelection = (result: any): any =>
        userRoleOptions.find((option) => {
          if (result?.hasOwnProperty(UserRoleType.ROLE)) {
            return option.value === UserRoleType.ROLE;
          }
          return option.value === UserRoleType.USER;
        });

      return {
        action: action || '',
        createApproval: {
          selection: createSelection(result?.create_approval) || null,
          user: result?.create_approval?.user || [],
          role: result?.create_approval?.role || null,
          title: result?.create_approval?.title || '',
          textareaSingle: result?.create_approval?.textarea_single || null,
        },
        createTask: {
          selection: createSelection(result?.create_task) || null,
          user: result?.create_task?.user || [],
          role: result?.create_task?.role || null,
          title: result?.create_task?.title || '',
          days: result?.create_task?.days || '',
          mandatory: result?.create_task?.mandatory || false,
          textareaSingle: result?.create_task?.textarea_single || null,
        },
        sendNotification: {
          selection: createSelection(result?.send_notification) || null,
          user: result?.send_notification?.user || [],
          role: result?.send_notification?.role || null,
          subject: result?.send_notification?.subject || null,
          textareaInApp: result?.send_notification?.textarea_in_app || null,
          textareaEmail: result?.send_notification?.textarea_email || null,
        },
        attachFile: result?.attach_file,
        addUserToRole: {
          user: result?.add_user_to_role?.user || [],
          role: result?.add_user_to_role?.role || [],
        },
        assignProjectToRole: result?.assign_project_to_role || null,
        addProjectLogMessage: result?.add_project_log_message || null,
        displayPopup: result?.display_popup || null,
        fillContractFromTemplate: result?.fill_contract_from_template || null,
        sendContractToPoc: result?.send_contract_to_poc || null,
        goToState: result?.go_to_state || null,
        signDocument: result?.sign_document || '',
      };
    });

  return {
    id: rule?.id,
    name: rule?.name,
    workflow: rule?.workflow,
    conditions: rule?.conditions,
    results: {
      tab: isAskUserAction ? Tab.ASK_USER : Tab.AUTOMATIC,
      askUser: askUser.length > 0 ? askUser : [initAskUser],
      automatic: automatic.length > 0 ? automatic : [initAutomatic],
    },
  };
};

const createConditionLabel = (
  condition: Condition,
  templates: FilterTemplate[],
): string => {
  let selections = [];

  const templateValues = templates.flatMap((template) => template.values);

  if (isArray(condition.value)) {
    condition?.value.filter((value) => {
      if (!value) {
        return;
      }

      const selection = templateValues.find(
        (template) => template?.value === value,
      );

      if (!selection) {
        return;
      }

      selections.push(selection?.label);
    });
  } else {
    selections.push(condition?.value);
  }

  return String(selections).replaceAll(',', ', ');
};

export { createOption, sanitizePayload, createRule, createConditionLabel };
