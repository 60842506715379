import React, { FC, useContext, useState } from "react";

import { AppContext } from "contexts";
import { Form } from "components/Form";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { FormTabs, Tab } from "components/Tabs";
import ProjectTitle from "components/form/ProjectTitle/Mutable";

import { useToasts } from "hooks";
import { getWorkflow } from "hooks/workflows";

import { Project } from "@contractool/schema";
import { FormTab } from "@contractool/schema/FormTab";

import { EventEmitter } from "utils/eventEmitter";
import { http } from "utils/http";
import { translate } from "utils/translations";

import { DefaultForm } from "../newproject/DefaultForm";
import ProjectFormProvider from "views/project/ProjectFormProvider";
import { FormRenderer } from "components/Formio";
import transformObject from "utils/transformOptionToValue";

const FormContainer: FC<{ project: Project; tab?: string }> = ({
                                                                   project,
                                                                   tab
                                                               }) => {
    return (
        <ProjectFormProvider>
            <div className="edit-project">
                <DefaultForm project={project} tab={tab} />
            </div>
        </ProjectFormProvider>
    );
};

export const ProjectDetailsEdit = ({
                                       project,
                                       onUpdate,
                                       onClose
                                   }: {
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}) => {
    const { success } = useToasts();
    const { config } = useContext(AppContext);
    const [nextTab, goNextTab] = useState<string>();

    const workflow: string = project?.workflow || "default";
    const workflowObject = getWorkflow(workflow);
    const [initValues, setInitValues] = useState({ ...project });
    const formTabs = workflowObject.form_tabs;
    const settings = workflowObject.settings;

    const projectChange = (project: Project) => {
        setInitValues({ ...project });
    };
    EventEmitter.subscribe("project-changed", projectChange);

    const handleSelectTab = (name: string) => {
        const titleElement = document.getElementById("modal-title");
        if (titleElement) {
            titleElement.scrollIntoView({ behavior: "smooth" });
        }
        goNextTab(name);
    };

  const handleSuccess = () => {
    onClose();
    onUpdate();
    success(
      translate('Project Details of :title were updated successfully', {
        title: project.title,
      }),
    ); // TODO: remove this (already in backend)
  };

    return (
        <Modal
            heading={translate("Edit Project Details")}
            onClose={onClose}
            size="w-4/5"
        ><div className="edit-project">
            {workflowObject.fields_config ? (
                <FormRenderer
                    submission={{ data: project.fields }}
                    form={workflowObject.fields_config}
                    onSubmit={({ data }) => {
                        http
                            .put<Project>('/api/projects/' + project.id + '/formio', data)
                            .then(handleSuccess);
                    }}
                />
            ) : (
            <Form
                initialValues={initValues}
                onSubmit={(values) => {
                    const payload = JSON.parse(JSON.stringify(values));

                    delete payload.logs_full;
                    delete payload.parent;
                    delete payload.children;
                    delete payload.child;
                    delete payload.team;
                    payload.editing = true;
                    payload.tab = nextTab;

                    return http.put<Project>("/api/projects/" + project.id, transformObject(payload));
                }}
                name="edit-project"
                onSuccess={handleSuccess}
                loader={"big"}
            >
                {formTabs.length > 0 ? (
                    <>
                        <ProjectTitle
                            className="mb-8 mt-8"
                            legend={settings.title_legend}
                        />
                        <FormTabs selected={nextTab} onSelect={handleSelectTab}>
                            {formTabs.map((tab: FormTab, index: number, array: FormTab[]) => {
                                let nextTabIndex = index;
                                nextTabIndex++;

                                const showNextButton = nextTabIndex !== array.length;

                                return (
                                    project && (
                                        <Tab
                                            key={tab?.id}
                                            name={tab?.title}
                                            heading={tab?.title}
                                            className="w-full h-auto overflow-auto mb-20"
                                        >
                                            {config.integration && (
                                                <FormContainer project={project} tab={tab?.id} />
                                            )}
                                            <Modal.Footer className="flex justify-between">
                                                <Button color="white" onClick={onClose}>
                                                    {translate("Cancel")}
                                                </Button>

                                                {showNextButton && (
                                                    <Button
                                                        onClick={() =>
                                                            handleSelectTab(array[nextTabIndex]?.title)
                                                        }
                                                    >
                                                        {translate("Next Step")}
                                                    </Button>
                                                )}

                                                <Form.Submit>{translate("Save Changes")}</Form.Submit>
                                            </Modal.Footer>
                                        </Tab>
                                    )
                                );
                            })}
                        </FormTabs>
                    </>
                ) : (
                    <>
                        <ProjectTitle
                            className="mb-8 mt-8"
                            legend={settings.title_legend}
                        />
                        {config.integration && <FormContainer project={project} />}
                        <Modal.Footer className="flex justify-between">
                            <Button color="white" onClick={onClose}>
                                {translate("Cancel")}
                            </Button>

                            <Form.Submit>{translate("Save Changes")}</Form.Submit>
                        </Modal.Footer>
                    </>
                )}
            </Form>
            )}
        </div>
        </Modal>
    );
};
