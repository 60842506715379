const transformObject = (obj: { [key: string]: any }): { [key: string]: any } => {
	const transformedObj: { [key: string]: any } = {};
	for (const key in obj) {
	  const value = obj[key];
	  if (typeof value === 'object' && value !== null && 'value' in value && 'label' in value) {
		transformedObj[key] = value.value;
	  } else {
		transformedObj[key] = value;
	  }
	}
	return transformedObj;
  };

  export default transformObject;