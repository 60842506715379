import { useHelpers, useRemirrorContext } from '@remirror/react';

import { translate } from 'utils/translations';

type Props = {
  onSubmit: (json: any) => void;
};

const SendButton: React.FC<Props> = ({ onSubmit }) => {
  const { getJSON } = useHelpers();
  const { clearContent } = useRemirrorContext();

  return (
    <div className="p-4">
      <button
        className="focus:outline-none text-blue-700 hover:text-blue-400"
        onClick={() => {
          onSubmit(getJSON());
          clearContent();
        }}
      >
        {translate('Send')}
      </button>
    </div>
  );
};

export default SendButton;
