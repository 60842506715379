import React, { useCallback, useEffect, useState } from 'react';
import * as qs from 'qs';
import { debounce, isEmpty, set } from 'lodash';
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { Option } from 'components/Dropdown';
import { Icon } from 'components/Icon';

import { FormContext } from './Form';

type Props = {
  onChange: (result: Option<string>[]) => void;
  onBlur?: (e: any) => void;
  value: PropsValue<string | Option<string>> | undefined;
  options: OptionsOrGroups<string | Option<string>, GroupBase<any>> | undefined;
  isMulti?: boolean;
  endpoint?: string;
  params?: object | null;
  apiParams?: any | null;
  placeholder?: string;
  readOnly?: boolean;
  isClearable?: boolean;
  hasError?: boolean;
  name?: string;
  isSearchable?: boolean;
  menuPortalTarget?: boolean;
  className?: string;
  defaultValue?: string;
};

const MultiselectDropdown2: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  options,
  isMulti = true,
  endpoint,
  params,
  apiParams,
  placeholder,
  readOnly,
  isClearable = true,
  hasError,
  name,
  isSearchable,
  menuPortalTarget,
  className,
  defaultValue,
}) => {
  const styles = {
    control: (base: any, state: any) => ({
      ...base,
      border: 'none',
      padding: '9px 0',
      borderRadius: '0',
      borderBottom: state.isFocused
        ? '1px solid #2684FF'
        : hasError
        ? '1px solid #f03e3e'
        : '1px solid #dee2e6',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
      ':hover': {
        ...base[':hover'],
        borderBottom: state.isFocused
          ? '1px solid #2684FF'
          : hasError
          ? '1px solid #f03e3e'
          : '1px solid #dee2e6',
      },
    }),
    menu: (base: any) => ({
      ...base,
      padding: '6px',
      margin: '0',
      borderRadius: '5px',
      boxShadow:
        '0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '0',
    }),
    option: (base: any, state: any) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#f1f3f5' : 'white',
      borderRadius: '3px',
      color: state.isSelected ? '#2684FF' : 'inherit',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      '> div': {
        ...base['> div'],
        padding: '0 8px',
        color: 'inherit',
        ':hover': {
          ...base['> div:hover'],
          color: 'inherit',
        },
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0',
    }),
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => (
    <components.DropdownIndicator {...props}>
      <Icon
        name="triangle"
        className={`text-gray-600 ${props.isFocused ? '' : 'rotate-180'}`}
        size={2}
      />
    </components.DropdownIndicator>
  );

  const ClearIndicator = (props: ClearIndicatorProps<any, true>) => (
    <components.ClearIndicator {...props}>
      <Icon name="close" size={6} />
    </components.ClearIndicator>
  );

  if (endpoint) {
    // TODO: From backend need to return {value: number, label: string}

    let newValue = value;
    // check if value is object
    if (Object.keys(value).length == 0 && typeof value === 'object') {
      // @ts-ignore
      if (value && !value.label && !value.value) {
        // @ts-ignore
        newValue = { value: value.id, label: value.name };
        // @ts-ignore
      } else if (value && value.label && !value.value) {
        // @ts-ignore
        newValue = { value: value.id, label: value.label };
      }
    }
    const formContext = React.useContext(FormContext);
    const [state, setState] = useState({
      isLoading: false,
      options: [],
      value: newValue,
    });

    const handleChange = (value: any) => {
      onChange(value);
      setState((prevState) => ({
        ...prevState,
        value,
      }));
    };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        value: newValue,
      }));
    }, [value]);

    const getAsyncOptions = async (inputValue: string) => {
      const queryParams = isEmpty(params) ? '' : `&${qs.stringify(params)}`;
      // remove from apiParams empty key phrase
      const apiParamsCopy = { ...apiParams };
      if (apiParamsCopy && apiParamsCopy?.phrase?.length >= 0) {
        delete apiParamsCopy['phrase'];
      }

      const apiQueryParams = isEmpty(apiParamsCopy)
        ? ''
        : `&${qs.stringify(apiParamsCopy, {
            arrayFormat: 'brackets',
            encode: false,
          })}`;

      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const options = await fetch(
        endpoint + `?phrase=${encodeURIComponent(inputValue)}${queryParams}${apiQueryParams}`,
        {
          credentials: 'include',
        },
      ).then((res) => res.json());

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));

      // Remove selected value
      // if doesn't exist in returned options
      const valueExists = await options.find((option: Option<string>) => {
        if (typeof value === 'number') {
          return option.value === value;
        }
        const { value: id } = value as Option<string>;
        return option.value === id;
      });

      if (valueExists && name) {
        set(formContext.values, name, valueExists);
        setState((prevState) => ({
          ...prevState,
          value: valueExists,
        }));
      }

      return options;
    };

    const loadOptions = useCallback(
      debounce((inputText, callback) => {
        getAsyncOptions(inputText).then((options) => callback(options));
      }, 300),
      [],
    );

    const handleFocus = async (e: any) => {
      const value = e.currentTarget.value;
      getAsyncOptions(value);
    };

    useEffect(() => {
      if (defaultValue) {
        getAsyncOptions(defaultValue);
      }
    }, [defaultValue]);
    return (
      <AsyncSelect
        className="border-0"
        loadOptions={loadOptions}
        isMulti={isMulti === true ? true : undefined}
        defaultOptions={true}
        defaultInputValue={defaultValue}
        value={state.value}
        onChange={handleChange}
        onBlur={onBlur}
        isClearable={isClearable}
        placeholder={
          placeholder ||
          (isMulti ? 'Multiple selection option...' : 'Select...')
        }
        isDisabled={readOnly}
        styles={styles}
        components={{ DropdownIndicator, ClearIndicator }}
        menuPlacement="auto"
        inputId={name}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        isSearchable={isSearchable}
        isLoading={state.isLoading}
        onFocus={handleFocus}
		cacheOptions
      />
    );
  }
  return (
    <Select
      isMulti={isMulti === true ? true : undefined}
      options={options}
      value={value}
      onChange={(value: any) => onChange(value)}
      onBlur={onBlur}
      isClearable={isClearable}
      placeholder={
        placeholder || (isMulti ? 'Multiple selection option...' : 'Select...')
      }
      isDisabled={readOnly}
      styles={styles}
      components={{ DropdownIndicator, ClearIndicator }}
      menuPlacement="auto"
      inputId={name}
      menuPortalTarget={menuPortalTarget ? document.body : null}
      isSearchable={isSearchable}
      className={className}
    />
  );
};

export default MultiselectDropdown2;