import React from 'react';

import { DatePicker } from 'components/DatePicker';

import { CustomReportFilter } from '@contractool/schema/ReportTab';

const DateRangeInputs: React.FC<{
  filter: CustomReportFilter;
  onUpdate: (updatedFilter: CustomReportFilter) => void;
}> = ({ filter, onUpdate }) => {
  const { property } = filter;

  const handleTo = (to: string) => {
    onUpdate({
      ...filter,
      values: ['custom_range'],
      to,
      property,
    });
  };

  const handleFrom = (from: string) => {
    onUpdate({
      ...filter,
      values: ['custom_range'],
      from,
      property,
    });
  };

  return (
    <div className="flex">
      <div className="w-2/4">
        <DatePicker
          name="from"
          formatInput="MM-dd-yyyy"
          value={filter.from}
          placeholder="from"
          placement="bottom-end"
          onChange={(value) => {
            handleFrom(value || '');
          }}
        />
      </div>
      <div className="w-2/4">
        <DatePicker
          name="to"
          formatInput="MM-dd-yyyy"
          value={filter.to}
          placeholder="to"
          placement="bottom-end"
          onChange={(value) => {
            handleTo(value || '');
          }}
        />
      </div>
    </div>
  );
};

export default DateRangeInputs;
