import React from 'react';
import { Link } from 'react-router-dom';

import { URL_INTEGRATIONS } from '@constants/integration';

import { translate } from 'utils/translations';
import { Integration } from 'types/output/integration';

type Props = {
  integration: Integration;
};

const Breadcrumb: React.FC<Props> = ({ integration }) => {
  return (
    <div className="flex text-gray-900 mb-5 text-md">
      <Link to={URL_INTEGRATIONS} className="underline">
        {translate('Integrations')}
      </Link>
      <div className="pl-3 pr-3">{'>'}</div>
      {integration?.title}
    </div>
  );
};

export default Breadcrumb;
