import React, { FC } from 'react';
import {
  ActivityContainer,
  ActivityHeader,
  ActivityBody,
} from '../ActivityPanel';
import { translate } from 'utils/translations';
import { Log } from '@contractool/schema';

export const MilestonesEditLog: FC<{ log: Log }> = ({ log }) => {
  let milestone = log.message.extra.milestone;

  const dellText = {
    dangerouslySetInnerHTML: { __html: milestone.to.value.dell },
  };
  const proofText = {
    dangerouslySetInnerHTML: { __html: milestone.to.value.proof },
  };

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={log.message.title}
        created_at={log.created_at}
		delegator={log?.delegator}
      />
      <ActivityBody className="mb-8 mr-14">
        <div>
          <div className="mb-4 text-gray-600">
            {translate('Dell Engineering activities')}
          </div>
          <div {...dellText} />
        </div>
        <div>
          <div className="mb-4 text-gray-600">
            {translate('Proof of performance')}
          </div>
          <div {...proofText} />
        </div>
        <div>
          <div className="mb-4 text-gray-600">
            {translate('Payment amount')}
          </div>
          <div>$ {milestone.to.value.amount}</div>
        </div>
        <div>
          <div className="mb-4 text-gray-600">{translate('Status')}</div>
          <div>{milestone.to.value.status}</div>
        </div>
      </ActivityBody>
    </ActivityContainer>
  );
};
