import { Project } from '../../../../_common/schema';
import React, { useState } from 'react';
import { useToasts } from 'hooks';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { Confirmation } from 'components/Confirmation';

export function RemoveRelatedProject({
  project,
  currentProject,
  onUpdate,
}: {
  project: Project;
  currentProject: Project;
  onUpdate: () => void;
}) {
  const { success } = useToasts();
  const { error } = useToasts();
  const handleFormSubmit = (data: any) =>
    http.post<any>(`/api/projects/${currentProject.id}/abandon`, data);

  return (
    <>
      <td>
        <Confirmation
          onConfirm={() => {
            handleFormSubmit({ child_id: project.id })
              .then(() => {
                success(
                  `${translate(
                    'Project was successfully abandoned from project',
                  )}`,
                );
                onUpdate();
              })
              .catch(() => {
                error(`${translate('Project was not abandoned from project')}`);
              });
          }}
          trigger={({ onClick }) => (
            <span
              className="text-blue-700 cursor-pointer align-items-center"
              onClick={onClick}
            >
              {translate('Remove')}
            </span>
          )}
          heading="Remove project"
          buttonText="Yes, remove"
          color="yellow"
        >
          Are you sure to remove project from related?
        </Confirmation>
      </td>
    </>
  );
}
