import { Project } from '@contractool/schema';
import { Editor } from '@tinymce/tinymce-react';
import * as React from 'react';

import { GuardContext, PusherContext } from 'contexts';
import { Button } from 'components/Button';
import { Toast } from 'components/Toast';
import { useToasts } from 'hooks';
import { Icon } from 'components/Icon';
import { Dropdown } from 'components/Dropdown';
import { Confirmation } from 'components/Confirmation';
import { CurrencyInput } from 'components/form/inputs';
import { getOptions } from 'utils/form';
import { http } from 'utils/http';
import { permissionRequest } from 'utils/wildcard';
import { translate } from 'utils/translations';
import './Milestones.css';

export interface Milestone {
  originalIndex?: number;
  id: number;
  year: string;
  dell: string;
  quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
  proof: string;
  amount: number;
  status: string;
  new: boolean;
  deleted: boolean;
}

const checkIfTimedOut = (seconds: number) =>
  new Date().getTime() / 1000 - seconds > 1800; // 1800 seconds = 30 mins

export const Milestones: React.FC<{
  project: Project;
  onUpdate: () => void;
}> = ({ project, onUpdate }) => {
  const { success } = useToasts();
  const [milestones, setMilestones] = React.useState<Milestone[]>(
    project.meta.milestone,
  );
  const isAmendment = project.fields.agreement_type === 'Amendment';

  let quarterOptions = getOptions(['Q1', 'Q2', 'Q3', 'Q4']);
  let statusOptions = getOptions(['Completed and Paid', 'Pending Completion']);

  let years = [];
  let nowYear = parseInt(new Date().getFullYear().toString().substr(-2));

  for (let y = 20; y <= nowYear + 4; y++) {
    years.push('FY' + y);
  }

  let yearOptions = getOptions(years.reverse());

  const init = {
    content_style: '.mce-content-body {padding : 7px}',
    height: 500,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help',
  };

  const addRow = (index: number) => {
    console.log('AddRow', index);
    let newMilestones = [];
    if (index === milestones.length - 1) {
      //Click on last row
      newMilestones = JSON.parse(JSON.stringify(milestones));
      let quarter = parseInt(
        milestones[milestones.length - 1].quarter.replace(/[^0-9]/g, ''),
      );
      let newQuarter = quarter + 1;
      if (quarter === 4) {
        newQuarter = 1;
      }
      let newYear = parseInt(
        milestones[milestones.length - 1].year.replace(/[^0-9]/g, ''),
      );
      if (newQuarter === 1) {
        newYear++;
      }

      newMilestones.push({
        new: true,
        deleted: false,
        amount: 0,
        id: milestones.length + 1,
        dell: '',
        year: 'FY' + newYear,
        quarter: 'Q' + newQuarter,
        proof: '',
      });
    } else {
      newMilestones = [];
      for (let i in milestones) {
        let row = JSON.parse(JSON.stringify(milestones[i]));
        console.log(
          'loopingRows',
          i,
          row,
          JSON.parse(JSON.stringify(newMilestones)),
        );
        if (index + 1 === parseInt(i)) {
          let newRow = {
            new: true,
            deleted: false,
            amount: 0,
            id: milestones.length + 1,
            dell: '',
            status: '',
            year: row.year,
            quarter: row.quarter,
            proof: '',
          };

          newMilestones.push(newRow);
          console.log(
            'Adding new row',
            i,
            newRow,
            'from row',
            row,
            JSON.parse(JSON.stringify(newMilestones)),
          );
        }
        if (!row.new && !row.originalIndex) {
          row.originalIndex = parseInt(i);
        }
        newMilestones.push(row);
        console.log(
          'Adding OLD row',
          i,
          row,
          JSON.parse(JSON.stringify(newMilestones)),
        );
      }
    }
    setMilestones(newMilestones);
  };

  const updateRow = (index: number, what: string, value: any) => {
    let newMilestones = milestones.map((milestone, i) => {
      let ret = milestone;
      if (i === index) {
        ret = { ...milestone, [what]: value };
      }
      console.log(what, index, value, ret);

      return ret;
    });

    setMilestones(newMilestones);
  };
  const { user } = React.useContext(GuardContext);

  const [lockedBy, setLockedBy] = React.useState(project.meta.milestone_lock);

  const editMode = React.useMemo(() => {
    return (
      lockedBy &&
      lockedBy.user &&
      user &&
      lockedBy.user.id === user.id &&
      lockedBy.time &&
      !checkIfTimedOut(lockedBy.time)
    );
  }, [lockedBy, user]);

  const lockedByOther = React.useMemo(() => {
    return (
      lockedBy &&
      lockedBy.user &&
      user &&
      lockedBy.user.id !== user.id &&
      lockedBy.time &&
      !checkIfTimedOut(lockedBy.time)
    );
  }, [lockedBy, user]);

  const pusher = React.useContext(PusherContext);
  const [subscribed, setSubscribed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  if (!subscribed && pusher && pusher.channel) {
    pusher
      .channel('app')
      .listen('.project.widget.milestone.lock_changed', (payload: any) => {
        if (payload.project_id === project.id) {
          setLockedBy(payload.lock);
        }
      });
    setSubscribed(true);
  }

  const switchEditMode = React.useCallback(() => {
    // todo: use async/await
    if (editMode) {
      setSubmitting(true);

      http
        .put(`api/integrations/dell_ji/projects/${project.id}/milestone`, {
          rows: milestones,
        })
        .then((newMilestone: any) => {
          setMilestones(newMilestone.data);
          onUpdate();
          success(`${translate('Milestones has been saved')}.`);
          http
            .post(
              `api/integrations/dell_ji/projects/${project.id}/milestone/unlock`,
            )
            .then(({ success, payload }: any) => {
              if (success) {
                setLockedBy(payload);
              } else {
                // todo: show toast message
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        });
    } else {
      setSubmitting(true);
      http
        .post(`api/integrations/dell_ji/projects/${project.id}/milestone/lock`)
        .then((result: any) => {
          setLockedBy(result.payload);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }, [editMode, project.id, milestones, onUpdate, success]);

  if (!user) {
    return null;
  }

  const milestoneEditPermission = permissionRequest(
    project,
    'project.widget.milestone.edit',
  );

  return (
    <div className="">
      <div className="text-lg mb-4">{translate('Milestones')}</div>
      {lockedByOther && (
        <div className="flex justify-center mb-10">
          <Toast variation={'error'}>
            {translate('Milestones table is currently edited by')}{' '}
            <b>{lockedBy.user.name}</b>
          </Toast>
        </div>
      )}
      <table className="table-auto w-full milestones-table">
        <thead>
          <tr>
            <th style={{ width: '3%' }}>#</th>
            <th style={{ width: '25%' }}>
              {translate('Dell Engineering activities')}
            </th>
            <th style={{ width: '7%' }}>{translate('Year')}</th>
            <th style={{ width: '8%' }}>{translate('Quarter')}</th>
            <th style={{ width: '25%' }}>
              {translate('Proof of performance')}
            </th>
            <th>{translate('Payment amount')}</th>
            {isAmendment && (
              <th style={{ width: '18%' }}>{translate('Status')}</th>
            )}
            {milestoneEditPermission && editMode && (
              <th className="bg-gray-000" style={{ width: '20px' }} />
            )}
          </tr>
        </thead>
        <tbody>
          {milestones &&
            milestones.map((milestone, index: number) => {
              console.log('rendering milestone', index, milestone);
              if (milestone.deleted) {
                return null;
              }
              const dellText = {
                dangerouslySetInnerHTML: { __html: milestone.dell },
              };
              const proofText = {
                dangerouslySetInnerHTML: { __html: milestone.proof },
              };

              let dellEditor = (
                <Editor
                  value={milestone.dell}
                  inline={true}
                  id={`dell-${index}-milestone`}
                  init={init}
                  apiKey="rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0"
                  onEditorChange={(data) => {
                    console.log('onEditorChange', data);
                    updateRow(index, 'dell', data);
                  }}
                />
              );

              return (
                <tr
                  key={`milestone${index}`}
                  className="bg-white border-b-4 border-gray-000 hover:milestone-hover"
                >
                  <td className="px-4 py-2 rounded-l-lg border-r-4 border-gray-000">
                    {index + 1}
                  </td>
                  <td className="px-4 py-2 text-sm border-r-4 border-gray-000">
                    {milestoneEditPermission && editMode ? (
                      <div className="flex items-center space-between">
                        <div className="w-full">{dellEditor}</div>
                        <div
                          onClick={() => {
                            // Document.execCommand('mceFocus',false,`dell-${index}-milestone`);
                          }}
                        >
                          <Icon
                            name="edit"
                            size={6}
                            className="text-gray-500"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="py-5" {...dellText} />
                    )}
                  </td>
                  <td className="px-4 py-2 text-center border-r-4 border-gray-000 milestone-year">
                    {milestoneEditPermission && editMode ? (
                      <Dropdown
                        name={`milestone-year-${index}`}
                        options={yearOptions}
                        onChange={(value: any) => {
                          updateRow(index, 'year', value);
                        }}
                        value={milestone.year}
                      />
                    ) : (
                      milestone.year
                    )}
                  </td>
                  <td className="px-4 py-2 text-center border-r-4 border-gray-000 milestone-quarter">
                    {milestoneEditPermission && editMode ? (
                      <Dropdown
                        name={`milestone-quarter-${index}`}
                        options={quarterOptions}
                        onChange={(value: any) => {
                          updateRow(index, 'quarter', value);
                        }}
                        value={milestone.quarter}
                      />
                    ) : (
                      milestone.quarter
                    )}
                  </td>
                  <td className="px-4 py-2 text-sm border-r-4 border-gray-000">
                    {milestoneEditPermission && editMode ? (
                      <div className="flex items-center space-between">
                        <div className="w-full">
                          <Editor
                            value={milestone.proof}
                            inline={true}
                            init={init}
                            id={`proof-${index}-milestone`}
                            apiKey="rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0"
                            onEditorChange={(data) => {
                              updateRow(index, 'proof', data);
                            }}
                          />
                        </div>
                        <div>
                          <Icon
                            name="edit"
                            size={6}
                            className="text-gray-500"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="py-5" {...proofText} />
                    )}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap rounded-r-lg w-7 border-r-4 border-gray-000">
                    <CurrencyInput
                      name="values"
                      placeholder="Number ..."
                      value={
                        milestone.amount ? milestone.amount.toString() : ''
                      }
                      locale="en-US"
                      currency="USD"
                      disabled={!milestoneEditPermission || !editMode}
                      onChange={(value) => {
                        updateRow(index, 'amount', Number(value));
                      }}
                      className={`milestone-amount-${index} py-5 focus:outline-none focus:border-b leading-none text-gray-700 placeholder-gray-400 border-gray-200 focus:border-blue-700`}
                    />
                  </td>
                  {isAmendment && (
                    <td className="px-4 py-2 text-sm border-r-4 border-gray-000 whitespace-nowrap milestone-status">
                      {editMode ? (
                        <Dropdown
                          name={`milestone-status-${index}`}
                          options={statusOptions}
                          onChange={(value: any) => {
                            updateRow(index, 'status', value);
                          }}
                          value={milestone.status ? milestone.status : ''}
                        />
                      ) : (
                        <div className="py-5">{milestone.status}</div>
                      )}
                    </td>
                  )}
                  {milestoneEditPermission && editMode && (
                    <td className="bg-gray-000" style={{ width: '20px' }}>
                      {milestones.filter((m) => !m.deleted).length > 1 && (
                        <Confirmation
                          onConfirm={() => {
                            updateRow(index, 'deleted', true);
                          }}
                          trigger={({ onClick }) => (
                            <div className="cursor-pointer" onClick={onClick}>
                              <Icon
                                name={'delete'}
                                size={5}
                                className="text-gray-500"
                              />
                            </div>
                          )}
                          heading="Delete milestone"
                          buttonText={'Yes, delete'}
                          color="red"
                        >
                          {translate('Really delete :index milestone?', {
                            index: `#${index + 1}`,
                          })}
                        </Confirmation>
                      )}
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          addRow(index);
                        }}
                      >
                        <Icon
                          name={'add'}
                          size={5}
                          className="text-gray-500 mt-3"
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      {milestoneEditPermission && (
        <div className="mt-10">
          <div className="text-right">
            {/*<Button*/}
            {/*className="mt-10 mr-10"*/}
            {/*color="white"*/}
            {/*onClick={addRow}*/}
            {/*>*/}
            {/*<Icon name="add" size={5} className="mr-3"/>*/}
            {/*Add row*/}
            {/*</Button>*/}
            {!lockedByOther && (
              <>
                {editMode ? (
                  <Button onClick={switchEditMode} disabled={submitting}>
                    <Icon name="done" size={5} className="mr-3" />
                    {translate('Save Milestones')}
                  </Button>
                ) : (
                  <Button onClick={switchEditMode} disabled={submitting}>
                    <Icon name="edit" size={5} className="mr-3" />
                    {translate('Edit')}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
