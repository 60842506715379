import React, { useContext } from 'react';
import { snakeCase } from 'lodash';

import { FormRenderer } from 'components/Formio';

import { AppContext } from 'contexts';

type Props = {
  onSubmit: (values: object) => void;
  value: object;
  states: object[];
};

const Role: React.FC<Props> = ({ onSubmit, value, states }) => {
  const { config } = useContext(AppContext);

  const groups = config?.user_groups || [];
  const createGroups = (options: any[]) =>
    options.map((option) => ({
      label: option.label,
      value: option.key,
    }));

  const createStates = (options: any[]) =>
    options.map((option) => ({
      label: option.stateTitle,
      value: snakeCase(option.stateTitle),
    }));

  return (
    <FormRenderer
      submission={{ data: value }}
      form={{
        display: 'form',
        components: [
          {
            label: 'Roles',
            labelPosition: 'top',
            description: '',
            tooltip: '',
            disableAddingRemovingRows: false,
            conditionalAddButton: '',
            reorder: false,
            addAnother: '',
            addAnotherPosition: 'bottom',
            layoutFixed: false,
            enableRowGroups: false,
            initEmpty: false,
            customClass: '',
            tabindex: '',
            hidden: false,
            hideLabel: false,
            autofocus: false,
            disabled: false,
            tableView: false,
            modalEdit: false,
            defaultValue: [
              {
                roleName: '',
                roleTag: '',
                select: 'singular',
                showInProjectForm: true,
                required: false,
                groupPermissions: [
                  {
                    what1: '',
                    what: '',
                    detail: '',
                    states: [],
                  },
                ],
              },
            ],
            persistent: true,
            protected: false,
            dbIndex: false,
            encrypted: false,
            redrawOn: '',
            clearOnHide: true,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            allowCalculateOverride: false,
            validateOn: 'change',
            validate: {
              required: false,
              minLength: '',
              maxLength: '',
              customMessage: '',
              custom: '',
              customPrivate: false,
              json: '',
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            errorLabel: '',
            errors: '',
            key: 'roles',
            tags: [],
            properties: {},
            conditional: {
              show: null,
              when: null,
              eq: '',
              json: '',
            },
            customConditional: '',
            logic: [],
            attributes: {},
            overlay: {
              style: '',
              page: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            type: 'datagrid',
            input: true,
            components: [
              {
                label: 'Role',
                key: 'role',
                type: 'well',
                input: false,
                tableView: false,
                components: [
                  {
                    label: 'Columns',
                    columns: [
                      {
                        components: [
                          {
                            label: 'Role name',
                            tableView: true,
                            key: 'roleName',
                            type: 'textfield',
                            input: true,
                            placeholder: '',
                            prefix: '',
                            customClass: '',
                            suffix: '',
                            multiple: false,
                            defaultValue: null,
                            protected: false,
                            unique: false,
                            persistent: true,
                            hidden: false,
                            clearOnHide: true,
                            refreshOn: '',
                            redrawOn: '',
                            modalEdit: false,
                            dataGridLabel: false,
                            labelPosition: 'top',
                            description: '',
                            errorLabel: '',
                            tooltip: '',
                            hideLabel: false,
                            tabindex: '',
                            disabled: false,
                            autofocus: false,
                            dbIndex: false,
                            customDefaultValue: '',
                            calculateValue: '',
                            calculateServer: false,
                            widget: {
                              type: 'input',
                            },
                            attributes: {},
                            validateOn: 'change',
                            validate: {
                              required: true,
                              custom: '',
                              customPrivate: false,
                              strictDateValidation: false,
                              multiple: false,
                              unique: false,
                              minLength: '',
                              maxLength: '',
                              pattern: '',
                            },
                            conditional: {
                              show: null,
                              when: null,
                              eq: '',
                            },
                            overlay: {
                              style: '',
                              left: '',
                              top: '',
                              width: '',
                              height: '',
                            },
                            allowCalculateOverride: false,
                            encrypted: false,
                            showCharCount: false,
                            showWordCount: false,
                            properties: {},
                            allowMultipleMasks: false,
                            addons: [],
                            mask: false,
                            inputType: 'text',
                            inputFormat: 'plain',
                            inputMask: '',
                            displayMask: '',
                            spellcheck: true,
                            truncateMultipleSpaces: false,
                            id: 'e07ft4z5',
                          },
                        ],
                        width: 3,
                        offset: 0,
                        push: 0,
                        pull: 0,
                        size: 'md',
                        currentWidth: 3,
                      },
                      {
                        components: [
                          {
                            label: 'Role tag',
                            tableView: true,
                            key: 'roleTag',
                            type: 'textfield',
                            input: true,
                            placeholder: '',
                            prefix: '',
                            customClass: '',
                            suffix: '',
                            multiple: false,
                            defaultValue: null,
                            protected: false,
                            unique: false,
                            persistent: true,
                            hidden: false,
                            clearOnHide: true,
                            refreshOn: '',
                            redrawOn: '',
                            modalEdit: false,
                            dataGridLabel: false,
                            labelPosition: 'top',
                            description: '',
                            errorLabel: '',
                            tooltip: '',
                            hideLabel: false,
                            tabindex: '',
                            disabled: false,
                            autofocus: false,
                            dbIndex: false,
                            customDefaultValue: '',
                            calculateValue: '',
                            calculateServer: false,
                            widget: {
                              type: 'input',
                            },
                            attributes: {},
                            validateOn: 'change',
                            validate: {
                              required: true,
                              custom: '',
                              customPrivate: false,
                              strictDateValidation: false,
                              multiple: false,
                              unique: false,
                              minLength: '',
                              maxLength: '',
                              pattern: '',
                            },
                            conditional: {
                              show: null,
                              when: null,
                              eq: '',
                            },
                            overlay: {
                              style: '',
                              left: '',
                              top: '',
                              width: '',
                              height: '',
                            },
                            allowCalculateOverride: false,
                            encrypted: false,
                            showCharCount: false,
                            showWordCount: false,
                            properties: {},
                            allowMultipleMasks: false,
                            addons: [],
                            mask: false,
                            inputType: 'text',
                            inputFormat: 'plain',
                            inputMask: '',
                            displayMask: '',
                            spellcheck: true,
                            truncateMultipleSpaces: false,
                            id: 'e5r7ft',
                          },
                        ],
                        width: 3,
                        offset: 0,
                        push: 0,
                        pull: 0,
                        size: 'md',
                        currentWidth: 3,
                      },
                      {
                        components: [
                          {
                            label: 'Select',
                            widget: 'choicesjs',
                            tableView: true,
                            data: {
                              values: [
                                {
                                  label: 'Singular',
                                  value: 'singular',
                                },
                                {
                                  label: 'Multiple',
                                  value: 'multiple',
                                },
                              ],
                              json: '',
                              url: '',
                              resource: '',
                              custom: '',
                            },
                            key: 'select',
                            type: 'select',
                            input: true,
                            defaultValue: 'singular',
                            placeholder: '',
                            prefix: '',
                            customClass: '',
                            suffix: '',
                            multiple: false,
                            protected: false,
                            unique: false,
                            persistent: true,
                            hidden: false,
                            clearOnHide: true,
                            refreshOn: '',
                            redrawOn: '',
                            modalEdit: false,
                            dataGridLabel: false,
                            labelPosition: 'top',
                            description: '',
                            errorLabel: '',
                            tooltip: '',
                            hideLabel: false,
                            tabindex: '',
                            disabled: false,
                            autofocus: false,
                            dbIndex: false,
                            customDefaultValue: '',
                            calculateValue: '',
                            calculateServer: false,
                            attributes: {},
                            validateOn: 'change',
                            validate: {
                              required: false,
                              custom: '',
                              customPrivate: false,
                              strictDateValidation: false,
                              multiple: false,
                              unique: false,
                              onlyAvailableItems: false,
                            },
                            conditional: {
                              show: null,
                              when: null,
                              eq: '',
                            },
                            overlay: {
                              style: '',
                              left: '',
                              top: '',
                              width: '',
                              height: '',
                            },
                            allowCalculateOverride: false,
                            encrypted: false,
                            showCharCount: false,
                            showWordCount: false,
                            properties: {},
                            allowMultipleMasks: false,
                            addons: [],
                            idPath: 'id',
                            clearOnRefresh: false,
                            limit: 100,
                            dataSrc: 'values',
                            valueProperty: '',
                            lazyLoad: true,
                            filter: '',
                            searchEnabled: true,
                            searchDebounce: 0.3,
                            searchField: '',
                            minSearch: 0,
                            readOnlyValue: false,
                            authenticate: false,
                            ignoreCache: false,
                            template: '<span>{{ item.label }}</span>',
                            selectFields: '',
                            selectThreshold: 0.3,
                            uniqueOptions: false,
                            fuseOptions: {
                              include: 'score',
                              threshold: 0.3,
                            },
                            indexeddb: {
                              filter: {},
                            },
                            customOptions: {},
                            useExactSearch: false,
                            id: 'eda09gi',
                          },
                        ],
                        size: 'md',
                        width: 3,
                        offset: 0,
                        push: 0,
                        pull: 0,
                        currentWidth: 3,
                      },
                      {
                        components: [
                          {
                            label: 'Groups',
                            labelPosition: 'top',
                            widget: 'choicesjs',
                            placeholder: '',
                            description: '',
                            tooltip: '',
                            customClass: '',
                            tabindex: '',
                            hidden: false,
                            hideLabel: false,
                            uniqueOptions: false,
                            autofocus: false,
                            disabled: false,
                            tableView: true,
                            modalEdit: false,
                            multiple: true,
                            dataSrc: 'values',
                            data: {
                              // remove bellow line when adjusting in Formio Builder
                              values: createGroups(groups),
                              // when adjusting in Formio Builder, you have to keep json structure
                              // enable bellow when adjusting in Formio Builder
                              // values: [
                              //   {
                              //     label: 'test',
                              //     value: 'test',
                              //   },
                              // ],
                              resource: '',
                              json: '',
                              url: '',
                              custom: '',
                            },
                            dataType: '',
                            idPath: 'id',
                            valueProperty: '',
                            template: '<span>{{ item.label }}</span>',
                            refreshOn: '',
                            refreshOnBlur: '',
                            clearOnRefresh: false,
                            searchEnabled: true,
                            selectThreshold: 0.3,
                            readOnlyValue: false,
                            customOptions: {},
                            useExactSearch: false,
                            persistent: true,
                            protected: false,
                            dbIndex: false,
                            encrypted: false,
                            clearOnHide: true,
                            customDefaultValue: '',
                            calculateValue: '',
                            calculateServer: false,
                            allowCalculateOverride: false,
                            validateOn: 'change',
                            validate: {
                              required: false,
                              onlyAvailableItems: false,
                              customMessage: '',
                              custom: '',
                              customPrivate: false,
                              json: '',
                              strictDateValidation: false,
                              multiple: false,
                              unique: false,
                            },
                            unique: false,
                            errorLabel: '',
                            errors: '',
                            key: 'groups',
                            tags: [],
                            properties: {},
                            conditional: {
                              show: null,
                              when: null,
                              eq: '',
                              json: '',
                            },
                            customConditional: '',
                            logic: [],
                            attributes: {},
                            overlay: {
                              style: '',
                              page: '',
                              left: '',
                              top: '',
                              width: '',
                              height: '',
                            },
                            type: 'select',
                            indexeddb: {
                              filter: {},
                            },
                            selectFields: '',
                            searchField: '',
                            searchDebounce: 0.3,
                            minSearch: 0,
                            filter: '',
                            limit: 100,
                            redrawOn: '',
                            input: true,
                            prefix: '',
                            suffix: '',
                            dataGridLabel: false,
                            showCharCount: false,
                            showWordCount: false,
                            allowMultipleMasks: false,
                            addons: [],
                            lazyLoad: true,
                            authenticate: false,
                            ignoreCache: false,
                            fuseOptions: {
                              include: 'score',
                              threshold: 0.3,
                            },
                            id: 'e8u9t0f',
                            defaultValue: '',
                          },
                        ],
                        size: 'md',
                        width: 3,
                        offset: 0,
                        push: 0,
                        pull: 0,
                        currentWidth: 3,
                      },
                    ],
                    autoAdjust: false,
                    customClass: 'pb-3',
                    hidden: false,
                    hideLabel: false,
                    modalEdit: false,
                    key: 'columns',
                    tags: [],
                    properties: {},
                    conditional: {
                      show: null,
                      when: null,
                      eq: '',
                      json: '',
                    },
                    customConditional: '',
                    logic: [],
                    attributes: {},
                    overlay: {
                      style: '',
                      page: '',
                      left: '',
                      top: '',
                      width: '',
                      height: '',
                    },
                    type: 'columns',
                    input: false,
                    tableView: false,
                    placeholder: '',
                    prefix: '',
                    suffix: '',
                    multiple: false,
                    defaultValue: null,
                    protected: false,
                    unique: false,
                    persistent: false,
                    clearOnHide: false,
                    refreshOn: '',
                    redrawOn: '',
                    dataGridLabel: false,
                    labelPosition: 'top',
                    description: '',
                    errorLabel: '',
                    tooltip: '',
                    tabindex: '',
                    disabled: false,
                    autofocus: false,
                    dbIndex: false,
                    customDefaultValue: '',
                    calculateValue: '',
                    calculateServer: false,
                    widget: null,
                    validateOn: 'change',
                    validate: {
                      required: false,
                      custom: '',
                      customPrivate: false,
                      strictDateValidation: false,
                      multiple: false,
                      unique: false,
                    },
                    allowCalculateOverride: false,
                    encrypted: false,
                    showCharCount: false,
                    showWordCount: false,
                    allowMultipleMasks: false,
                    addons: [],
                    tree: false,
                    lazyLoad: false,
                    id: 'ee53d7f',
                  },
                  {
                    label: 'Show in project form',
                    tableView: false,
                    key: 'showInProjectForm',
                    type: 'checkbox',
                    input: true,
                    defaultValue: true,
                    placeholder: '',
                    prefix: '',
                    customClass: '',
                    suffix: '',
                    multiple: false,
                    protected: false,
                    unique: false,
                    persistent: true,
                    hidden: false,
                    clearOnHide: true,
                    refreshOn: '',
                    redrawOn: '',
                    modalEdit: false,
                    dataGridLabel: true,
                    labelPosition: 'right',
                    description: '',
                    errorLabel: '',
                    tooltip: '',
                    hideLabel: false,
                    tabindex: '',
                    disabled: false,
                    autofocus: false,
                    dbIndex: false,
                    customDefaultValue: '',
                    calculateValue: '',
                    calculateServer: false,
                    widget: null,
                    attributes: {},
                    validateOn: 'change',
                    validate: {
                      required: false,
                      custom: '',
                      customPrivate: false,
                      strictDateValidation: false,
                      multiple: false,
                      unique: false,
                    },
                    conditional: {
                      show: null,
                      when: null,
                      eq: '',
                    },
                    overlay: {
                      style: '',
                      left: '',
                      top: '',
                      width: '',
                      height: '',
                    },
                    allowCalculateOverride: false,
                    encrypted: false,
                    showCharCount: false,
                    showWordCount: false,
                    properties: {},
                    allowMultipleMasks: false,
                    addons: [],
                    inputType: 'checkbox',
                    value: '',
                    name: '',
                    id: 'ekr8cfm',
                  },
                  {
                    label: 'Required',
                    tableView: false,
                    key: 'required',
                    type: 'checkbox',
                    input: true,
                    defaultValue: false,
                    placeholder: '',
                    prefix: '',
                    customClass: '',
                    suffix: '',
                    multiple: false,
                    protected: false,
                    unique: false,
                    persistent: true,
                    hidden: false,
                    clearOnHide: true,
                    refreshOn: '',
                    redrawOn: '',
                    modalEdit: false,
                    dataGridLabel: true,
                    labelPosition: 'right',
                    description: '',
                    errorLabel: '',
                    tooltip: '',
                    hideLabel: false,
                    tabindex: '',
                    disabled: false,
                    autofocus: false,
                    dbIndex: false,
                    customDefaultValue: '',
                    calculateValue: '',
                    calculateServer: false,
                    widget: null,
                    attributes: {},
                    validateOn: 'change',
                    validate: {
                      required: false,
                      custom: '',
                      customPrivate: false,
                      strictDateValidation: false,
                      multiple: false,
                      unique: false,
                    },
                    conditional: {
                      show: null,
                      when: null,
                      eq: '',
                    },
                    overlay: {
                      style: '',
                      left: '',
                      top: '',
                      width: '',
                      height: '',
                    },
                    allowCalculateOverride: false,
                    encrypted: false,
                    showCharCount: false,
                    showWordCount: false,
                    properties: {},
                    allowMultipleMasks: false,
                    addons: [],
                    inputType: 'checkbox',
                    value: '',
                    name: '',
                    id: 'ejkmey5',
                  },
                  {
                    label: 'Roles permissions',
                    reorder: false,
                    addAnotherPosition: 'bottom',
                    layoutFixed: false,
                    enableRowGroups: false,
                    initEmpty: false,
                    tableView: false,
                    defaultValue: [
                      {
                        what1: '',
                        what: '',
                        detail: '',
                      },
                    ],
                    key: 'groupPermissions',
                    type: 'datagrid',
                    input: true,
                    components: [
                      {
                        label: 'permission',
                        widget: 'choicesjs',
                        tableView: true,
                        data: {
                          values: [
                            {
                              label: 'allow',
                              value: 'allow',
                            },
                            {
                              label: 'forbid',
                              value: 'forbid',
                            },
                          ],
                          json: '',
                          url: '',
                          resource: '',
                          custom: '',
                        },
                        key: 'what1',
                        type: 'select',
                        input: true,
                        id: 'ew0cq90000',
                        placeholder: '',
                        prefix: '',
                        customClass: '',
                        suffix: '',
                        multiple: false,
                        defaultValue: null,
                        protected: false,
                        unique: false,
                        persistent: true,
                        hidden: false,
                        clearOnHide: true,
                        refreshOn: '',
                        redrawOn: '',
                        modalEdit: false,
                        dataGridLabel: false,
                        labelPosition: 'top',
                        description: '',
                        errorLabel: '',
                        tooltip: '',
                        hideLabel: false,
                        tabindex: '',
                        disabled: false,
                        autofocus: false,
                        dbIndex: false,
                        customDefaultValue: '',
                        calculateValue: '',
                        calculateServer: false,
                        attributes: {},
                        validateOn: 'change',
                        validate: {
                          required: false,
                          custom: '',
                          customPrivate: false,
                          strictDateValidation: false,
                          multiple: false,
                          unique: false,
                          onlyAvailableItems: false,
                        },
                        conditional: {
                          show: null,
                          when: null,
                          eq: '',
                        },
                        overlay: {
                          style: '',
                          left: '',
                          top: '',
                          width: '',
                          height: '',
                        },
                        allowCalculateOverride: false,
                        encrypted: false,
                        showCharCount: false,
                        showWordCount: false,
                        properties: {},
                        allowMultipleMasks: false,
                        addons: [],
                        idPath: 'id',
                        clearOnRefresh: false,
                        limit: 100,
                        dataSrc: 'values',
                        valueProperty: '',
                        lazyLoad: true,
                        filter: '',
                        searchEnabled: true,
                        searchDebounce: 0.3,
                        searchField: '',
                        minSearch: 0,
                        readOnlyValue: false,
                        authenticate: false,
                        ignoreCache: false,
                        template: '<span>{{ item.label }}</span>',
                        selectFields: '',
                        selectThreshold: 0.3,
                        uniqueOptions: false,
                        fuseOptions: {
                          include: 'score',
                          threshold: 0.3,
                        },
                        indexeddb: {
                          filter: {},
                        },
                        customOptions: {},
                        useExactSearch: false,
                      },
                      {
                        label: 'component',
                        widget: 'choicesjs',
                        tableView: true,
                        data: {
                          values: [
                            {
                              label: 'Contract',
                              value: 'contract',
                            },
                            {
                              label: 'Attachements',
                              value: 'attachements',
                            },
                            {
                              label: 'Tasks',
                              value: 'tasks',
                            },
                            {
                              label: 'Approvals',
                              value: 'approvals',
                            },
                            {
                              label: 'Private discussion',
                              value: 'private-discussion',
                            },
                          ],
                          json: '',
                          url: '',
                          resource: '',
                          custom: '',
                        },
                        key: 'what',
                        type: 'select',
                        input: true,
                        id: 'e3cbg6v0000',
                        placeholder: '',
                        prefix: '',
                        customClass: '',
                        suffix: '',
                        multiple: false,
                        defaultValue: null,
                        protected: false,
                        unique: false,
                        persistent: true,
                        hidden: false,
                        clearOnHide: true,
                        refreshOn: '',
                        redrawOn: '',
                        modalEdit: false,
                        dataGridLabel: false,
                        labelPosition: 'top',
                        description: '',
                        errorLabel: '',
                        tooltip: '',
                        hideLabel: false,
                        tabindex: '',
                        disabled: false,
                        autofocus: false,
                        dbIndex: false,
                        customDefaultValue: '',
                        calculateValue: '',
                        calculateServer: false,
                        attributes: {},
                        validateOn: 'change',
                        validate: {
                          required: false,
                          custom: '',
                          customPrivate: false,
                          strictDateValidation: false,
                          multiple: false,
                          unique: false,
                          onlyAvailableItems: false,
                        },
                        conditional: {
                          show: null,
                          when: null,
                          eq: '',
                        },
                        overlay: {
                          style: '',
                          left: '',
                          top: '',
                          width: '',
                          height: '',
                        },
                        allowCalculateOverride: false,
                        encrypted: false,
                        showCharCount: false,
                        showWordCount: false,
                        properties: {},
                        allowMultipleMasks: false,
                        addons: [],
                        idPath: 'id',
                        clearOnRefresh: false,
                        limit: 100,
                        dataSrc: 'values',
                        valueProperty: '',
                        lazyLoad: true,
                        filter: '',
                        searchEnabled: true,
                        searchDebounce: 0.3,
                        searchField: '',
                        minSearch: 0,
                        readOnlyValue: false,
                        authenticate: false,
                        ignoreCache: false,
                        template: '<span>{{ item.label }}</span>',
                        selectFields: '',
                        selectThreshold: 0.3,
                        uniqueOptions: false,
                        fuseOptions: {
                          include: 'score',
                          threshold: 0.3,
                        },
                        indexeddb: {
                          filter: {},
                        },
                        customOptions: {},
                        useExactSearch: false,
                      },
                      {
                        label: 'action',
                        widget: 'choicesjs',
                        tableView: true,
                        data: {
                          values: [
                            {
                              label: '*',
                              value: 'all',
                            },
                            {
                              label: 'view',
                              value: 'view',
                            },
                            {
                              label: 'edit',
                              value: 'edit',
                            },
                            {
                              label: 'delete',
                              value: 'delete',
                            },
                          ],
                          json: '',
                          url: '',
                          resource: '',
                          custom: '',
                        },
                        key: 'detail',
                        type: 'select',
                        input: true,
                        id: 'etfixo50000',
                        placeholder: '',
                        prefix: '',
                        customClass: '',
                        suffix: '',
                        multiple: false,
                        defaultValue: null,
                        protected: false,
                        unique: false,
                        persistent: true,
                        hidden: false,
                        clearOnHide: true,
                        refreshOn: '',
                        redrawOn: '',
                        modalEdit: false,
                        dataGridLabel: false,
                        labelPosition: 'top',
                        description: '',
                        errorLabel: '',
                        tooltip: '',
                        hideLabel: false,
                        tabindex: '',
                        disabled: false,
                        autofocus: false,
                        dbIndex: false,
                        customDefaultValue: '',
                        calculateValue: '',
                        calculateServer: false,
                        attributes: {},
                        validateOn: 'change',
                        validate: {
                          required: false,
                          custom: '',
                          customPrivate: false,
                          strictDateValidation: false,
                          multiple: false,
                          unique: false,
                          onlyAvailableItems: false,
                        },
                        conditional: {
                          show: null,
                          when: null,
                          eq: '',
                        },
                        overlay: {
                          style: '',
                          left: '',
                          top: '',
                          width: '',
                          height: '',
                        },
                        allowCalculateOverride: false,
                        encrypted: false,
                        showCharCount: false,
                        showWordCount: false,
                        properties: {},
                        allowMultipleMasks: false,
                        addons: [],
                        idPath: 'id',
                        clearOnRefresh: false,
                        limit: 100,
                        dataSrc: 'values',
                        valueProperty: '',
                        lazyLoad: true,
                        filter: '',
                        searchEnabled: true,
                        searchDebounce: 0.3,
                        searchField: '',
                        minSearch: 0,
                        readOnlyValue: false,
                        authenticate: false,
                        ignoreCache: false,
                        template: '<span>{{ item.label }}</span>',
                        selectFields: '',
                        selectThreshold: 0.3,
                        uniqueOptions: false,
                        fuseOptions: {
                          include: 'score',
                          threshold: 0.3,
                        },
                        indexeddb: {
                          filter: {},
                        },
                        customOptions: {},
                        useExactSearch: false,
                      },
                      {
                        label: 'States',
                        widget: 'choicesjs',
                        tableView: true,
                        multiple: true,
                        data: {
                          // remove bellow line when adjusting in Formio Builder
                          values: createStates(states),
                          // when adjusting in Formio Builder, you have to keep json structure
                          // enable bellow when adjusting in Formio Builder
                          // values: [
                          //   {
                          //     label: 'test',
                          //     value: 'test',
                          //   },
                          // ],
                          json: '',
                          url: '',
                          resource: '',
                          custom: '',
                        },
                        key: 'states',
                        type: 'select',
                        input: true,
                        id: 'evzy27l0000',
                        placeholder: '',
                        prefix: '',
                        customClass: '',
                        suffix: '',
                        defaultValue: null,
                        protected: false,
                        unique: false,
                        persistent: true,
                        hidden: false,
                        clearOnHide: true,
                        refreshOn: '',
                        redrawOn: '',
                        modalEdit: false,
                        dataGridLabel: false,
                        labelPosition: 'top',
                        description: '',
                        errorLabel: '',
                        tooltip: '',
                        hideLabel: false,
                        tabindex: '',
                        disabled: false,
                        autofocus: false,
                        dbIndex: false,
                        customDefaultValue: '',
                        calculateValue: '',
                        calculateServer: false,
                        attributes: {},
                        validateOn: 'change',
                        validate: {
                          required: false,
                          custom: '',
                          customPrivate: false,
                          strictDateValidation: false,
                          multiple: false,
                          unique: false,
                          onlyAvailableItems: false,
                        },
                        conditional: {
                          show: null,
                          when: null,
                          eq: '',
                        },
                        overlay: {
                          style: '',
                          left: '',
                          top: '',
                          width: '',
                          height: '',
                        },
                        allowCalculateOverride: false,
                        encrypted: false,
                        showCharCount: false,
                        showWordCount: false,
                        properties: {},
                        allowMultipleMasks: false,
                        addons: [],
                        idPath: 'id',
                        clearOnRefresh: false,
                        limit: 100,
                        dataSrc: 'values',
                        valueProperty: '',
                        lazyLoad: true,
                        filter: '',
                        searchEnabled: true,
                        searchDebounce: 0.3,
                        searchField: '',
                        minSearch: 0,
                        readOnlyValue: false,
                        authenticate: false,
                        ignoreCache: false,
                        template: '<span>{{ item.label }}</span>',
                        selectFields: '',
                        selectThreshold: 0.3,
                        uniqueOptions: false,
                        fuseOptions: {
                          include: 'score',
                          threshold: 0.3,
                        },
                        indexeddb: {
                          filter: {},
                        },
                        customOptions: {},
                        useExactSearch: false,
                      },
                    ],
                    placeholder: '',
                    prefix: '',
                    customClass: '',
                    suffix: '',
                    multiple: false,
                    protected: false,
                    unique: false,
                    persistent: true,
                    hidden: false,
                    clearOnHide: true,
                    refreshOn: '',
                    redrawOn: '',
                    modalEdit: false,
                    dataGridLabel: false,
                    labelPosition: 'top',
                    description: '',
                    errorLabel: '',
                    tooltip: '',
                    hideLabel: false,
                    tabindex: '',
                    disabled: false,
                    autofocus: false,
                    dbIndex: false,
                    customDefaultValue: '',
                    calculateValue: '',
                    calculateServer: false,
                    widget: null,
                    attributes: {},
                    validateOn: 'change',
                    validate: {
                      required: false,
                      custom: '',
                      customPrivate: false,
                      strictDateValidation: false,
                      multiple: false,
                      unique: false,
                    },
                    conditional: {
                      show: null,
                      when: null,
                      eq: '',
                    },
                    overlay: {
                      style: '',
                      left: '',
                      top: '',
                      width: '',
                      height: '',
                    },
                    allowCalculateOverride: false,
                    encrypted: false,
                    showCharCount: false,
                    showWordCount: false,
                    properties: {},
                    allowMultipleMasks: false,
                    addons: [],
                    tree: true,
                    lazyLoad: false,
                    disableAddingRemovingRows: false,
                    id: 'e2n2wnl',
                  },
                ],
                id: 'ec4zy9u000',
                placeholder: '',
                prefix: '',
                customClass: '',
                suffix: '',
                multiple: false,
                defaultValue: null,
                protected: false,
                unique: false,
                persistent: false,
                hidden: false,
                clearOnHide: true,
                refreshOn: '',
                redrawOn: '',
                modalEdit: false,
                dataGridLabel: false,
                labelPosition: 'top',
                description: '',
                errorLabel: '',
                tooltip: '',
                hideLabel: false,
                tabindex: '',
                disabled: false,
                autofocus: false,
                dbIndex: false,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                widget: null,
                attributes: {},
                validateOn: 'change',
                validate: {
                  required: false,
                  custom: '',
                  customPrivate: false,
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                },
                conditional: {
                  show: null,
                  when: null,
                  eq: '',
                },
                overlay: {
                  style: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                allowCalculateOverride: false,
                encrypted: false,
                showCharCount: false,
                showWordCount: false,
                properties: {},
                allowMultipleMasks: false,
                addons: [],
                tree: false,
                lazyLoad: false,
              },
            ],
            placeholder: '',
            prefix: '',
            suffix: '',
            multiple: false,
            unique: false,
            refreshOn: '',
            dataGridLabel: false,
            widget: null,
            showCharCount: false,
            showWordCount: false,
            allowMultipleMasks: false,
            addons: [],
            tree: true,
            lazyLoad: false,
            id: 'eyyes7i',
          },
          {
            type: 'button',
            label: 'Submit',
            key: 'submit',
            size: 'md',
            block: false,
            action: 'submit',
            disableOnInvalid: true,
            theme: 'primary',
            input: true,
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            dataGridLabel: true,
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            addons: [],
            leftIcon: '',
            rightIcon: '',
            id: 'eqd8u4m000',
          },
        ],
      }}
      onSubmit={({ data }) => onSubmit(data)}
    />
  );
};

export default Role;
