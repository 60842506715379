import { useState } from 'react';
import { translate } from 'utils/translations';
import { Button } from 'components/Button';
import { FormBuilder, FormRenderer } from 'components/Formio';
import Delay from 'components/Integration/Delay';

type Props = {
  onSubmit: (values: object) => void;
  value: object;
};

const FieldsBuilder: React.FC<Props> = ({ value, onSubmit }) => {
  const [state, setState] = useState<object>();

  return (
    <>
      <div className="pb-5">
        <Button onClick={() => onSubmit(state)} size="small">
          {translate('Save Fields')}
        </Button>
      </div>
      <h1 className="text-xl pb-3">{translate('Builder')}</h1>
      <FormBuilder
        options={{
          builder: {
            custom: {
              title: translate('Custom'),
              weight: 0,
            },
            basic: {
              default: false,
            },
          },
        }}
        form={{
          ...value,
          display: 'form',
        }}
        onChange={(form) =>
          setState((prevState) => ({ ...prevState, ...form }))
        }
      />
      <h1 className="text-xl pb-3 pt-3">{translate('Renderer')}</h1>
      <Delay>
        <FormRenderer
          form={{
            ...value,
            display: 'form',
          }}
        />
      </Delay>
    </>
  );
};

export default FieldsBuilder;
