import React from 'react';
import { Cell } from 'react-table';

import { TableWithPagination } from 'components/Table';
import { Menu, MenuItem } from 'components/Menu';
import { Icon } from 'components/Icon';

import { translate } from 'utils/translations';
import { IntegrationForm } from 'types/output/integration';

type Props = {
  data: { id: number; title: string }[];
  isLoading: boolean;
  onDelete: (id: number | undefined) => void;
  onEdit: (id: number | undefined) => void;
  onClick: (id: number) => void;
};

const List: React.FC<Props> = ({
  data,
  isLoading,
  onDelete,
  onEdit,
  onClick,
}) => {
  const columns = [
    {
      Header: translate('Name'),
      accessor: 'title',
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      width: 50,
      Cell: ({ value }: { value: number }) => (
        <Menu
          handle={({ toggleMenu }) => (
            <div
              onClick={toggleMenu}
              className="flex items-center cursor-pointer"
            >
              <Icon
                name="more_horiz"
                className="text-gray-600 cursor-pointer"
                size={6}
              />
            </div>
          )}
        >
          <MenuItem icon="edit" onClick={() => onEdit(value)}>
            {translate('Edit')}
          </MenuItem>
          <MenuItem icon="remove_circle" onClick={() => onDelete(value)}>
            {translate('Delete')}
          </MenuItem>
        </Menu>
      ),
    },
  ];

  const handleClick = ({ row, column }: Cell<object, any>) => {
    if (column.id === 'id') return;

    const original = row.original as IntegrationForm;
    onClick(original.id);
  };

  return (
    <TableWithPagination
      columns={columns}
      data={data}
      isLoading={isLoading}
      onClick={handleClick}
    />
  );
};

export default List;
