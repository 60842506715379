import React, { useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { RouteTabs, Tab } from 'components/Tabs';
import List from 'components/Integration/List';
import Modal from 'components/Integration/Modal';
import { Message } from 'components/Message';
import Settings from 'components/Integration/Integration/Settings';

import Group from './Group';
import Delay from '../Delay';
import Breadcrumb from './Breadcrumb';

import { URL_API_INTEGRATION } from '@constants/integration';
import { URL_API_WORKFLOW, URL_WORKFLOW_DETAIL } from '@constants/workflow';

import { WorkflowForm } from 'types/output/workflow';
import { WorkflowResponseApiType } from 'types/api/workflow';
import { IntegrationResponseApiType } from 'types/api/integration';

import { mutation } from 'utils';
import { contentFilter } from 'utils/search';
import { translate } from 'utils/translations';
import { createWorkflow } from 'utils/integration/workflow';
import { useRequest } from 'hooks/useRequest';
import FieldsBuilder from '../FieldsBuilder';

type Props = {
  searchInput: string;
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: (value: boolean) => void;
};

const Integration: React.FC<Props> = ({
  searchInput,
  isCreateModalOpen,
  setIsCreateModalOpen,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { id }: { id: string } = useParams();
  const [editModalId, setEditModalId] = useState<number | null>(null);

  const [
    integration,
    { refresh: refreshIntegration, isLoading: isLoadingIntegration },
  ] = useRequest<IntegrationResponseApiType>(
    `${URL_API_INTEGRATION}/${id}`,
    null,
  );
  const [
    workflowsData,
    { refresh: refreshWorkflows, isLoading: isLoadingWorkflows },
  ] = useRequest<WorkflowResponseApiType[]>(
    `${URL_API_INTEGRATION}/${id}/workflows`,
    [],
  );

  const handleClick = (wid: number) =>
    history.push(generatePath(URL_WORKFLOW_DETAIL, { id, wid }));

  const workflows = workflowsData.map(createWorkflow);

  if (isLoadingIntegration) {
    return <>{translate('Loading integration...')}</>;
  }

  if (!integration && !isLoadingIntegration) {
    return (
      <Message.Error>
        {translate('The integration was not found.')}
      </Message.Error>
    );
  }

  const workflowTab = (
    <Tab name="workflow" heading={translate('Workflows')} className="py-8">
      <List
        data={contentFilter(workflows, searchInput)}
        isLoading={isLoadingWorkflows}
        onDelete={(wid) =>
          mutation({
            url: `${URL_API_WORKFLOW}/${wid}`,
            method: 'DELETE',
            refresh: refreshWorkflows,
            message: 'The workflow has been deleted',
          })
        }
        onEdit={(id) => setEditModalId(id)}
        onClick={handleClick}
      />
    </Tab>
  );

  const settingsTab = (
    <Tab name="settings" heading={translate('Settings')} className="py-8">
      <Delay>
        <Settings
          onSubmit={(settings) =>
            mutation({
              url: `${URL_API_INTEGRATION}/${id}`,
              method: 'PUT',
              payload: { settings },
              refresh: refreshIntegration,
              message: 'The integration has been edited',
            })
          }
          value={integration?.settings}
        />
      </Delay>
    </Tab>
  );

  const groupTab = (
    <Tab name="group" heading={translate('Groups')} className="py-8">
      <Delay>
        <Group
          onSubmit={(groups) =>
            mutation({
              url: `${URL_API_INTEGRATION}/${id}`,
              method: 'PUT',
              payload: { groups },
              refresh: refreshIntegration,
              message: 'The integration has been edited',
            })
          }
          value={integration?.groups}
        />
      </Delay>
    </Tab>
  );

  const userFieldsTab = (
    <Tab name="user_fields" heading={translate('User fields')} className="py-8">
      <Delay>
        <FieldsBuilder
          onSubmit={(user_fields) =>
            mutation({
              url: `${URL_API_INTEGRATION}/${id}`,
              method: 'PUT',
              payload: { user_fields },
              refresh: refreshIntegration,
              message: 'The integration has been edited',
            })
          }
          value={integration?.user_fields}
        />
      </Delay>
    </Tab>
  );

  const supplierFieldsTab = (
    <Tab
      name="supplier_fields"
      heading={translate('Supplier fields')}
      className="py-8"
    >
      <Delay>
        <FieldsBuilder
          onSubmit={(supplier_fields) =>
            mutation({
              url: `${URL_API_INTEGRATION}/${id}`,
              method: 'PUT',
              payload: { supplier_fields },
              refresh: refreshIntegration,
              message: 'The integration has been edited',
            })
          }
          value={integration?.supplier_fields}
        />
      </Delay>
    </Tab>
  );

  const supplierPocFieldsTab = (
    <Tab
      name="supplier_poc_fields"
      heading={translate('Supplier POC fields')}
      className="py-8"
    >
      <Delay>
        <FieldsBuilder
          onSubmit={(supplier_poc_fields) =>
            mutation({
              url: `${URL_API_INTEGRATION}/${id}`,
              method: 'PUT',
              payload: { supplier_poc_fields },
              refresh: refreshIntegration,
              message: 'The integration has been edited',
            })
          }
          value={integration?.supplier_poc_fields}
        />
      </Delay>
    </Tab>
  );

  return (
    <>
      <Breadcrumb integration={integration} />
      <RouteTabs match={`${url}/:tab`}>
        {integration?.is_legacy
          ? [workflowTab, settingsTab].map((tab) => tab)
          : [
              workflowTab,
              settingsTab,
              groupTab,
              userFieldsTab,
              supplierFieldsTab,
              supplierPocFieldsTab,
            ].map((tab) => tab)}
      </RouteTabs>
      <Modal<WorkflowForm>
        title="Workflow"
        isOpen={isCreateModalOpen}
        onSubmit={(values) =>
          mutation({
            url: URL_API_WORKFLOW,
            method: 'POST',
            payload: {
              ...values,
              integration_id: id,
            },
            refresh: refreshWorkflows,
            message: 'The workflow has been created',
          })
        }
        onClose={() => setIsCreateModalOpen(false)}
      />
      <Modal<WorkflowForm>
        isEditMode
        isOpen={Boolean(editModalId)}
        title="Workflow"
        data={workflows.find((workflow) => workflow.id === editModalId)}
        onSubmit={(payload) =>
          mutation({
            url: `${URL_API_WORKFLOW}/${payload.id}`,
            payload,
            method: 'PUT',
            refresh: refreshWorkflows,
            message: 'The workflow has been edited',
          })
        }
        onDelete={(id) => {
          mutation({
            url: `${URL_API_WORKFLOW}/${id}`,
            method: 'DELETE',
            refresh: refreshWorkflows,
            message: 'The workflow has been deleted',
          });
        }}
        onClose={() => setEditModalId(null)}
      />
    </>
  );
};

export default Integration;
