import React from 'react';
import { Document } from '@contractool/schema';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Icons } from '../Icon';
import { sign } from '../../utils/auth';
import { PreviewIcon } from 'components/documents/PreviewIcon';
import { Checkbox } from 'components/Checkbox';

export function DocumentComponent({
  document,
  see = true,
  deletable = true,
  download = true,
  change = true,
  edit = false,
  editChildren,
  values = [],
  enableSelection,
  onDelete,
  onSelect,
}: {
  document: Document;
  see?: boolean;
  deletable?: boolean;
  download?: boolean;
  change?: boolean;
  onDelete: (document: Document) => void;
  edit?: boolean;
  editChildren?: React.ReactChild;
  values?: string[];
  enableSelection?: boolean;
  onSelect?: (document: Document) => void;
}) {
  const location = useLocation();
  let icon: Icons;
  switch (document.icon) {
    case 'file-alt':
    case 'file-archive':
    case 'file-audio':
    case 'file-excel':
    case 'file-image':
    case 'file-pdf':
    case 'file-powerpoint':
    case 'file-video':
    case 'file-word':
      icon = document.icon;
      break;
    default:
      icon = 'description';
  }

  return (
    <div className="border-b border-gray-100 py-7 flex" key={document.id}>
      {enableSelection && (
        <div className="pr-3">
          <Checkbox
            name="selectAttachment"
            value={String(document.id)}
            selectedValues={values}
            onChange={() => {
              if (onSelect) {
                onSelect(document);
              }
            }}
          />
        </div>
      )}
      {change ? (
        <Link
          to={`${location.pathname}/documents/${document.id}`}
          className="flex"
        >
          <Icon name={icon} size={6} />
          <div className="leading-tighter pl-4 text-gray-700">
            {document.title}
          </div>
        </Link>
      ) : (
        <div className="flex">
          <Icon name={icon} size={6} />
          <div className="leading-tighter pl-4 text-gray-700">
            {document.title}
          </div>
        </div>
      )}
      <div className="grow flex justify-end">
        {edit && editChildren && <>{editChildren}</>}
        {see && (
          <PreviewIcon
            fileUrl={document.preview_url}
            modalPreview={document.modal_preview}
            type={document.type}
			documentId={document.id}
          />
        )}
        {download && (
          <a href={sign(document.download_url)} download>
            <Icon name="get_app" size={6} className="ml-3 text-gray-400" />
          </a>
        )}
        {deletable && (
          <span
            onClick={() => onDelete(document)}
            className="cursor-pointer focus:outline-none"
          >
            <Icon name="delete" size={6} className="ml-3 text-gray-400" />
          </span>
        )}
      </div>
    </div>
  );
}
