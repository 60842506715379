import Datagrid from 'formiojs/components/datagrid/DataGrid';
import config from './config';

class PropertyList extends Datagrid {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'List vlastníctva',
      icon: 'file',
      group: 'custom',
      documentation: '',
      weight: 0,
      schema: PropertyList.schema(),
    };
  }

  /**
   * This is the default schema of your custom component. It will "derive"
   * from the base class "schema" and extend it with its default JSON schema
   * properties. The most important are "type" which will be your component
   * type when defining new components.
   *
   * @param extend - This allows classes deriving from this component to
   *                 override the schema of the overridden class.
   */

  static schema() {
    return Datagrid.schema({
      type: 'propertyList',
      label: 'List Vlastníctva',
      key: 'propertyList',

      components: [
        {
          label: 'List vlastníctva',
          customClass: '',
          hidden: false,
          hideLabel: false,
          dataGridLabel: false,
          disabled: false,
          modalEdit: false,
          key: 'listVlastnictva',
          tags: [],
          properties: {},
          conditional: { show: null, when: null, eq: '', json: '' },
          customConditional: '',
          logic: [],
          attributes: {},
          overlay: {
            style: '',
            page: '',
            left: '',
            top: '',
            width: '',
            height: '',
          },
          type: 'well',
          input: false,
          placeholder: '',
          prefix: '',
          suffix: '',
          multiple: false,
          defaultValue: null,
          protected: false,
          unique: false,
          persistent: false,
          clearOnHide: true,
          refreshOn: '',
          redrawOn: '',
          tableView: false,
          labelPosition: 'top',
          description: '',
          errorLabel: '',
          tooltip: '',
          tabindex: '',
          autofocus: false,
          dbIndex: false,
          customDefaultValue: '',
          calculateValue: '',
          calculateServer: false,
          widget: null,
          validateOn: 'change',
          validate: {
            required: false,
            custom: '',
            customPrivate: false,
            strictDateValidation: false,
            multiple: false,
            unique: false,
          },
          allowCalculateOverride: false,
          encrypted: false,
          showCharCount: false,
          showWordCount: false,
          allowMultipleMasks: false,
          addons: [],
          tree: false,
          lazyLoad: false,
          components: [
            {
              key: 'propertyListUpload',
              type: 'propertyListUpload',
              input: true,
              label: 'Nahrajte list vlastníctva',
              prefix: null,
              suffix: null,
              overlay: {
                top: null,
                left: null,
                style: null,
                width: null,
                height: null,
              },
              tooltip: null,
              redrawOn: null,
              tabindex: null,
              validate: {
                custom: null,
                required: false,
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              refreshOn: null,
              tableView: false,
              errorLabel: null,
              conditional: { eq: null, show: null, when: null },
              customClass: null,
              description: null,
              placeholder: null,
              calculateValue: null,
              customDefaultValue: null,
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              modalEdit: false,
              dataGridLabel: false,
              labelPosition: 'top',
              hideLabel: false,
              disabled: false,
              autofocus: false,
              dbIndex: false,
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              addons: [],
              id: 'eqje8900000',
            },
            {
              key: 'lv',
              type: 'well',
              input: false,
              label: 'LV+',
              prefix: null,
              suffix: null,
              overlay: {
                top: null,
                left: null,
                page: null,
                style: null,
                width: null,
                height: null,
              },
              tooltip: null,
              redrawOn: null,
              tabindex: null,
              validate: {
                custom: null,
                required: false,
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              refreshOn: null,
              tableView: false,
              components: [
                {
                  key: 'columns1',
                  type: 'columns',
                  input: false,
                  label: 'Columns',
                  prefix: null,
                  suffix: null,
                  columns: [
                    {
                      pull: 0,
                      push: 0,
                      size: 'md',
                      width: 3,
                      offset: 0,
                      components: [
                        {
                          key: 'cisloLv',
                          case: null,
                          type: 'textfield',
                          input: true,
                          label: 'Číslo LV',
                          errors: null,
                          prefix: null,
                          suffix: null,
                          overlay: {
                            top: null,
                            left: null,
                            page: null,
                            style: null,
                            width: null,
                            height: null,
                          },
                          tooltip: null,
                          redrawOn: null,
                          tabindex: null,
                          validate: {
                            json: null,
                            custom: null,
                            pattern: null,
                            maxLength: null,
                            minLength: null,
                            customMessage: null,
                            required: false,
                            customPrivate: false,
                            strictDateValidation: false,
                            multiple: false,
                            unique: false,
                          },
                          inputMask: null,
                          refreshOn: null,
                          tableView: true,
                          errorLabel: null,
                          conditional: {
                            eq: null,
                            json: null,
                            show: null,
                            when: null,
                          },
                          customClass: null,
                          description: null,
                          displayMask: null,
                          placeholder: null,
                          autocomplete: null,
                          calculateValue: null,
                          customConditional: null,
                          customDefaultValue: null,
                          multiple: false,
                          defaultValue: null,
                          protected: false,
                          unique: false,
                          persistent: true,
                          hidden: false,
                          clearOnHide: true,
                          modalEdit: false,
                          dataGridLabel: false,
                          labelPosition: 'top',
                          hideLabel: false,
                          disabled: false,
                          autofocus: false,
                          dbIndex: false,
                          calculateServer: false,
                          widget: { type: 'input' },
                          attributes: {},
                          validateOn: 'change',
                          allowCalculateOverride: false,
                          encrypted: false,
                          showCharCount: false,
                          showWordCount: false,
                          properties: {},
                          allowMultipleMasks: false,
                          addons: [],
                          mask: false,
                          inputType: 'text',
                          inputFormat: 'plain',
                          spellcheck: true,
                          truncateMultipleSpaces: false,
                          id: 'ea7uux',
                        },
                      ],
                      currentWidth: 3,
                    },
                    {
                      pull: 0,
                      push: 0,
                      size: 'md',
                      width: 3,
                      offset: 0,
                      components: [
                        {
                          key: 'obec',
                          case: null,
                          type: 'textfield',
                          input: true,
                          label: 'Obec',
                          errors: null,
                          prefix: null,
                          suffix: null,
                          overlay: {
                            top: null,
                            left: null,
                            page: null,
                            style: null,
                            width: null,
                            height: null,
                          },
                          tooltip: null,
                          redrawOn: null,
                          tabindex: null,
                          validate: {
                            json: null,
                            custom: null,
                            pattern: null,
                            maxLength: null,
                            minLength: null,
                            customMessage: null,
                            required: false,
                            customPrivate: false,
                            strictDateValidation: false,
                            multiple: false,
                            unique: false,
                          },
                          inputMask: null,
                          refreshOn: null,
                          tableView: true,
                          errorLabel: null,
                          conditional: {
                            eq: null,
                            json: null,
                            show: null,
                            when: null,
                          },
                          customClass: null,
                          description: null,
                          displayMask: null,
                          placeholder: null,
                          autocomplete: null,
                          calculateValue: null,
                          customConditional: null,
                          customDefaultValue: null,
                          multiple: false,
                          defaultValue: null,
                          protected: false,
                          unique: false,
                          persistent: true,
                          hidden: false,
                          clearOnHide: true,
                          modalEdit: false,
                          dataGridLabel: false,
                          labelPosition: 'top',
                          hideLabel: false,
                          disabled: false,
                          autofocus: false,
                          dbIndex: false,
                          calculateServer: false,
                          widget: { type: 'input' },
                          attributes: {},
                          validateOn: 'change',
                          allowCalculateOverride: false,
                          encrypted: false,
                          showCharCount: false,
                          showWordCount: false,
                          properties: {},
                          allowMultipleMasks: false,
                          addons: [],
                          mask: false,
                          inputType: 'text',
                          inputFormat: 'plain',
                          spellcheck: true,
                          truncateMultipleSpaces: false,
                          id: 'ezyh3up',
                        },
                      ],
                      currentWidth: 3,
                    },
                    {
                      pull: 0,
                      push: 0,
                      size: 'md',
                      width: 3,
                      offset: 0,
                      components: [
                        {
                          key: 'katastralneUzemie',
                          case: null,
                          type: 'textfield',
                          input: true,
                          label: 'Katastralne uzemie',
                          errors: null,
                          prefix: null,
                          suffix: null,
                          overlay: {
                            top: null,
                            left: null,
                            page: null,
                            style: null,
                            width: null,
                            height: null,
                          },
                          tooltip: null,
                          redrawOn: null,
                          tabindex: null,
                          validate: {
                            json: null,
                            custom: null,
                            pattern: null,
                            maxLength: null,
                            minLength: null,
                            customMessage: null,
                            required: false,
                            customPrivate: false,
                            strictDateValidation: false,
                            multiple: false,
                            unique: false,
                          },
                          inputMask: null,
                          refreshOn: null,
                          tableView: true,
                          errorLabel: null,
                          conditional: {
                            eq: null,
                            json: null,
                            show: null,
                            when: null,
                          },
                          customClass: null,
                          description: null,
                          displayMask: null,
                          placeholder: null,
                          autocomplete: null,
                          calculateValue: null,
                          customConditional: null,
                          customDefaultValue: null,
                          multiple: false,
                          defaultValue: null,
                          protected: false,
                          unique: false,
                          persistent: true,
                          hidden: false,
                          clearOnHide: true,
                          modalEdit: false,
                          dataGridLabel: false,
                          labelPosition: 'top',
                          hideLabel: false,
                          disabled: false,
                          autofocus: false,
                          dbIndex: false,
                          calculateServer: false,
                          widget: { type: 'input' },
                          attributes: {},
                          validateOn: 'change',
                          allowCalculateOverride: false,
                          encrypted: false,
                          showCharCount: false,
                          showWordCount: false,
                          properties: {},
                          allowMultipleMasks: false,
                          addons: [],
                          mask: false,
                          inputType: 'text',
                          inputFormat: 'plain',
                          spellcheck: true,
                          truncateMultipleSpaces: false,
                          id: 'evyudb',
                        },
                      ],
                      currentWidth: 3,
                    },
                    {
                      pull: 0,
                      push: 0,
                      size: 'md',
                      width: 3,
                      offset: 0,
                      components: [
                        {
                          key: 'okres',
                          case: null,
                          type: 'textfield',
                          input: true,
                          label: 'Okres',
                          errors: null,
                          prefix: null,
                          suffix: null,
                          overlay: {
                            top: null,
                            left: null,
                            page: null,
                            style: null,
                            width: null,
                            height: null,
                          },
                          tooltip: null,
                          redrawOn: null,
                          tabindex: null,
                          validate: {
                            json: null,
                            custom: null,
                            pattern: null,
                            maxLength: null,
                            minLength: null,
                            customMessage: null,
                            required: false,
                            customPrivate: false,
                            strictDateValidation: false,
                            multiple: false,
                            unique: false,
                          },
                          inputMask: null,
                          refreshOn: null,
                          tableView: true,
                          errorLabel: null,
                          conditional: {
                            eq: null,
                            json: null,
                            show: null,
                            when: null,
                          },
                          customClass: null,
                          description: null,
                          displayMask: null,
                          placeholder: null,
                          autocomplete: null,
                          calculateValue: null,
                          customConditional: null,
                          customDefaultValue: null,
                          multiple: false,
                          defaultValue: null,
                          protected: false,
                          unique: false,
                          persistent: true,
                          hidden: false,
                          clearOnHide: true,
                          modalEdit: false,
                          dataGridLabel: false,
                          labelPosition: 'top',
                          hideLabel: false,
                          disabled: false,
                          autofocus: false,
                          dbIndex: false,
                          calculateServer: false,
                          widget: { type: 'input' },
                          attributes: {},
                          validateOn: 'change',
                          allowCalculateOverride: false,
                          encrypted: false,
                          showCharCount: false,
                          showWordCount: false,
                          properties: {},
                          allowMultipleMasks: false,
                          addons: [],
                          mask: false,
                          inputType: 'text',
                          inputFormat: 'plain',
                          spellcheck: true,
                          truncateMultipleSpaces: false,
                          id: 'e5z9mvn',
                        },
                      ],
                      currentWidth: 3,
                    },
                  ],
                  overlay: {
                    top: null,
                    left: null,
                    page: null,
                    style: null,
                    width: null,
                    height: null,
                  },
                  tooltip: null,
                  redrawOn: null,
                  tabindex: null,
                  validate: {
                    custom: null,
                    required: false,
                    customPrivate: false,
                    strictDateValidation: false,
                    multiple: false,
                    unique: false,
                  },
                  refreshOn: null,
                  tableView: false,
                  errorLabel: null,
                  conditional: {
                    eq: null,
                    json: null,
                    show: null,
                    when: null,
                  },
                  customClass: null,
                  description: null,
                  placeholder: null,
                  calculateValue: null,
                  customConditional: null,
                  customDefaultValue: null,
                  multiple: false,
                  defaultValue: null,
                  protected: false,
                  unique: false,
                  persistent: false,
                  hidden: false,
                  clearOnHide: false,
                  modalEdit: false,
                  dataGridLabel: false,
                  labelPosition: 'top',
                  hideLabel: false,
                  disabled: false,
                  autofocus: false,
                  dbIndex: false,
                  calculateServer: false,
                  widget: null,
                  attributes: {},
                  validateOn: 'change',
                  allowCalculateOverride: false,
                  encrypted: false,
                  showCharCount: false,
                  showWordCount: false,
                  properties: {},
                  allowMultipleMasks: false,
                  addons: [],
                  tree: false,
                  lazyLoad: false,
                  autoAdjust: false,
                  id: 'eyfsddn',
                },
                {
                  title: 'Detaily',
                  theme: 'default',
                  tooltip: '',
                  customClass: '',
                  collapsible: true,
                  hidden: false,
                  hideLabel: false,
                  disabled: false,
                  modalEdit: false,
                  key: 'panel',
                  tags: [],
                  properties: {},
                  customConditional: '',
                  conditional: { json: '', show: null, when: null, eq: '' },
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: '',
                  },
                  type: 'panel',
                  label: 'Panel',
                  breadcrumb: 'default',
                  tabindex: '',
                  input: false,
                  tableView: false,
                  components: [
                    {
                      key: 'columns',
                      type: 'columns',
                      input: false,
                      label: 'Columns',
                      prefix: null,
                      suffix: null,
                      columns: [
                        {
                          pull: 0,
                          push: 0,
                          size: 'md',
                          width: 6,
                          offset: 0,
                          components: [
                            {
                              key: 'fieldSet',
                              type: 'fieldset',
                              input: false,
                              label: 'Field Set',
                              legend: 'Časť A:',
                              prefix: null,
                              suffix: null,
                              overlay: {
                                top: null,
                                left: null,
                                page: null,
                                style: null,
                                width: null,
                                height: null,
                              },
                              tooltip: null,
                              redrawOn: null,
                              tabindex: null,
                              validate: {
                                custom: null,
                                required: false,
                                customPrivate: false,
                                strictDateValidation: false,
                                multiple: false,
                                unique: false,
                              },
                              refreshOn: null,
                              tableView: false,
                              components: [
                                {
                                  key: 'stavba',
                                  type: 'datagrid',
                                  input: true,
                                  label: 'Stavba',
                                  errors: null,
                                  prefix: null,
                                  suffix: null,
                                  overlay: {
                                    top: null,
                                    left: null,
                                    page: null,
                                    style: null,
                                    width: null,
                                    height: null,
                                  },
                                  reorder: false,
                                  tooltip: null,
                                  redrawOn: null,
                                  tabindex: null,
                                  validate: {
                                    json: null,
                                    custom: null,
                                    maxLength: null,
                                    minLength: null,
                                    customMessage: null,
                                    required: false,
                                    customPrivate: false,
                                    strictDateValidation: false,
                                    multiple: false,
                                    unique: false,
                                  },
                                  hideLabel: true,
                                  initEmpty: false,
                                  modalEdit: true,
                                  refreshOn: null,
                                  tableView: false,
                                  addAnother: null,
                                  components: [
                                    {
                                      key: 'supisneCislo1',
                                      case: null,
                                      type: 'textfield',
                                      input: true,
                                      label: 'Súpisné číslo',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        pattern: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      inputMask: null,
                                      refreshOn: null,
                                      tableView: true,
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      displayMask: null,
                                      placeholder: null,
                                      autocomplete: null,
                                      calculateValue: null,
                                      customConditional: null,
                                      customDefaultValue: null,
                                      id: 'eadusb',
                                      multiple: false,
                                      defaultValue: null,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      modalEdit: false,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: { type: 'input' },
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputFormat: 'plain',
                                      spellcheck: true,
                                      truncateMultipleSpaces: false,
                                    },
                                    {
                                      key: 'naPozemkuParcelneCislo',
                                      case: null,
                                      type: 'textfield',
                                      input: true,
                                      label: 'Na pozemku parcelné číslo',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        pattern: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      inputMask: null,
                                      refreshOn: null,
                                      tableView: true,
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      displayMask: null,
                                      placeholder: null,
                                      autocomplete: null,
                                      calculateValue: null,
                                      customConditional: null,
                                      customDefaultValue: null,
                                      id: 'e3kpd3',
                                      multiple: false,
                                      defaultValue: null,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      modalEdit: false,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: { type: 'input' },
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputFormat: 'plain',
                                      spellcheck: true,
                                      truncateMultipleSpaces: false,
                                    },
                                    {
                                      key: 'popisStavby',
                                      case: null,
                                      type: 'textfield',
                                      input: true,
                                      label: 'Popis stavby',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        pattern: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      inputMask: null,
                                      refreshOn: null,
                                      tableView: true,
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      displayMask: null,
                                      placeholder: null,
                                      autocomplete: null,
                                      calculateValue: null,
                                      customConditional: null,
                                      customDefaultValue: null,
                                      id: 'e7na0mk',
                                      multiple: false,
                                      defaultValue: null,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      modalEdit: false,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: { type: 'input' },
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputFormat: 'plain',
                                      spellcheck: true,
                                      truncateMultipleSpaces: false,
                                    },
                                  ],
                                  errorLabel: null,
                                  conditional: {
                                    eq: null,
                                    json: null,
                                    show: null,
                                    when: null,
                                  },
                                  customClass: 'every-three-row',
                                  description: null,
                                  layoutFixed: false,
                                  placeholder: null,
                                  defaultValue: null,
                                  calculateValue: null,
                                  enableRowGroups: false,
                                  customConditional: null,
                                  addAnotherPosition: 'bottom',
                                  customDefaultValue: null,
                                  conditionalAddButton: null,
                                  multiple: false,
                                  protected: false,
                                  unique: false,
                                  persistent: true,
                                  hidden: false,
                                  clearOnHide: true,
                                  dataGridLabel: false,
                                  labelPosition: 'top',
                                  disabled: false,
                                  autofocus: false,
                                  dbIndex: false,
                                  calculateServer: false,
                                  widget: null,
                                  attributes: {},
                                  validateOn: 'change',
                                  allowCalculateOverride: false,
                                  encrypted: false,
                                  showCharCount: false,
                                  showWordCount: false,
                                  properties: {},
                                  allowMultipleMasks: false,
                                  addons: [],
                                  tree: true,
                                  lazyLoad: false,
                                  disableAddingRemovingRows: false,
                                  id: 'eek241s',
                                },
                                {
                                  key: 'parcela',
                                  type: 'datagrid',
                                  input: true,
                                  label: 'Parcela',
                                  errors: null,
                                  prefix: null,
                                  suffix: null,
                                  overlay: {
                                    top: null,
                                    left: null,
                                    page: null,
                                    style: null,
                                    width: null,
                                    height: null,
                                  },
                                  reorder: false,
                                  tooltip: null,
                                  redrawOn: null,
                                  tabindex: null,
                                  validate: {
                                    json: null,
                                    custom: null,
                                    maxLength: null,
                                    minLength: null,
                                    customMessage: null,
                                    required: false,
                                    customPrivate: false,
                                    strictDateValidation: false,
                                    multiple: false,
                                    unique: false,
                                  },
                                  initEmpty: false,
                                  modalEdit: true,
                                  refreshOn: null,
                                  tableView: false,
                                  addAnother: null,
                                  components: [
                                    {
                                      key: 'parcelneCislo',
                                      case: null,
                                      type: 'textfield',
                                      input: true,
                                      label: 'Parcelné číslo',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        pattern: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      inputMask: null,
                                      refreshOn: null,
                                      tableView: true,
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      displayMask: null,
                                      placeholder: null,
                                      autocomplete: null,
                                      calculateValue: null,
                                      customConditional: null,
                                      customDefaultValue: null,
                                      id: 'ecasi59',
                                      multiple: false,
                                      defaultValue: null,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      modalEdit: false,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: { type: 'input' },
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputFormat: 'plain',
                                      spellcheck: true,
                                      truncateMultipleSpaces: false,
                                    },
                                    {
                                      key: 'vymera',
                                      case: null,
                                      type: 'textfield',
                                      input: true,
                                      label: 'Výmera',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        pattern: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      inputMask: null,
                                      refreshOn: null,
                                      tableView: true,
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      displayMask: null,
                                      placeholder: null,
                                      autocomplete: null,
                                      calculateValue: null,
                                      customConditional: null,
                                      customDefaultValue: null,
                                      id: 'eu1521o',
                                      multiple: false,
                                      defaultValue: null,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      modalEdit: false,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: { type: 'input' },
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputFormat: 'plain',
                                      spellcheck: true,
                                      truncateMultipleSpaces: false,
                                    },
                                    {
                                      key: 'typPozemku',
                                      case: null,
                                      type: 'textfield',
                                      input: true,
                                      label: 'Typ pozemku',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        pattern: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      inputMask: null,
                                      refreshOn: null,
                                      tableView: true,
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      displayMask: null,
                                      placeholder: null,
                                      autocomplete: null,
                                      calculateValue: null,
                                      customConditional: null,
                                      customDefaultValue: null,
                                      id: 'eg6jogd',
                                      multiple: false,
                                      defaultValue: null,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      modalEdit: false,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: { type: 'input' },
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputFormat: 'plain',
                                      spellcheck: true,
                                      truncateMultipleSpaces: false,
                                    },
                                  ],
                                  errorLabel: null,
                                  conditional: {
                                    eq: null,
                                    json: null,
                                    show: null,
                                    when: null,
                                  },
                                  customClass: null,
                                  description: null,
                                  layoutFixed: false,
                                  placeholder: null,
                                  defaultValue: null,
                                  calculateValue: null,
                                  enableRowGroups: false,
                                  customConditional: null,
                                  addAnotherPosition: 'bottom',
                                  customDefaultValue: null,
                                  conditionalAddButton: null,
                                  multiple: false,
                                  protected: false,
                                  unique: false,
                                  persistent: true,
                                  hidden: false,
                                  clearOnHide: true,
                                  dataGridLabel: false,
                                  labelPosition: 'top',
                                  hideLabel: false,
                                  disabled: false,
                                  autofocus: false,
                                  dbIndex: false,
                                  calculateServer: false,
                                  widget: null,
                                  attributes: {},
                                  validateOn: 'change',
                                  allowCalculateOverride: false,
                                  encrypted: false,
                                  showCharCount: false,
                                  showWordCount: false,
                                  properties: {},
                                  allowMultipleMasks: false,
                                  addons: [],
                                  tree: true,
                                  lazyLoad: false,
                                  disableAddingRemovingRows: false,
                                  id: 'ec7bzxo',
                                },
                              ],
                              errorLabel: null,
                              conditional: {
                                eq: null,
                                json: null,
                                show: null,
                                when: null,
                              },
                              customClass: 'three-rows-wrapper',
                              description: null,
                              placeholder: null,
                              calculateValue: null,
                              customConditional: null,
                              customDefaultValue: null,
                              multiple: false,
                              defaultValue: null,
                              protected: false,
                              unique: false,
                              persistent: false,
                              hidden: false,
                              clearOnHide: true,
                              modalEdit: false,
                              dataGridLabel: false,
                              labelPosition: 'top',
                              hideLabel: false,
                              disabled: false,
                              autofocus: false,
                              dbIndex: false,
                              calculateServer: false,
                              widget: null,
                              attributes: {},
                              validateOn: 'change',
                              allowCalculateOverride: false,
                              encrypted: false,
                              showCharCount: false,
                              showWordCount: false,
                              properties: {},
                              allowMultipleMasks: false,
                              addons: [],
                              tree: false,
                              lazyLoad: false,
                              id: 'ek86wj',
                            },
                          ],
                          currentWidth: 6,
                        },
                        {
                          pull: 0,
                          push: 0,
                          size: 'md',
                          width: 6,
                          offset: 0,
                          components: [
                            {
                              key: 'castBAC',
                              type: 'well',
                              input: false,
                              label: 'cast B a C',
                              prefix: null,
                              suffix: null,
                              overlay: {
                                top: null,
                                left: null,
                                page: null,
                                style: null,
                                width: null,
                                height: null,
                              },
                              tooltip: null,
                              redrawOn: null,
                              tabindex: null,
                              validate: {
                                custom: null,
                                required: false,
                                customPrivate: false,
                                strictDateValidation: false,
                                multiple: false,
                                unique: false,
                              },
                              hideLabel: true,
                              refreshOn: null,
                              tableView: false,
                              components: [
                                {
                                  key: 'fieldSet1',
                                  type: 'fieldset',
                                  input: false,
                                  label: 'Field Set',
                                  legend: 'Časť B:',
                                  prefix: null,
                                  suffix: null,
                                  overlay: {
                                    top: null,
                                    left: null,
                                    page: null,
                                    style: null,
                                    width: null,
                                    height: null,
                                  },
                                  tooltip: null,
                                  redrawOn: null,
                                  tabindex: null,
                                  validate: {
                                    custom: null,
                                    required: false,
                                    customPrivate: false,
                                    strictDateValidation: false,
                                    multiple: false,
                                    unique: false,
                                  },
                                  refreshOn: null,
                                  tableView: false,
                                  components: [
                                    {
                                      label: 'Vlastníci',
                                      labelPosition: 'top',
                                      placeholder: '',
                                      description: '',
                                      tooltip: '',
                                      prefix: '',
                                      suffix: '',
                                      widget: { type: 'input' },
                                      displayMask: '',
                                      editor: '',
                                      autoExpand: false,
                                      customClass: '',
                                      tabindex: '',
                                      autocomplete: '',
                                      hidden: false,
                                      hideLabel: false,
                                      showWordCount: false,
                                      showCharCount: false,
                                      autofocus: false,
                                      spellcheck: true,
                                      disabled: true,
                                      tableView: true,
                                      modalEdit: false,
                                      multiple: false,
                                      persistent: true,
                                      inputFormat: 'html',
                                      protected: false,
                                      dbIndex: false,
                                      case: '',
                                      truncateMultipleSpaces: false,
                                      encrypted: false,
                                      redrawOn: '',
                                      clearOnHide: true,
                                      customDefaultValue: '',
                                      calculateValue: '',
                                      calculateServer: false,
                                      allowCalculateOverride: false,
                                      validateOn: 'change',
                                      validate: {
                                        required: false,
                                        pattern: '',
                                        customMessage: '',
                                        custom: '',
                                        customPrivate: false,
                                        json: '',
                                        minLength: '',
                                        maxLength: '',
                                        minWords: '',
                                        maxWords: '',
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      unique: false,
                                      errorLabel: '',
                                      errors: '',
                                      key: 'vlastnici',
                                      tags: [],
                                      properties: {},
                                      conditional: {
                                        show: null,
                                        when: null,
                                        eq: '',
                                        json: '',
                                      },
                                      customConditional: '',
                                      logic: [],
                                      attributes: {},
                                      overlay: {
                                        style: '',
                                        page: '',
                                        left: '',
                                        top: '',
                                        width: '',
                                        height: '',
                                      },
                                      type: 'textarea',
                                      rows: 3,
                                      wysiwyg: false,
                                      input: true,
                                      refreshOn: '',
                                      dataGridLabel: false,
                                      allowMultipleMasks: false,
                                      addons: [],
                                      mask: false,
                                      inputType: 'text',
                                      inputMask: '',
                                      fixedSize: true,
                                      id: 'epfonvk',
                                      defaultValue: null,
                                    },
                                    {
                                      key: 'vlastnik',
                                      type: 'datagrid',
                                      input: true,
                                      label: 'Vlastník',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      reorder: false,
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      initEmpty: false,
                                      modalEdit: true,
                                      refreshOn: null,
                                      tableView: false,
                                      addAnother: 'Pridaj ďalší',
                                      components: [
                                        {
                                          label: 'Vlastník',
                                          labelPosition: 'top',
                                          placeholder: '',
                                          description: '',
                                          tooltip: '',
                                          prefix: '',
                                          suffix: '',
                                          widget: { type: 'input' },
                                          inputMask: '',
                                          displayMask: '',
                                          allowMultipleMasks: false,
                                          customClass: '',
                                          tabindex: '',
                                          autocomplete: '',
                                          hidden: false,
                                          hideLabel: false,
                                          showWordCount: false,
                                          showCharCount: false,
                                          mask: false,
                                          autofocus: false,
                                          spellcheck: true,
                                          dataGridLabel: false,
                                          disabled: false,
                                          tableView: true,
                                          modalEdit: false,
                                          multiple: false,
                                          persistent: true,
                                          inputFormat: 'plain',
                                          protected: false,
                                          dbIndex: false,
                                          case: '',
                                          truncateMultipleSpaces: false,
                                          encrypted: false,
                                          redrawOn: '',
                                          clearOnHide: true,
                                          customDefaultValue: '',
                                          calculateValue: '',
                                          calculateServer: false,
                                          allowCalculateOverride: false,
                                          validateOn: 'change',
                                          validate: {
                                            required: false,
                                            pattern: '',
                                            customMessage: '',
                                            custom: '',
                                            customPrivate: false,
                                            json: '',
                                            minLength: '',
                                            maxLength: '',
                                            strictDateValidation: false,
                                            multiple: false,
                                            unique: false,
                                          },
                                          unique: false,
                                          errorLabel: '',
                                          errors: '',
                                          key: 'vlastnik1',
                                          tags: [],
                                          properties: {},
                                          conditional: {
                                            show: null,
                                            when: null,
                                            eq: '',
                                            json: '',
                                          },
                                          customConditional: '',
                                          logic: [],
                                          attributes: {},
                                          overlay: {
                                            style: '',
                                            page: '',
                                            left: '',
                                            top: '',
                                            width: '',
                                            height: '',
                                          },
                                          type: 'textfield',
                                          input: true,
                                          refreshOn: '',
                                          addons: [],
                                          inputType: 'text',
                                          id: 'ebx1h1000',
                                          defaultValue: '',
                                        },
                                        {
                                          key: 'spoluvlastnickyPodiel',
                                          case: null,
                                          type: 'textfield',
                                          input: true,
                                          label: 'Spoluvlastnícky podiel',
                                          errors: null,
                                          prefix: null,
                                          suffix: null,
                                          overlay: {
                                            top: null,
                                            left: null,
                                            page: null,
                                            style: null,
                                            width: null,
                                            height: null,
                                          },
                                          tooltip: null,
                                          redrawOn: null,
                                          tabindex: null,
                                          validate: {
                                            json: null,
                                            custom: null,
                                            pattern: null,
                                            maxLength: null,
                                            minLength: null,
                                            customMessage: null,
                                            required: false,
                                            customPrivate: false,
                                            strictDateValidation: false,
                                            multiple: false,
                                            unique: false,
                                          },
                                          inputMask: null,
                                          refreshOn: null,
                                          tableView: true,
                                          errorLabel: null,
                                          conditional: {
                                            eq: null,
                                            json: null,
                                            show: null,
                                            when: null,
                                          },
                                          customClass: null,
                                          description: null,
                                          displayMask: null,
                                          placeholder: null,
                                          autocomplete: null,
                                          calculateValue: null,
                                          customConditional: null,
                                          customDefaultValue: null,
                                          id: 'e5e2zdi00000000',
                                          multiple: false,
                                          defaultValue: null,
                                          protected: false,
                                          unique: false,
                                          persistent: true,
                                          hidden: false,
                                          clearOnHide: true,
                                          modalEdit: false,
                                          dataGridLabel: false,
                                          labelPosition: 'top',
                                          hideLabel: false,
                                          disabled: false,
                                          autofocus: false,
                                          dbIndex: false,
                                          calculateServer: false,
                                          widget: { type: 'input' },
                                          attributes: {},
                                          validateOn: 'change',
                                          allowCalculateOverride: false,
                                          encrypted: false,
                                          showCharCount: false,
                                          showWordCount: false,
                                          properties: {},
                                          allowMultipleMasks: false,
                                          addons: [],
                                          mask: false,
                                          inputType: 'text',
                                          inputFormat: 'plain',
                                          spellcheck: true,
                                          truncateMultipleSpaces: false,
                                        },
                                      ],
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      layoutFixed: false,
                                      placeholder: null,
                                      defaultValue: null,
                                      calculateValue: null,
                                      enableRowGroups: false,
                                      customConditional: null,
                                      addAnotherPosition: 'bottom',
                                      customDefaultValue: null,
                                      conditionalAddButton: null,
                                      multiple: false,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: null,
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      tree: true,
                                      lazyLoad: false,
                                      disableAddingRemovingRows: false,
                                      id: 'e6xvqtk',
                                    },
                                  ],
                                  errorLabel: null,
                                  conditional: {
                                    eq: null,
                                    json: null,
                                    show: null,
                                    when: null,
                                  },
                                  customClass: 'two-rows-wrapper',
                                  description: null,
                                  placeholder: null,
                                  calculateValue: null,
                                  customConditional: null,
                                  customDefaultValue: null,
                                  multiple: false,
                                  defaultValue: null,
                                  protected: false,
                                  unique: false,
                                  persistent: false,
                                  hidden: false,
                                  clearOnHide: true,
                                  modalEdit: false,
                                  dataGridLabel: false,
                                  labelPosition: 'top',
                                  hideLabel: false,
                                  disabled: false,
                                  autofocus: false,
                                  dbIndex: false,
                                  calculateServer: false,
                                  widget: null,
                                  attributes: {},
                                  validateOn: 'change',
                                  allowCalculateOverride: false,
                                  encrypted: false,
                                  showCharCount: false,
                                  showWordCount: false,
                                  properties: {},
                                  allowMultipleMasks: false,
                                  addons: [],
                                  tree: false,
                                  lazyLoad: false,
                                  id: 'ex0y3ms',
                                },
                                {
                                  key: 'fieldSet2',
                                  type: 'fieldset',
                                  input: false,
                                  label: 'Field Set',
                                  legend: 'Časť C:',
                                  prefix: null,
                                  suffix: null,
                                  overlay: {
                                    top: null,
                                    left: null,
                                    page: null,
                                    style: null,
                                    width: null,
                                    height: null,
                                  },
                                  tooltip: null,
                                  redrawOn: null,
                                  tabindex: null,
                                  validate: {
                                    custom: null,
                                    required: false,
                                    customPrivate: false,
                                    strictDateValidation: false,
                                    multiple: false,
                                    unique: false,
                                  },
                                  refreshOn: null,
                                  tableView: false,
                                  components: [
                                    {
                                      key: 'tarchy',
                                      type: 'datagrid',
                                      input: true,
                                      label: 'Ťarchy',
                                      errors: null,
                                      prefix: null,
                                      suffix: null,
                                      overlay: {
                                        top: null,
                                        left: null,
                                        page: null,
                                        style: null,
                                        width: null,
                                        height: null,
                                      },
                                      reorder: false,
                                      tooltip: null,
                                      redrawOn: null,
                                      tabindex: null,
                                      validate: {
                                        json: null,
                                        custom: null,
                                        maxLength: null,
                                        minLength: null,
                                        customMessage: null,
                                        required: false,
                                        customPrivate: false,
                                        strictDateValidation: false,
                                        multiple: false,
                                        unique: false,
                                      },
                                      initEmpty: false,
                                      modalEdit: true,
                                      refreshOn: null,
                                      tableView: false,
                                      addAnother: null,
                                      components: [
                                        {
                                          key: 'obsah',
                                          case: null,
                                          type: 'textarea',
                                          input: true,
                                          label: 'Obsah',
                                          editor: null,
                                          errors: null,
                                          prefix: null,
                                          suffix: null,
                                          overlay: {
                                            top: null,
                                            left: null,
                                            page: null,
                                            style: null,
                                            width: null,
                                            height: null,
                                          },
                                          tooltip: null,
                                          redrawOn: null,
                                          tabindex: null,
                                          validate: {
                                            json: null,
                                            custom: null,
                                            pattern: null,
                                            maxWords: null,
                                            minWords: null,
                                            maxLength: null,
                                            minLength: null,
                                            customMessage: null,
                                            required: false,
                                            customPrivate: false,
                                            strictDateValidation: false,
                                            multiple: false,
                                            unique: false,
                                          },
                                          inputMask: null,
                                          refreshOn: null,
                                          tableView: true,
                                          autoExpand: false,
                                          errorLabel: null,
                                          conditional: {
                                            eq: null,
                                            json: null,
                                            show: null,
                                            when: null,
                                          },
                                          customClass: null,
                                          description: null,
                                          displayMask: null,
                                          placeholder: null,
                                          autocomplete: null,
                                          calculateValue: null,
                                          customConditional: null,
                                          customDefaultValue: null,
                                          id: 'ea3fu2n',
                                          multiple: false,
                                          defaultValue: null,
                                          protected: false,
                                          unique: false,
                                          persistent: true,
                                          hidden: false,
                                          clearOnHide: true,
                                          modalEdit: false,
                                          dataGridLabel: false,
                                          labelPosition: 'top',
                                          hideLabel: false,
                                          disabled: false,
                                          autofocus: false,
                                          dbIndex: false,
                                          calculateServer: false,
                                          widget: { type: 'input' },
                                          attributes: {},
                                          validateOn: 'change',
                                          allowCalculateOverride: false,
                                          encrypted: false,
                                          showCharCount: false,
                                          showWordCount: false,
                                          properties: {},
                                          allowMultipleMasks: false,
                                          addons: [],
                                          mask: false,
                                          inputType: 'text',
                                          inputFormat: 'html',
                                          spellcheck: true,
                                          truncateMultipleSpaces: false,
                                          rows: 3,
                                          wysiwyg: false,
                                          fixedSize: true,
                                        },
                                      ],
                                      errorLabel: null,
                                      conditional: {
                                        eq: null,
                                        json: null,
                                        show: null,
                                        when: null,
                                      },
                                      customClass: null,
                                      description: null,
                                      layoutFixed: false,
                                      placeholder: null,
                                      defaultValue: null,
                                      calculateValue: null,
                                      enableRowGroups: false,
                                      customConditional: null,
                                      addAnotherPosition: 'bottom',
                                      customDefaultValue: null,
                                      conditionalAddButton: null,
                                      multiple: false,
                                      protected: false,
                                      unique: false,
                                      persistent: true,
                                      hidden: false,
                                      clearOnHide: true,
                                      dataGridLabel: false,
                                      labelPosition: 'top',
                                      hideLabel: false,
                                      disabled: false,
                                      autofocus: false,
                                      dbIndex: false,
                                      calculateServer: false,
                                      widget: null,
                                      attributes: {},
                                      validateOn: 'change',
                                      allowCalculateOverride: false,
                                      encrypted: false,
                                      showCharCount: false,
                                      showWordCount: false,
                                      properties: {},
                                      allowMultipleMasks: false,
                                      addons: [],
                                      tree: true,
                                      lazyLoad: false,
                                      disableAddingRemovingRows: false,
                                      id: 'e7yfl78',
                                    },
                                  ],
                                  errorLabel: null,
                                  conditional: {
                                    eq: null,
                                    json: null,
                                    show: null,
                                    when: null,
                                  },
                                  customClass: null,
                                  description: null,
                                  placeholder: null,
                                  calculateValue: null,
                                  customConditional: null,
                                  customDefaultValue: null,
                                  multiple: false,
                                  defaultValue: null,
                                  protected: false,
                                  unique: false,
                                  persistent: false,
                                  hidden: false,
                                  clearOnHide: true,
                                  modalEdit: false,
                                  dataGridLabel: false,
                                  labelPosition: 'top',
                                  hideLabel: false,
                                  disabled: false,
                                  autofocus: false,
                                  dbIndex: false,
                                  calculateServer: false,
                                  widget: null,
                                  attributes: {},
                                  validateOn: 'change',
                                  allowCalculateOverride: false,
                                  encrypted: false,
                                  showCharCount: false,
                                  showWordCount: false,
                                  properties: {},
                                  allowMultipleMasks: false,
                                  addons: [],
                                  tree: false,
                                  lazyLoad: false,
                                  id: 'e7nuo7g',
                                },
                              ],
                              errorLabel: null,
                              conditional: {
                                eq: null,
                                json: null,
                                show: null,
                                when: null,
                              },
                              customClass: null,
                              description: null,
                              placeholder: null,
                              calculateValue: null,
                              customConditional: null,
                              customDefaultValue: null,
                              multiple: false,
                              defaultValue: null,
                              protected: false,
                              unique: false,
                              persistent: false,
                              hidden: false,
                              clearOnHide: true,
                              modalEdit: false,
                              dataGridLabel: false,
                              labelPosition: 'top',
                              disabled: false,
                              autofocus: false,
                              dbIndex: false,
                              calculateServer: false,
                              widget: null,
                              attributes: {},
                              validateOn: 'change',
                              allowCalculateOverride: false,
                              encrypted: false,
                              showCharCount: false,
                              showWordCount: false,
                              properties: {},
                              allowMultipleMasks: false,
                              addons: [],
                              tree: false,
                              lazyLoad: false,
                              id: 'euk71z8',
                            },
                          ],
                          currentWidth: 6,
                        },
                      ],
                      overlay: {
                        top: null,
                        left: null,
                        page: null,
                        style: null,
                        width: null,
                        height: null,
                      },
                      tooltip: null,
                      redrawOn: null,
                      tabindex: null,
                      validate: {
                        custom: null,
                        required: false,
                        customPrivate: false,
                        strictDateValidation: false,
                        multiple: false,
                        unique: false,
                      },
                      refreshOn: null,
                      tableView: false,
                      errorLabel: null,
                      conditional: {
                        eq: null,
                        json: null,
                        show: null,
                        when: null,
                      },
                      customClass: null,
                      description: null,
                      placeholder: null,
                      calculateValue: null,
                      customConditional: null,
                      customDefaultValue: null,
                      multiple: false,
                      defaultValue: null,
                      protected: false,
                      unique: false,
                      persistent: false,
                      hidden: false,
                      clearOnHide: false,
                      modalEdit: false,
                      dataGridLabel: false,
                      labelPosition: 'top',
                      hideLabel: false,
                      disabled: false,
                      autofocus: false,
                      dbIndex: false,
                      calculateServer: false,
                      widget: null,
                      attributes: {},
                      validateOn: 'change',
                      allowCalculateOverride: false,
                      encrypted: false,
                      showCharCount: false,
                      showWordCount: false,
                      properties: {},
                      allowMultipleMasks: false,
                      addons: [],
                      tree: false,
                      lazyLoad: false,
                      autoAdjust: false,
                      id: 'ecjkt3',
                    },
                  ],
                  placeholder: '',
                  prefix: '',
                  suffix: '',
                  multiple: false,
                  defaultValue: null,
                  protected: false,
                  unique: false,
                  persistent: false,
                  clearOnHide: false,
                  refreshOn: '',
                  redrawOn: '',
                  dataGridLabel: false,
                  labelPosition: 'top',
                  description: '',
                  errorLabel: '',
                  autofocus: false,
                  dbIndex: false,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  widget: null,
                  validateOn: 'change',
                  validate: {
                    required: false,
                    custom: '',
                    customPrivate: false,
                    strictDateValidation: false,
                    multiple: false,
                    unique: false,
                  },
                  allowCalculateOverride: false,
                  encrypted: false,
                  showCharCount: false,
                  showWordCount: false,
                  allowMultipleMasks: false,
                  addons: [],
                  tree: false,
                  lazyLoad: false,
                  id: 'etpkrtq',
                  collapsed: true,
                },
              ],
              errorLabel: null,
              conditional: { eq: null, json: null, show: null, when: null },
              customClass: null,
              description: null,
              placeholder: null,
              calculateValue: null,
              customConditional: null,
              customDefaultValue: null,
              id: 'eudc4wf0000000000',
              multiple: false,
			  defaultValue: [
                {
                  listVlastnictva: [
                    {
                      stavba: [[]],
                      tarchy: [[]],
                      parcela: [[]],
                      vlastnik: [
                        {
                          vlastnik1: [],
                        },
                      ],
                    },
                  ],
                },
              ],
              protected: false,
              unique: false,
              persistent: false,
              hidden: false,
              clearOnHide: true,
              modalEdit: false,
              dataGridLabel: false,
              labelPosition: 'top',
              hideLabel: false,
              disabled: false,
              autofocus: false,
              dbIndex: false,
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              addons: [],
              tree: false,
              lazyLoad: false,
            },
          ],
          id: 'evafwue000',
        },
      ],
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = config;

  /**
   * Called when the component has been instantiated. This is useful to define
   * default instance variable values.
   *
   * @param component - The JSON representation of the component created.
   * @param options - The global options for the renderer
   * @param data - The contextual data object (model) used for this component.
   */
  constructor(component, options, data) {
    super(component, options, data);
  }

  /**
   * Called immediately after the component has been instantiated to initialize
   * the component.
   */
  init() {
    super.init();
  }

  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
}

export default PropertyList;
