import React from 'react';
import { FieldArray, FormikProps, useFormikContext, Field } from 'formik';

import { Button } from 'components/Button';
import Condition from 'components/Condition';
import MultiselectDropdown2 from 'components/MultiselectDropdown2';
import { Field as FieldWrapper } from 'components/Field';
import { Icon } from 'components/Icon';

import { InputTypeSwitch } from 'views/projects/FilterNew';

import { FilterTemplate } from '@contractool/schema';

import { translate } from 'utils/translations';
import { Condition as ConditionType, RulesForm } from 'types/output/rules';
import { condition } from '@constants/rule';

type Props = {
  templates: FilterTemplate[];
};

const When: React.FC<Props> = ({ templates }) => {
  const { values }: FormikProps<RulesForm> = useFormikContext();

  return (
    <div className="mb-12">
      <FieldArray name="conditions">
        {({ push, remove, replace }) => (
          <>
            {values.conditions.map(
              (condition: ConditionType, index: number) => {
                const template = templates.find(
                  (template: FilterTemplate) =>
                    template?.name === condition?.subject,
                );
                const firstItem = index === 0;

                return (
                  <>
                    {!firstItem && (
                      <div className="text-gray-700 mb-5">
                        {translate('and')}
                      </div>
                    )}
                    <div className="flex mb-6 justify-between">
                      <div className="flex hover:bg-gray-150">
                        <div className="w-80 mr-9">
                          <Field name={`conditions.${index}.subject`}>
                            {({ field, meta }: any) => (
                              <Condition
                                {...field}
                                templates={templates}
                                condition={condition}
                                editCondition={replace}
                                index={index}
                                error={meta.touched && meta.error}
                              />
                            )}
                          </Field>
                        </div>
                        {condition.subject && (
                          <>
                            <div className="w-40 mr-9">
                              <Field name={`conditions.${index}.operation`}>
                                {({ field, meta }: any) => (
                                  <FieldWrapper
                                    {...field}
                                    errorMessage={meta.touched && meta.error}
                                  >
                                    <MultiselectDropdown2
                                      {...field}
                                      onChange={(operation) =>
                                        replace(index, {
                                          ...condition,
                                          operation,
                                        })
                                      }
                                      options={template?.operations}
                                      isMulti={false}
                                      isClearable={false}
                                      hasError={meta.touched && meta.error}
                                      menuPortalTarget
                                    />
                                  </FieldWrapper>
                                )}
                              </Field>
                            </div>
                            <div className="w-155">
                              {condition.operation?.value !== 'EMPTY' && condition.operation?.value !== 'NOT_EMPTY' && (
                                <Field name={`conditions.${index}.value`}>
                                  {({ field, meta }: any) => (
                                    <FieldWrapper
                                      {...field}
                                      errorMessage={meta.touched && meta.error}
                                    >
                                      <InputTypeSwitch
                                        {...field}
                                        options={template?.values}
                                        props={template?.props}
                                        type={template?.type}
                                        onChange={(value) => {
                                          replace(index, {
                                            ...condition,
                                            value,
                                          });
                                        }}
                                        hasError={meta.touched && meta.error}
                                        hasAnyOption={
                                          condition.subject === 'approvals'
                                        }
                                      />
                                    </FieldWrapper>
                                  )}
                                </Field>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {!firstItem && (
                        <div
                          className="flex items-center ml-11 cursor-pointer"
                          onClick={() => remove(index)}
                        >
                          <Icon name="close" size={6} />
                        </div>
                      )}
                    </div>
                  </>
                );
              },
            )}
            <Button
              color="white"
              size="small"
              radius="full"
              icon="add"
              onClick={() => push(condition)}
            >
              {translate('Add condition')}
            </Button>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default When;
