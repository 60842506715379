import React, { FC } from 'react';
import { useParams, useHistory, Route, Switch } from 'react-router';
import { Form } from '../../components/Form';
import { Supplier, SupplierContact, Project } from '@contractool/schema';
import { http } from '../../utils/http';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { RouteTabs, Tab } from '../../components/Tabs';
import { Link } from 'react-router-dom';
import { pluralize as _ } from '../../utils/text';
import { Menu, MenuItem } from '../../components/Menu';
import { useRequest } from '../../utils/hooks/useRequest';
import { translate } from 'utils/translations';
import { useToasts } from 'hooks';
import { Confirmation } from '../../components/Confirmation';
import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteRequest } from '../../utils/hooks/useInfiniteRequest';
import { ProjectStateIcon } from '../projects/ProjectStateIcon';
import SupplierForm from './SupplierForm';
import { SupplierContactModal } from '../../components/form/SupplierContactModal';
import { SupplierMenu } from './edit/SupplierMenu';

export function SupplierEdit({ onUpdate }: { onUpdate: () => void }) {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const [supplier, { refresh }] = useRequest<Supplier | undefined>(
    `/api/suppliers/${id}`,
    undefined,
  );
  const { success } = useToasts();

  if (supplier === undefined) {
    return null;
  }

  const close = () => {
    history.push('/suppliers');
  };

  return (
    <>
      <Modal
        heading={translate('Supplier Detail')}
        onClose={close}
        contentClassName="h-124"
        corner={
          <SupplierMenu
            supplier={supplier}
            onRefresh={() => {
              onUpdate();
            }}
            onClose={() => close()}
          />
        }
      >
        <RouteTabs match={`/suppliers/${supplier.id}/edit/:tab`}>
          <Tab name="general" heading={translate('General')} className="pt-12">
            <GeneralTab
              supplier={supplier}
              onSuccess={(supplier: Supplier) => {
                close();
                onUpdate();
                success(
                  `${translate('Supplier :title was updated successfully', {
                    title: supplier.title,
                  })}`,
                );
              }}
              onCancel={() => close()}
            />
          </Tab>

          <Tab
            name="contacts"
            heading={_({
              _: `:count ${translate('Contacts')}`,
              1: `:count ${translate('Contact')}`,
            })(supplier.contacts_count || 0)}
          >
            <ContactsTab
              supplier={supplier}
              onRefresh={() => refresh()}
              onCancel={() => close()}
            />
          </Tab>
        </RouteTabs>
      </Modal>
    </>
  );
}

const GeneralTab: FC<{
  supplier: Supplier;
  onSuccess: (supplier: Supplier) => void;
  onCancel: () => void;
}> = ({ supplier, onSuccess, onCancel }) => {
  return (
    <Form
      initialValues={{ ...supplier }}
      onSubmit={(values) => http.put(supplier.url, values)}
      onSuccess={onSuccess}
    >
      <SupplierForm />

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onCancel}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const ContactsTab: FC<{
  supplier: Supplier;
  onRefresh: () => void;
  onCancel: () => void;
}> = ({ supplier, onRefresh, onCancel }) => {
  const history = useHistory();

  return (
    <>
      <div className="h-110 pt-12 overflow-auto">
        <table className="w-full table-fixed">
          <tbody>
            {supplier.contacts.map((contact) => (
              <tr key={contact.id}>
                <td className="pb-8 w-14">
                  <ContactAvatar contact={contact} />
                </td>
                <td className="pb-8 pr-4 truncate">{contact.name}</td>
                <td className="pb-8 truncate text-gray-600" title="hello">
                  {contact.email}
                </td>
                <td className="pb-8 pl-4 text-gray-600">{contact.phone}</td>
                {contact.is_default ? (
                  <td className={'pb-8 pl-4 text-gray-600'}>
                    {translate('Default contact')}
                  </td>
                ) : (
                  <td className={'pb-8 pl-4 text-gray-600'} />
                )}
                <td className="pb-8 pr-4 w-10 text-right">
                  <div className="flex justify-end">
                    <Menu handle="more_vert">
                      <Link
                        to={`/suppliers/${supplier.id}/edit/contacts/${contact.id}/edit`}
                        className="items-center"
                      >
                        <MenuItem icon="edit">{translate('Edit')}</MenuItem>
                      </Link>
                      <Confirmation
                        onConfirm={() => {
                          http.delete(contact.url).then(onRefresh);
                        }}
                        trigger={({ onClick }) => (
                          <MenuItem
                            icon="remove_circle"
                            onClick={() => {
                              onClick();
                            }}
                          >
                            {translate('Remove')}
                          </MenuItem>
                        )}
                        heading={translate('Remove contact')}
                        buttonText={translate('Yes, remove')}
                        color="red"
                      >
                        {translate(
                          "Are you sure you want to remove contact :name ? You can't undo this action.",
                          { name: contact.name },
                        )}
                      </Confirmation>
                    </Menu>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pb-8">
          <Link
            to={`/suppliers/${supplier.id}/edit/contacts/new`}
            className="inline-flex items-center"
          >
            <Button
              color="white"
              size="small"
              radius="full"
              icon="add"
              tabIndex={-1}
            ></Button>

            <span className="text-gray-600 ml-4">Add Contact</span>
          </Link>
        </div>

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>

        <Switch>
          <Route path={`/suppliers/${supplier.id}/edit/contacts/new`}>
            <NewContactModal
              supplier={supplier}
              onCancel={() => {
                history.push(`/suppliers/${supplier.id}/edit/contacts`);
              }}
              onSuccess={() => {
                history.push(`/suppliers/${supplier.id}/edit/contacts`);
                onRefresh();
              }}
            />
          </Route>
          <Route
            path={`/suppliers/${supplier.id}/edit/contacts/:contactId/edit`}
          >
            <EditContactModal
              supplier={supplier}
              onCancel={() => {
                history.push(`/suppliers/${supplier.id}/edit/contacts`);
              }}
              onSuccess={() => {
                history.push(`/suppliers/${supplier.id}/edit/contacts`);
                onRefresh();
              }}
            />
          </Route>
        </Switch>
      </div>
    </>
  );
};

// TODO: generate background/foreground color
const ContactAvatar: FC<{ contact: SupplierContact }> = ({ contact }) => {
  const initials = contact.name
    .split(' ')
    .map((word) => word.charAt(0))
    .map((char) => char.toUpperCase())
    .join('');

  const colors = [
    'bg-lime-700',
    'bg-red-700',
    'bg-blue-700',
    'bg-orange-700',
    'bg-pink-700',
    'bg-yellow-700',
    'bg-green-700',
    'bg-gray-700',
    'bg-teal-700',
    'bg-indigo-700',
  ];

  return (
    <div
      className={`w-10 h-10 flex items-center justify-center text-white ${
        colors[contact.id % 10]
      } rounded-full`}
    >
      {initials[0] + initials[initials.length - 1]}
    </div>
  );
};

const NewContactModal: FC<{
  supplier: Supplier;
  onCancel: () => void;
  onSuccess: (contact: SupplierContact) => void;
}> = ({ supplier, onSuccess, onCancel }) => {
  return (
    <SupplierContactModal
      label="Add contact"
      contact={{
        name: '',
        email: '',
        phone: '',
        id: 0,
        supplier_id: supplier.id,
        url: '',
        is_default: false,
        fields: {},
      }}
      onSubmit={(values) => http.post(supplier.contacts_url, values)}
      onCancel={onCancel}
      onSuccess={onSuccess}
    />
  );
};

const EditContactModal: FC<{
  supplier: Supplier;
  onCancel: () => void;
  onSuccess: (contact: SupplierContact) => void;
}> = ({ supplier, onSuccess, onCancel }) => {
  const { contactId } = useParams<{ contactId?: string }>();
  const contact = supplier.contacts.find(
    (c) => contactId && c.id === parseInt(contactId),
  );
  if (!contact) return null;

  return (
    <SupplierContactModal
      label="Edit contact"
      contact={contact}
      onSubmit={(values) => http.put(contact.url, values)}
      onCancel={onCancel}
      onSuccess={onSuccess}
    />
  );
};
