import { ReactElement, useEffect, useState } from 'react';

import { translate } from 'utils/translations';

type Props = {
  children: ReactElement;
};

const Delay: React.FC<Props> = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(false);

    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, 700);

    return () => clearTimeout(timeoutId);
  }, [children]);

  if (!shouldRender) {
    return <>{translate('Loading...')}</>;
  }

  return children;
};

export default Delay;
