import React, { FC, useContext, useState } from 'react';
import { Form, FormContext } from 'components/Form';
import { FieldTemplate, SupplierContact } from '../../../../_common/schema';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { inputAcceptsOnlyNumbersOf } from 'features/form/input';
import { translate } from 'utils/translations';
import { CustomField } from 'components';
import { AppContext } from 'contexts';
import { Checkbox } from 'components/Checkbox';
import { values } from 'lodash';

const NumberInput = inputAcceptsOnlyNumbersOf(TextInput);

export const SupplierContactModal: FC<{
  label: string;
  contact: SupplierContact;
  onCancel: () => void;
  onSuccess: (contact: SupplierContact) => void;
  onSubmit: (values: SupplierContact) => Promise<any>;
  isOpen?: boolean;
}> = ({ label, contact, onSuccess, onCancel, onSubmit, isOpen }) => {
  const { config } = useContext(AppContext);
  return (
    <Modal
      heading={label}
      size="small"
      onClose={onCancel}
      portalClassName="supplier-contact"
      isOpen={isOpen}
    >
      <Form initialValues={contact} onSubmit={onSubmit} onSuccess={onSuccess}>
        <Form.TextInput
          name="name"
          label={translate('Name')}
          className="mb-6"
          required={true}
        />
        <Form.TextInput
          name="email"
          label={translate('Email')}
          className="mb-6"
          type="email"
          required={true}
        />
        <Form.Field
          name="phone"
          label={translate('Phone')}
          component={NumberInput}
          type="tel"
          pattern="[0-9]"
          required={false}
        />
        <Form.Switch.Single name="is_default" label="Default" />
        {config.supplier_poc_fields.map(
          (field: FieldTemplate, index: number) => {
            return (
              <CustomField
                key={field.name + index}
                field={field}
                option="fields."
                className="mb-6"
              />
            );
          },
        )}

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onCancel}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{label}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
