import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Kind } from '@contractool/schema';
import { Page } from '../../components/layout/Page';
import { AppContext } from 'contexts';
import { Workflow } from '../../../../_common/schema/Workflow';
import WorkspaceCard from './WorkspaceCard';
import { Workspace } from '@contractool/schema/Workspace';

export function NewProject() {
  const { config } = useContext(AppContext);
  const history = useHistory();

  const workspaces = config.workspaces as Workspace[];

  const countWorkspaceOccurrences = (
    workspace: Workspace,
    workflows: Workflow[],
  ): number => {
    const count = workflows.reduce((totalCount, workflow) => {
      if (workflow.workspace === workspace.key) {
        if (countButonOccurrences(workflow) > 1) {
          return totalCount + countButonOccurrences(workflow);
        }
        return totalCount + 1;
      }
      return totalCount;
    }, 0);

    return count;
  };

  const countButonOccurrences = (workflow: Workflow): number => {
    const count = workflow.kinds.reduce((totalCount, kind) => {
      return totalCount + kind.buttons.length;
    }, 0);

    return count;
  };

  useEffect(() => {
    if (config?.workspaces?.length <= 1 && config?.workflows?.length === 1) {
      history.push('/projects/new/form');
    }
  }, []);

  return (
    <Page heading="New Projects">
      {config.integration === 'dell_swp' && (
        <div className={'text-md'}>
          Do not submit requests that are <b>Microsoft</b> or{' '}
          <b>Internal Use</b> here.
          <ul>
            <li />
            For Microsoft requests, please contact Pratheeba Paneer Se
            (pratheeba_paneer_se@dell.com).
            <li />
            For Internal Use, please click this link -{' '}
            <a
              href="https://dell-ip.cequence.io"
              target="_blank"
              className={'text-blue-700'}
              rel="noreferrer"
            >
              https://dell-ip.cequence.io
            </a>
            .
          </ul>
        </div>
      )}

      <div
        className="grid grid-cols-3 mt-6 gap-4"
        style={{ maxWidth: '1200px' }}
      >
        {workspaces
          .filter((workspace: Workspace) => workspace?.display === true)
          .map((workspace: Workspace, index: number) => {
            const numberOfWorkflows = countWorkspaceOccurrences(
              workspace,
              config.workflows,
            );
            if (numberOfWorkflows === 1) {
              // If workflow has one workflow, send it workflow object for params for the serialize link
              const workflow = config.workflows.find((workflow: Workflow) => {
                return workflow.workspace === workspace.key;
              });
              return (
                <WorkspaceCard
                  key={'card' + index}
                  workspace={workspace}
                  workflow={workflow}
                  numberOfWorkflows={numberOfWorkflows}
                />
              );
            }
            return (
              <WorkspaceCard
                key={'card' + index}
                workspace={workspace}
                numberOfWorkflows={numberOfWorkflows}
              />
            );
          })}
      </div>
    </Page>
  );
}
