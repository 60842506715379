import * as React from 'react';

import {Context as ReportsTabContext} from 'contexts/reports/tab';
import {Button} from 'components/Button';
import {translate} from 'utils/translations';

const ReportLibraryCreateButton = () => {
    const {idx: selectedTabIdx} = React.useContext(ReportsTabContext);

    return selectedTabIdx !== null ? (
        <Button.Link to={`/reports-library/${selectedTabIdx}/create`} color="blue" icon="add">
            {translate('Add report')}
        </Button.Link>
    ) : null;
};

export default ReportLibraryCreateButton;
