import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Form } from 'components/Form';
import { GuardContext } from 'contexts';
import { FormContext } from 'components/Form';
import { fieldDependsOnOtherThan } from 'features/form/field';
import { isAllowedForUser } from 'utils/form';
import SupplierSelect from 'components/form/SupplierSelect';
import { Workflow } from '../../../_common/schema/Workflow';
import { FieldTemplate } from '@contractool/schema';

function CustomField({
  field,
  option,
  className,
  config,
  required,
  workflow,
  readOnly,
  onChange,
}: {
  field: FieldTemplate;
  option: string;
  className?: string;
  config?: any;
  required?: boolean;
  workflow?: Workflow;
  readOnly?: boolean;
  onChange?: () => void;
}) {
  const { user } = useContext(GuardContext);
  const { values } = useContext(FormContext);
  const history = useHistory();
  if (!field) {
    return <div>Missing field in fields.php</div>;
  }
  if (!isAllowedForUser(field, user)) {
    return null;
  }
  let right = null;
  if (field.extra && field.extra.link) {
    // right = <Link className="text-blue-600 hover:text-blue-300" to={field.extra.link.href}>{field.extra.link.label}</Link>
    right = (
      <a
        className="cursor-pointer text-blue-700 flex items-center mr-4 hover:text-blue-300"
        href={field.extra.link.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {field.extra.link.label}
      </a>
    );
  }
  if (field.side_link && field.side_link.url) {
    right = (
      <Link
        to={`${field.side_link.url}${history.location.search}`}
        data-cy={`field-link-` + field.name}
        className="cursor-pointer text-blue-700 flex items-center mr-4 hover:text-blue-300"
      >
        {field.side_link.text}
      </Link>
    );
  }

  let require = field.required;
  if (required) {
    require = required;
  }

  let legend = '';
  if (field.legend) {
    legend = field.legend;
  }
  if (
    field.name === 'commodity_ltwo' &&
    values.fields.commodity_lone === 'Human Capital' && values.workflow === 'default'
  ) {
    legend =
      'For procurement assistance with reviewing a draft SoW (please submit a word version of the SoW) or an RFP or Contingent Labor Hiring please select one of the applicable tiles below';
  }
  let helptext = '';
  if (field.helptext) {
    helptext = field.helptext;
  }
  switch (field.type) {
    case 'SUPPLIER':
      return (
        <SupplierSelect
          required={field.required}
          name={field.name}
          apiParams={field.extra.apiParams ? field.extra.apiParams : {}}
          field={field}
          workflow={workflow}
        />
      );
    case 'ATTACHMENT':
      return (
        <Form.AttachmentUpload
          field={field}
          className={className}
          key={field.name}
        />
      );
    case 'CURRENCY':
      return (
        <Form.CurrencyInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={field.legend ? field.legend : ''}
          helptext={helptext}
          className={className}
          required={require}
          {...field.props}
        />
      );
    case 'INPUT':
      return field.name.includes('email') ? (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          type="email"
          className={className}
          required={require}
          {...field.props}
        />
      ) : (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          className={className}
          required={require}
          right={right}
          {...field.props}
        />
      );
    case 'SELECT':
      if (config) {
        if (config.type === 'DEPENDING') {
          return (
            <Form.DependingDropdown
              key={field.name}
              name={option + field.name}
              label={field.label}
              options={config.options}
              legend={legend}
              helptext={helptext}
              dependencies={config.dependencies}
              alt={config.alt}
              className={className}
              required={require}
              {...field.props}
            />
          );
        } else if (config.type === 'GENERATING') {
          return (
            <Form.GeneratingDropdown
              key={field.name}
              name={option + field.name}
              label={field.label}
              legend={legend}
              helptext={helptext}
              options={field.values}
              generate={config.generate}
              className={className}
              required={require}
              {...field.props}
            />
          );
        }
      }

      return (
        <Form.Dropdown
          autocomplete={true}
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          options={field.values}
          className={className}
          required={require}
          readOnly={readOnly}
          {...field.props}
        />
      );
    case 'DATE':
      return (
        <Form.DatePicker
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          formatInput="dd MMM yyyy"
          className={className}
          required={require}
          since={field.extra && field.extra.since ? field.extra.since : null}
          readOnly={readOnly}
          {...field.props}
        />
      );
    case 'BOOLEAN_SELECT':
      return (
        <Form.Dropdown
          key={field.name}
          name={option + field.name}
          options={field.values}
          label={field.label}
          legend={legend}
          helptext={helptext}
          className={className}
          required={require}
          {...field.props}
        />
      );
    case 'CHECKBOX':
      return (
        <Form.Switch.Single
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          className={className}
          {...field.props}
        />
      );
    case 'TEXT':
      return (
        <Form.TextArea
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          rows={5}
          required={require}
          className={className}
          readOnly={readOnly}
          {...field.props}
        />
      );
    case 'MULTI_SELECT':
      return (
        <Form.Multiselect
          key={field.name}
          name={option + field.name}
          options={field.values}
          label={field.label}
          legend={legend}
          helptext={helptext}
          placeholder={field.label}
          dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
          className={className}
          autocomplete
          required={require}
          right={right}
          {...field.props}
        />
      );
    case 'SELECT2':
      return (
        <Form.Multiselect2
          {...field.props}
          key={field.name}
          name={option + field.name}
          label={field.label}
          endpoint={field.valuesEndpoint}
          options={field.values}
          isMulti={field.isMulti}
          legend={legend}
          helptext={helptext}
          placeholder={field.label}
          dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
          className={className}
          autocomplete
          required={require}
          extra={field.extra}
          right={right}
        />
      );
    case 'MULTI_SELECT_ROWS':
      return (
        <Form.MultiselectRow
          key={field.name}
          name={option + field.name}
          label={field.label}
          endpoint={field.valuesEndpoint}
          legend={legend}
          helptext={helptext}
          placeholder={field.label}
          dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
          className={className}
          autocomplete
          required={require}
          {...field.props}
        />
      );
    case 'FORMULA':
      return (
        <Form.Formula
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          placeholder={field.label}
          formula={field.formula}
          className={className}
          {...field.props}
        />
      );
    case 'BIG_ADVANCED_RADIO':
      return (
        <Form.Radio.BigAdvanced
          {...field}
          key={field.name}
          name={option + field.name}
          legend={legend}
          helptext={helptext}
          options={field.advancedRadioValues}
          className={className}
          defaultValue={field.default}
          onChange={onChange}
        />
      );
    case 'SMALL_ADVANCED_RADIO':
      return (
        <Form.Radio.SmallAdvanced
          {...field}
          key={field.name}
          name={option + field.name}
          legend={legend}
          helptext={helptext}
          options={field.advancedRadioValues}
          className={className}
          colSize={field.extra.col_size ? field.extra.col_size : null}
          rowSize={field.extra.row_size ? field.extra.row_size : null}
          readOnly={readOnly}
          defaultValue={field.default}
          onChange={onChange}
        />
      );
    case 'MONTHLY_SAVINGS':
      return (
        <Form.MonthlySavings
          key={field.name}
          name={field.name}
          label={field.label}
          className={className}
          readOnly={readOnly}
        />
      );
    case 'NUMBER':
      return (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          className={className}
          type="number"
          required={require}
          right={right}
          {...field.props}
        />
      );
    case 'WYSIWYG':
      return (
        <Form.Wysiwyg
          key={field.name}
          name={option + field.name}
          label={field.label}
          className={className}
        />
      );
    case 'LABEL':
      return (
        <div className='min-h-20 p-2 pb-4 rounded'>
          <div className="flex justify-between">
            <div>
              {field.label && (
                <label
                  className='block leading-normal select-none flex items-center text-md text-gray-600'
                >
                  {field.label}
                </label>
              )}
              {field.legend && (
                <div className="text-gray-500 text-sm">{field.legend}</div>
              )}
            </div>
            <div className={'text-md'}>{right}</div>
          </div>
        </div>
      );
    default:
      return (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          helptext={helptext}
          className={className}
          required={require}
          {...field.props}
        />
      );
  }
}

const DependentCustomFieldOnCard = fieldDependsOnOtherThan(CustomField);

export default DependentCustomFieldOnCard;
