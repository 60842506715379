import React, { FC, ReactNode } from 'react';
import { Project } from '../../../../../_common/schema';
import { SidePanel } from '../ProjectDetail';
import { Icon } from 'components/Icon';
import { translate } from 'utils/translations';
import { Avatar } from 'components/Avatar';
import ReactTooltip from 'react-tooltip';
import { Link, Switch, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import { http } from 'utils/http';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Modal } from 'components/Modal';
import { permissionRequest } from 'utils/wildcard';
import { RemoveRelatedProject } from 'components/RelatedProjects/RemoveRelatedProject';

export const RelatedProjects: FC<{
  project: Project;
  onUpdate: () => void;
}> = ({ project, onUpdate }) => {
  return (
    <SidePanel
      heading="Related projects"
      right={
        permissionRequest(project, 'project.adopt') ? (
          <Link
            to={`/projects/${project.id}/adopt`}
            className="flex items-center text-blue-700 cursor-pointer"
          >
            <Icon name="add" size={5} />
            <span className="ml-2">{translate('Adopt')}</span>
          </Link>
        ) : null
      }
    >
      {project.parents &&
        project.parents.map((parent: Project) => {
          return (
            <fieldset
              className={`border hover:bg-gray-100 rounded pl-2 ${
                parent.id === project.id ? 'border-blue-300' : 'border-gray-300'
              }`}
            >
              <legend className="text-gray-600 text-center p-4 pb-0">
                {translate('Parent Project')}
              </legend>
              <div>
                <div className="flex items-center cursor-pointer hover:underline">
                  <ProjectRow
                    key={`related-project-${parent.id}`}
                    project={parent}
                    currentProject={project}
                    onUpdate={onUpdate}
                  />
                </div>
              </div>
            </fieldset>
          );
        })}
      <fieldset className={'pt-4 pb-8 border-gray-300'}>
        <table className="table">
          <tbody>
            {project.children.map((child: Project) => {
              return (
                <ProjectRow
                  key={`related-project-${child.id}`}
                  project={child}
                  currentProject={project}
                  onUpdate={onUpdate}
                />
              );
            })}
          </tbody>
        </table>
      </fieldset>
      <Switch>
        <Route path={`/projects/${project.id}/adopt`}>
          <AdoptModal project={project} onUpdate={onUpdate} />
        </Route>
      </Switch>
    </SidePanel>
  );
};

function AdoptModal({
  project,
  onUpdate,
}: {
  project: Project;
  onUpdate: () => void;
}) {
  const history = useHistory();
  const close = () => {
    history.push(`/projects/${project.id}`);
  };
  return (
    <Modal heading={translate('Adopt project')} onClose={close}>
      <Form
        initialValues={{
          child_id: '',
        }}
        onSubmit={(values) =>
          http.put(`/api/projects/${project.id}/adopt`, values)
        }
        onSuccess={() => {
          close();
          onUpdate();
        }}
      >
        <Form.TextInput
          name="child_id"
          label={translate('Child project ID')}
          legend={translate(
            'Enter the project ID which will be added as child project to this project.',
          )}
          className="mb-6"
          autoFocus={true}
        />
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={close}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Adopt')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const ProjectStatistic = ({ project }: { project: Project }) => {
  const cssClass =
    project.state.key == 'canceled' ? 'bg-red-500' : 'bg-blue-500';

  return (
    <div className="flex">
      {project.statistics.map((statistics, index) => (
        <div
          key={index}
          title={project.state.key}
          className={`mr-1 w-4 h-2 ${
            statistics.type !== 'next' ? cssClass : 'bg-gray-200'
          } rounded-full`}
        />
      ))}
    </div>
  );
};

const ProjectLink = ({
  children,
  project,
  tip,
}: {
  children?: ReactNode;
  project: Project;
  tip?: string;
}) => {
  return (
    <td className="cursor-pointer" style={{ padding: 0 }} data-tip={tip}>
      <a
        href={`/#/projects/${project.id}`}
        rel="noopener noreferrer"
        className="px-6 py-6 block w-full"
      >
        {children}
      </a>
    </td>
  );
};

const ProjectRow = ({
  project,
  currentProject,
  onUpdate,
}: {
  project: Project;
  currentProject: Project;
  onUpdate: () => void;
}) => {
  const style = project.state.key == 'canceled' ? '0.5' : '1';
  return (
    <tr
      key={`project${project.id}`}
      className={`rounded align-middle table-row border-b-1 border-gray-100 py-2 ${
        currentProject.id === project.id
          ? ' text-blue-700'
          : ' hover:bg-gray-000 cursor-pointer'
      }`}
      style={{ opacity: style }}
    >
      <ProjectLink project={project}>{project.title}</ProjectLink>
      <ProjectLink project={project}>
        {project.team.assignee ? (
          <div data-tip={project.team.assignee.name} className="flex">
            <Avatar user={project.team.assignee} className="w-10 h-10 mr-4" />
            <div className="self-center" title={project.team.assignee?.name} />
          </div>
        ) : (
          <div className="text-gray-600 ml-2">-</div>
        )}
      </ProjectLink>
      {/*<ProjectLink project={project}>*/}
      {/*    <div className="flex">*/}
      {/*        /!*<ProjectStateIcon state={project.state}/>*!/*/}
      {/*        <div className={'ml-4 self-center'}>*/}
      {/*            {project.state.label}*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</ProjectLink>*/}
      <ProjectLink project={project} tip={project.state.label}>
        <ReactTooltip />
        <ProjectStatistic project={project} />
      </ProjectLink>
      <ProjectLink project={project}>
        {project.fields['is_winner'] == 'Yes' && <span>Winner</span>}
      </ProjectLink>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <RemoveRelatedProject
        project={project}
        currentProject={currentProject}
        onUpdate={onUpdate}
      />
    </tr>
  );
};
