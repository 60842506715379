import React, { useContext, useState } from 'react';
import { generatePath, Route, useHistory, useParams } from 'react-router-dom';

import { Page } from 'components/layout/Page';
import Right from 'components/Integration/Right';
import Workflow from 'components/Integration/Workflow';
import Modal from 'components/Integration/Rule/Modal';

import { mutation } from 'utils';
import { translate } from 'utils/translations';
import { createRule } from 'utils/integration/rules';

import { AppContext } from 'contexts';
import { useRequest } from 'hooks/useRequest';
import { FilterTemplate } from '@contractool/schema';
import { RuleResponseApiType } from 'types/api/rules';

import { URL_API_RULES } from '@constants/rule';
import {
  URL_CREATE_WORKFLOW_RULE,
  URL_EDIT_WORKFLOW_RULE,
  URL_WORKFLOW_RULE,
  URL_WORKFLOW_RULE_DASHBOARD,
} from '@constants/workflow';
import { getWorkflowById } from 'hooks/workflows';

const WorkflowPage: React.FC = () => {
  const { id, wid }: { id: string; wid: string } = useParams();
  const workflowObject = getWorkflowById(parseInt(wid));
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const [templates] = useRequest<FilterTemplate[]>(
    `/api/project-filter-templates?workflow=${workflowObject?.key}`,
    [],
  );

  const [rulesData, { refresh, isLoading }] = useRequest<RuleResponseApiType[]>(
    `${URL_API_RULES}/${wid}`,
    [],
  );

  const handleRuleClose = () =>
    history.push(generatePath(URL_WORKFLOW_RULE, { id, wid }));

  const rules = rulesData.map(createRule);

  return (
    <Page
      heading={translate('Workflow Detail')}
      right={
        <Route
          exact
          path={URL_WORKFLOW_RULE_DASHBOARD}
          render={({ match }) => (
            <Right
              onChange={setSearchQuery}
              value={searchQuery}
              buttonUrl={generatePath(URL_CREATE_WORKFLOW_RULE, {
                id: match.params.id,
                wid: match.params.wid,
              })}
              buttonName={translate('New rule')}
            />
          )}
        />
      }
    >
      <Workflow
        rules={rules}
        refreshRules={refresh}
        searchQuery={searchQuery}
        isLoading={isLoading}
        templates={templates}
      />
      <Route path={URL_CREATE_WORKFLOW_RULE}>
        <Modal
          templates={templates}
          onSubmit={(payload) =>
            mutation({
              url: URL_API_RULES,
              payload,
              method: 'POST',
              refresh,
              message: 'The rule has been created',
            })
          }
          onClose={handleRuleClose}
        />
      </Route>
      <Route path={URL_EDIT_WORKFLOW_RULE}>
        <Modal
          isEditMode
          templates={templates}
          rules={rules}
          isLoadingRules={isLoading}
          onSubmit={(payload, id) =>
            mutation({
              url: `${URL_API_RULES}/${id}`,
              payload,
              method: 'PUT',
              refresh,
              message: 'The rule has been edited',
            })
          }
          onDelete={(id) =>
            mutation({
              url: `${URL_API_RULES}/${id}`,
              method: 'DELETE',
              refresh,
              message: 'The rule has been deleted',
            })
          }
          onClose={handleRuleClose}
        />
      </Route>
    </Page>
  );
};

export default WorkflowPage;
