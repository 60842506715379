import { LogFull, Comment } from '@contractool/schema';
import React, { FC, useContext } from 'react';

import Remirror from 'components/Remirror';
import { useToasts } from 'hooks';
import {
  ActivityBody,
  ActivityContainer,
  ActivityHeader,
} from 'views/projects/ActivityPanel';
import { http } from 'utils/http';
import { byOldestUp } from 'utils/sort';
import { useSmallLoader } from 'components/Loader';
import { translate } from 'utils/translations';
import ProjectContext from '../ProjectContext';

export const ConversationLog: FC<{
  log: LogFull;
  onUpdate: () => void;
}> = ({ log, onUpdate }) => {
  return (
    <ActivityContainer>
      <>
        <ActivityHeader
          causer={log.causer}
          created_at={log.created_at}
          delegator={log?.delegator}
        />
        <ActivityBody className="mb-8 mr-14">
          <div className="whitespace-pre-wrap">
            {log.message.body && bodyParser(log.message.body)}
          </div>
        </ActivityBody>

        {(log.comments ?? [])
          .sort((a: Comment, b: Comment) =>
            byOldestUp(a.created_at, b.created_at),
          )
          .map((comment, index) => (
            <ReplyComment
              key={'reply-messages' + log.id + index}
              comment={comment}
            />
          ))}

        <ReplyMessage log={log} onUpdate={onUpdate} />
      </>
    </ActivityContainer>
  );
};

const ReplyComment: FC<{
  comment: Comment;
}> = ({ comment }) => {
  return (
    <div className="ml-12">
      <ActivityHeader
        causer={comment.commenter}
        created_at={comment.created_at}
      />
      <ActivityBody className="mb-8 pr-3">
        <div className="whitespace-pre-wrap">{bodyParser(comment.body)}</div>
      </ActivityBody>
    </div>
  );
};

const ReplyMessage: FC<{
  log: LogFull;
  onUpdate: () => void;
}> = ({ log, onUpdate }) => {
  const { success } = useToasts();
  const smallLoader = useSmallLoader();

  const { workflow } = useContext(ProjectContext);

  const submitHandler = (json: any) => {
    smallLoader.start(translate('Sending the message...'));
    http
      .post<Comment>(log.comments_url, { body: json, type: log.meta.type })
      .then((response) => {
        onUpdate();
        success(
          translate(
            `Comment was successfully added by ${response.data.commenter.name}.`,
          ),
        );
        smallLoader.stop();
      });
  };

  return <Remirror type="input" onSubmit={submitHandler} workflow={workflow} />;
};

//TODO: Fix when people append some bullsh*t text after mentioning a person
const bodyParser = (body: string) => {
  const result = body.replace(/@[a-zA-Z0-9_.]+(?:[_.])/g, (username) => {
    return `<span class='font-medium text-blue-500'>${username}</span>`;
  });

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: result,
      }}
    />
  );
};
