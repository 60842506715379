import React, { useContext } from 'react';
import { Field as FormikField } from 'formik';
import { camelCase, set } from 'lodash';

import MultiselectDropdown2 from 'components/MultiselectDropdown2';
import { Field as FieldWrapper } from 'components/Field';

import { userRoleOptions } from '@constants/rule';
import { Field, UserRoleType } from 'types/output/rules';
import { useRequest } from 'hooks/useRequest';
import { User } from '@contractool/schema';
import { getWorkflow } from 'hooks/workflows';
import { AppContext } from 'contexts';
import { createOption } from 'utils/integration/rules';

type Props = {
  path: string;
  value: any;
  field: Field;
  onChange: (value: any) => void;
};

const Selection: React.FC<Props> = ({ path, value, field, onChange }) => {
  const action = camelCase(value.action);
  const { assessmentWorkflow } = useContext(AppContext);
  const workflow = getWorkflow(assessmentWorkflow);

  const users = useRequest<User[]>('/api/users?dropdown=1', []);

  return (
    <>
      <div className="w-60 mr-9">
        <FormikField name={`${path}.selection`}>
          {({ field: formikField, meta }: any) => (
            <FieldWrapper {...field} errorMessage={meta.touched && meta.error}>
              <MultiselectDropdown2
                {...formikField}
                onChange={(result) => {
                  const payload = set(value, `${action}.selection`, result);

                  if (result === null || result.length === 0) {
                    payload[action].selection = null;
                    payload[action].user = null;
                    payload[action].role = null;
                  }

                  onChange(payload);
                }}
                isMulti={field?.isMulty}
                options={userRoleOptions}
                hasError={meta.touched && meta.error}
                menuPortalTarget
              />
            </FieldWrapper>
          )}
        </FormikField>
      </div>
      {value?.[action]?.selection?.value === UserRoleType.USER && (
        <div className="w-130">
          <FormikField name={`${path}.user`}>
            {({ field: formikField, meta }: any) => (
              <FieldWrapper
                {...field}
                errorMessage={meta.touched && meta.error}
              >
                <MultiselectDropdown2
                  {...formikField}
                  onChange={(result) => {
                    const payload = set(value, `${action}.user`, result);
                    onChange(payload);
                  }}
                  options={users[0].map(createOption)}
                  hasError={meta.touched && meta.error}
                  menuPortalTarget
                />
              </FieldWrapper>
            )}
          </FormikField>
        </div>
      )}
      {value?.[action]?.selection?.value === UserRoleType.ROLE && (
        <div className="w-130">
          <FormikField name={`${path}.role`}>
            {({ field: formikField, meta }: any) => (
              <FieldWrapper
                {...field}
                errorMessage={meta.touched && meta.error}
              >
                <MultiselectDropdown2
                  {...formikField}
                  onChange={(result) => {
                    const payload = set(value, `${action}.role`, result);
                    onChange(payload);
                  }}
                  isMulti={field?.isMulty}
                  options={workflow?.roles.map((role: any) =>
                    createOption({ ...role, name: role?.key }),
                  )}
                  hasError={meta.touched && meta.error}
                  menuPortalTarget
                />
              </FieldWrapper>
            )}
          </FormikField>
        </div>
      )}
    </>
  );
};

export default Selection;
