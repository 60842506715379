import { Workflow } from '@contractool/schema/Workflow';
import { Icon } from 'components/Icon';
import { IconCircle } from 'components/IconCircle';
import { Page } from 'components/layout/Page';
import { AppContext } from 'contexts';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { translate } from 'utils/translations';
import { fromQueryString } from 'utils/url';

export function NewProjectWorkflow() {
  const history = useHistory();

  const searchParams = fromQueryString(history.location.search);

  const { config } = useContext(AppContext);
  const workspace = config?.workspaces?.find(
    (w: Workflow) => w.key === searchParams?.workspace,
  );
  const workflows = config?.workflows?.filter(
    (w: Workflow) => w.workspace === searchParams?.workspace,
  ) as Workflow[];

  const serialize: any = function (obj: any, prefix: any) {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v),
        );
      }
    }

    return str.join('&');
  };

  return (
    <Page heading={translate('New Project Request')}>
      {config.integration === 'dell_swp' && (
        <div className={'text-md'}>
          Do not submit requests that are <b>Microsoft</b> or{' '}
          <b>Internal Use</b> here.
          <ul>
            <li />
            For Microsoft requests, please contact Pratheeba Paneer Se
            (pratheeba_paneer_se@dell.com).
            <li />
            For Internal Use, please click this link -{' '}
            <a
              href="https://dell-ip.cequence.io"
              target="_blank"
              className={'text-blue-700'}
              rel="noreferrer"
            >
              https://dell-ip.cequence.io
            </a>
            .
          </ul>
        </div>
      )}
      <div style={{ maxWidth: '1200px' }}>
        <div className={'mt-10 mb-5 ml-1 border p-8 rounded-lg'}>
          <h3 className="text-xl mb-6 ">Create new {workspace?.title}</h3>
          <p className="text-gray-500 text-left">{workspace?.description}</p>
        </div>
        <div className="grid gap-4 mt-8 grid-cols-2 ">
          {workflows?.map((workflow) => {
            return workflow.kinds?.map((kind) => {
              return kind.buttons?.map((button, index) => {
                const searchParams = serialize({
                  ...button?.extra,
                  kind: button?.label,
                });
                return (
                  <Link
                    data-id="title"
                    type="button"
                    key={`button${index}`}
                    to={{
                      pathname: '/projects/new/form',
                      search: `?${searchParams}`,
                      state: button?.extra.workflow,
                    }}
                    className="small-radio-box-value group  border rounded-lg p-5 flex  justify-between items-center w-full  focus:outline-none focus:text-blue-700 focus:border-blue-700"
                  >
                    <div className="flex items-center">
                      <IconCircle
                        name={button?.extra.icon}
                        color={button?.extra.color}
                        className={'mr-4'}
                        circleSize={10}
                        iconSize={5}
                      />

                      <div className="text-l text-left">{button?.label}</div>
                    </div>
                    <Icon
                      name="arrow_forward"
                      size={6}
                      className="mr-2 text-gray-500 group-focus:text-blue-700"
                    />
                  </Link>
                );
              });
            });
          })}
        </div>
      </div>
    </Page>
  );
}
