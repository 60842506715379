import { Icon } from 'components/Icon';
import { IconCircle } from 'components/IconCircle';
import React, { FC } from 'react';
import { Workspace } from '@contractool/schema/Workspace';
import { Workflow } from '@contractool/schema/Workflow';
import NewProjectLink from './NewProjectLink';

type Props = {
  workspace: Workspace;
  workflow?: Workflow;
  numberOfWorkflows: number;
};

export const WorkspaceCard: FC<Props> = ({
  workspace,
  workflow,
  numberOfWorkflows,
}) => {
  return (
    <NewProjectLink
      workspace={workspace}
      numberOfWorkflows={numberOfWorkflows}
      workflow={workflow}
    >
      <div>
        <IconCircle
          name={workspace.icon}
          className="mb-8 group-hover:text-blue-700 group-hover:bg-blue-000 group-focus:text-blue-700 group-focus:bg-blue-000"
          circleSize={16}
          iconSize={8}
        />
        <div className="text-xl mb-6 text-left">{workspace.title}</div>
        {workspace.description && (
          <div className="text-gray-500 pb-5 text-left">
            {workspace.description}
          </div>
        )}
      </div>
      <Icon
        name="arrow_forward"
        size={6}
        className="text-gray-500 group-hover:text-blue-700 group-focus:text-blue-700"
      />
    </NewProjectLink>
  );
};
export default WorkspaceCard;
