import React from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Form, Field, Formik } from 'formik';

import { Modal as ComponentModal } from 'components/Modal';
import { Button } from 'components/Button';
import { Tab, Tabs } from 'components/Tabs';
import { Menu, MenuItem } from 'components/Menu';
import { Field as FieldWrapper } from 'components/Field';
import { Icon } from 'components/Icon';
import { Message } from 'components/Message';

import { FilterTemplate } from '@contractool/schema';

import When from './When';

import AskUser from './Tab/AskUser';
import Automatic from './Tab/Automatic';

import { translate } from 'utils/translations';
import { Tab as TabType, RulesForm } from 'types/output/rules';
import { schema } from 'validation/rules';
import { condition, askUser, automatic } from '@constants/rule';
import { sanitizePayload } from 'utils/integration/rules';
import { TextInput } from 'components/TextInput';
import { WorkflowResponseApiType } from 'types/api/workflow';
import { URL_API_WORKFLOW } from '@constants/workflow';
import { useRequest } from 'hooks/useRequest';
import { createWorkflow } from 'utils/integration/workflow';

type Props = {
  onSubmit: (payload: any, id?: number | null) => void;
  onDelete?: (id: number | undefined) => void;
  onClose: () => void;
  rules?: RulesForm[];
  isLoadingRules?: boolean;
  isEditMode?: boolean;
  templates: FilterTemplate[];
};

const Modal: React.FC<Props> = ({
  onSubmit,
  onDelete,
  onClose,
  rules,
  isLoadingRules,
  isEditMode = false,
  templates,
}) => {
  const { id, wid, rid } = useParams<{
    id: string;
    wid: string;
    rid: string;
  }>();
  const [workflowData, { isLoading: isLoadingWorkflow }] =
    useRequest<WorkflowResponseApiType>(`${URL_API_WORKFLOW}/${wid}`, null);

  const workflow = createWorkflow(workflowData);
  const rule = rules?.find((rule) => rule?.id === Number(rid));

  const isLoading = isLoadingRules || isLoadingWorkflow;
  const isWorkflowEmpty = isEmpty(workflow);
  const isRuleEmpty = isEmpty(rule);
  const isWorkflowIdDifferent = workflow?.integrationId !== Number(id);
  const isRuleDifferentFromWorkflow = workflow?.key !== rule?.workflow;
  const notFound =
    (isWorkflowEmpty ||
      isRuleEmpty ||
      isWorkflowIdDifferent ||
      isRuleDifferentFromWorkflow) &&
    isEditMode;

  const handleDelete = () => {
    onDelete && onDelete(rule?.id);
    onClose();
  };

  return (
    <ComponentModal
      heading={isEditMode ? translate('Edit Rule') : translate('New Rule')}
      onClose={onClose}
      corner={
        isEditMode && (
          <Menu
            handle={({ toggleMenu }) => (
              <div
                onClick={toggleMenu}
                className="flex items-center cursor-pointer"
              >
                <Icon
                  name="more_horiz"
                  className="text-gray-600 cursor-pointer"
                  size={6}
                />
              </div>
            )}
          >
            <MenuItem icon="remove_circle" onClick={handleDelete}>
              {translate('Delete')}
            </MenuItem>
          </Menu>
        )
      }
      size="large"
    >
      <Formik<RulesForm>
        initialValues={{
          id: rule?.id,
          name: rule?.name || '',
          workflow: wid,
          conditions: rule?.conditions || [condition],
          results: {
            tab: rule?.results?.tab || TabType.ASK_USER,
            askUser: isEmpty(rule?.results?.askUser)
              ? [askUser]
              : rule?.results?.askUser || [askUser],
            automatic: isEmpty(rule?.results?.automatic)
              ? [automatic]
              : rule?.results?.automatic || [automatic],
          },
        }}
        validationSchema={schema}
        onSubmit={async (values: RulesForm) => {
          const payload = sanitizePayload(values);

          onSubmit(payload, values?.id);
          onClose();
        }}
        enableReinitialize
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          errors,
          status,
        }) => {
          if (Object.keys(errors).length > 0) {
            console.log('FormikErrors', errors, status);
          }
          return (
            <Form>
              {isLoading ? (
                <>{translate('Loading rule...')}</>
              ) : (
                <>
                  {notFound ? (
                    <div className="mb-8">
                      <Message.Error>
                        {translate('The rule was not found.')}
                      </Message.Error>
                    </div>
                  ) : (
                    <>
                      {notFound ? (
                        <div className="mb-8">
                          <Message.Error>
                            {translate('The rule was not found.')}
                          </Message.Error>
                        </div>
                      ) : (
                        <>
                          <Field name="name">
                            {({ field, meta }: any) => (
                              <FieldWrapper
                                {...field}
                                className="mb-12"
                                errorMessage={meta.touched && meta.error}
                              >
                                <TextInput
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue(field.name, value);
                                  }}
                                  placeholder={translate('Name of rule')}
                                  hasError={meta.touched && meta.error}
                                />
                              </FieldWrapper>
                            )}
                          </Field>
                          <h2 className="text-gray-700 text-lg">
                            {translate('When')}
                          </h2>
                          <When templates={templates} />
                          {values.conditions.length > 0 && (
                            <>
                              <h2 className="text-gray-700 text-lg">
                                {translate('Then')}
                              </h2>
                              <Tabs
                                onSelect={(name: string) =>
                                  setFieldValue('results.tab', name)
                                }
                                selected={values.results.tab}
                              >
                                <Tab
                                  name={TabType.ASK_USER}
                                  heading={translate('Ask user to')}
                                  className="py-8"
                                >
                                  <AskUser />
                                </Tab>
                                <Tab
                                  name={TabType.AUTOMATIC}
                                  heading={translate('Do automatic action')}
                                  className="py-8"
                                >
                                  <Automatic />
                                </Tab>
                              </Tabs>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <ComponentModal.Footer className="flex justify-between">
                <Button color="white" onClick={onClose}>
                  {translate('Cancel')}
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  disabled={notFound || isSubmitting}
                >
                  {translate('Save')}
                </Button>
              </ComponentModal.Footer>
            </Form>
          );
        }}
      </Formik>
    </ComponentModal>
  );
};

export default Modal;
