import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { ReactComponent } from '@formio/react';
import settingsForm from './config';
import { UploadFile } from 'components/Attachment';
import { http } from 'utils/http';
import { URL_API_LIBRARY } from '@constants/attachment';
import { Document } from '@contractool/schema';
import { useSmallLoader } from 'components/Loader';
type Props = {
  component: any;
  value: string | null;
  onChange: (value: string, flags: string) => void;
  additionalInfo: any;
};

const DataExtractionFileUpload: React.FC<Props> = ({
  onChange,
  additionalInfo,
}) => {
  const { start } = useSmallLoader();
  return (
    <>
      <UploadFile
        onCompleted={async (response) => {
          const payload = {
            file: response,
          };
          start('Extracting the meta data. Please wait...');
          const res = await http.post<Document>(
            URL_API_LIBRARY + '?crunch=entities',
            payload,
          );
          //const res = { data: { id: 1 } };

          console.log(res);

          additionalInfo(res.data);
          onChange(String(res.data.id), null);
        }}
        additionalData={{ categoryId: 0 }}
      />
    </>
  );
};

export default class File extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Data Extraction File Upload',
      icon: 'file',
      group: '',
      documentation: '',
      weight: 0,
      schema: File.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    // @ts-ignore
    return ReactComponent.schema({
      type: 'dataExtractionFileUpload',
      label: 'Data Extraction File Upload',
      key: 'dataExtractionFileUpload',
      customClass: 'entity-extraction',
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  handleAdditionalInfo(data) {
    // @ts-ignore
    this._data['fileID'] = data.id;
  }

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return render(
      <DataExtractionFileUpload
        // @ts-ignore
        component={this.component}
        value={this.dataValue}
        onChange={this.updateValue}
        additionalInfo={this.handleAdditionalInfo.bind(this)}
      />,
      element,
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      unmountComponentAtNode(element);
    }
  }
}
