import React from 'react';

import { TableBasic, TableWithPagination } from 'components/Table';

import { Section, Variation } from './DesignSystem';

export function Table() {
  return (
    <>
      <Section title="Table basic">
        <Variation>
          <TableBasic
            columns={[
              {
                Header: 'First Name',
                accessor: 'first_name',
              },
              {
                Header: 'Last Name',
                accessor: 'last_name',
              },
            ]}
            data={[
              {
                first_name: 'Janko',
                last_name: 'Hrasko',
              },
              {
                first_name: 'Janko',
                last_name: 'Mrkvicka',
              },
            ]}
            isLoading={false}
          />
        </Variation>
      </Section>
      <Section title="Table with pagination">
        <Variation>
          <TableWithPagination
            pageSize={2}
            columns={[
              {
                Header: 'First Name',
                accessor: 'first_name',
              },
              {
                Header: 'Last Name',
                accessor: 'last_name',
              },
            ]}
            data={[
              {
                first_name: 'Kathleen',
                last_name: 'Mcdonald',
              },
              {
                first_name: 'Michelle',
                last_name: 'Morrison',
              },
              {
                first_name: 'Janko',
                last_name: 'Mrkvicka',
              },
              {
                first_name: 'Eden',
                last_name: 'Sanders',
              },
            ]}
            isLoading={false}
          />
        </Variation>
      </Section>
    </>
  );
}
