import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { TextArea } from 'components/TextArea';
import { Field as FieldWrapper } from 'components/Field';
import { FormBuilder, FormRenderer } from 'components/Formio';

import { translate } from 'utils/translations';
import { useCequenceStorage } from 'hooks/useCequenceStorage';
import { useToasts } from 'hooks';
import { Components } from '@formio/react';
import components from 'components/Formio/Custom';

type PropsStorage = {
  formio: object;
};

const FormBuilderr: React.FC = () => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const { success } = useToasts();

  const [{ formio }, setDevStorage] = useCequenceStorage<PropsStorage>('dev', {
    formio: { display: 'form' },
  });

  const validationSchema = yup.object().shape({
    formio: yup.string().required(translate('Required')),
  });

  const prettifyString = (json: object) => JSON.stringify(json, null, 2);

  const closeModal = () => setOpenModal(false);
  const openModal = () => setOpenModal(true);
  Components.setComponents(components);
  const handleImportFormio = ({ formio }) => {
    setDevStorage({ formio: JSON.parse(formio) });
    closeModal();
    success(translate('Successful created'));
  };
  console.log('formio', JSON.stringify(formio));
  return (
    <>
      <div className="pb-5">
        <Button onClick={openModal}>{translate('Import Form')}</Button>
      </div>
      <h1 className="text-xl pb-3">{translate('Builder')}</h1>
      <FormBuilder
        form={{ ...formio }}
        onChange={(formio) => setDevStorage({ formio })}
      />
      <h1 className="text-xl pb-3 pt-3">{translate('Renderer')}</h1>
      <FormRenderer
        form={{ ...formio }}
        onSubmit={({ data }) => alert(prettifyString(data))}
      />
      {isOpenModal && (
        <Modal heading={translate('Import Form')} onClose={closeModal}>
          <Formik
            initialValues={{
              formio: prettifyString(formio),
            }}
            validationSchema={validationSchema}
            onSubmit={handleImportFormio}
          >
            {({ handleSubmit, setFieldValue, isSubmitting }) => (
              <Form>
                <Field name="formio">
                  {({ field, meta }: any) => (
                    <FieldWrapper
                      {...field}
                      label={translate('JSON object')}
                      className="mb-12"
                      errorMessage={meta.touched && meta.error}
                    >
                      <TextArea
                        {...field}
                        onChange={(value) => setFieldValue(field.name, value)}
                        hasError={meta.touched && meta.error}
                        rows={20}
                      />
                    </FieldWrapper>
                  )}
                </Field>
                <Modal.Footer className="flex justify-between">
                  <Button color="white" onClick={closeModal}>
                    {translate('Cancel')}
                  </Button>
                  <Button
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    {translate('Save')}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default FormBuilderr;
