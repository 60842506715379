import { translate } from 'utils/translations';

const Loading = () => {
  return (
    <tr>
      <td className="text-gray-600">{translate('Loading')}</td>
    </tr>
  );
};

export default Loading;
