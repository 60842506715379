import React, { FC, useContext } from 'react';
import { Project } from '@contractool/schema';
import { SidePanel } from './ProjectDetail';
import { http } from 'utils/http';
import { Icon } from 'components/Icon';
import { parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { ProjectDocumentDetail } from './ProjectDocumentDetail';
import { Document } from '@contractool/schema';
import { sign } from 'utils/auth';
import { translate } from 'utils/translations';
import { AppContext } from 'contexts';
import { iconHandler } from './ProjectLogs/DocumentLog';
import { permissionRequest } from 'utils/wildcard';
import { AddFiles } from 'views/projects/AddFiles';
import { UploadFile } from 'components/Attachment';
import { Confirmation } from 'components/Confirmation';
import { Document as AttachmentDocument } from '@contractool/schema/Document';
import { useToasts } from 'hooks';
import { PreviewIcon } from 'components/documents/PreviewIcon';
import { addVersion } from 'features/office/office';
import ReactTooltip from 'react-tooltip';

/* returns main document as Attachment */
const mainDocument = (project: Project): Document | null => {
  const attachments = project.attachments.filter(
    (value) => project.document_id + '' === value.id + '',
  );
  if (attachments.length === 1) return attachments[0];

  return null;
};
export const ContractPanel: FC<{
  project: Project;
  refresh: () => void;
  onSelectedFiles: (selectedFiles: Document[]) => void;
  onClose: () => void;
}> = ({ project, refresh, onSelectedFiles, onClose }) => {
  const { config } = useContext(AppContext);
  const { success } = useToasts();
  const projectDocument = mainDocument(project);

  if (!config.config) {
    return null;
  }
  const onSend = (file: { path: string; name: string }) => {
    const currentMainDocument = mainDocument(project);
    const data = { ...file, project_document: true };
    if (currentMainDocument === null) {
      return http.post<Document>(project.attachments_url, { file: data });
    }

    return http.post<Document>(currentMainDocument.versions_url, {
      file: data,
    });
  };

  return (
    <SidePanel
      heading={translate('Contract')}
      right={
        <div className="flex">
          {config.config.office365 && projectDocument === null ? (
            <span className="flex items-center mr-3">
              <a
                href={project.new_contract_url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-blue-700 mr-4"
              >
                <Icon name="word" size={5} />
                <span className="ml-2">{translate('Create online')}</span>
              </a>
              <span className="text-gray-300">|</span>
            </span>
          ) : null}
          {permissionRequest(project, 'documents.edit') && (
            <Link
              to={`/projects/${project.id}/contract/browse`}
              className="flex items-center text-blue-700"
            >
              <Icon name="add" size={5} />
              <span className="ml-2">{translate('Add file')}</span>
            </Link>
          )}
        </div>
      }
    >
      <ContractDocument project={project} onUpdate={refresh}></ContractDocument>
      {permissionRequest(project, 'documents.edit') ? (
        <UploadFile
          onCompleted={async (file) => {
            await onSend(file);
            onClose();
            refresh();
          }}
        />
      ) : null}
      <Switch>
        <Route path={`/projects/${project.id}/documents/:documentId`}>
          <ProjectDocumentDetail
            onUpdate={() => {
              onClose();
              refresh();
            }}
            onClose={onClose}
            project={project}
          />
        </Route>
        {permissionRequest(project, 'documents.edit') && (
          <Route exact path={`/projects/${project.id}/contract/browse`}>
            <AddFiles
              onCompletedUpload={async (file) => {
                await onSend(file);
                onClose();
                refresh();
              }}
              onSelectFiles={async (selectedFiles) => {
                if (selectedFiles.length === 0) return;

                await Promise.all(
                  selectedFiles.map(async (selectedFile) => {
                    await http.post<Document>(project.library_attachments_url, {
                      document_id: selectedFile.id,
                      type: 'main',
                    });

                    success(
                      `${translate(
                        'Document :file was successfully added to project',
                        {
                          file: `${
                            selectedFiles.find(
                              (doc) => doc.id === selectedFile.id,
                            )?.title
                          }`,
                        },
                      )}.`,
                    );
                  }),
                );

                onClose();
                onSelectedFiles(selectedFiles);
              }}
              onClose={onClose}
              hasPermission={permissionRequest(project, 'library.view')}
            />
          </Route>
        )}
      </Switch>
    </SidePanel>
  );
};

const ContractDocument: FC<{ project: Project; onUpdate: () => void }> = ({
  project,
  onUpdate,
}) => {
  const { config } = useContext(AppContext);
  const { success, error } = useToasts();

  if (project.attachments_url.length === 0) return null;
  const projectDocument = mainDocument(project);
  if (projectDocument === null) return <></>;
  const latestVersion =
    projectDocument.versions[projectDocument.versions.length - 1];
  const permissions = project.can;
  const icon = iconHandler(projectDocument.title);

  const deleteAttachment = (projectDocument: AttachmentDocument) => {
    http
      .delete(projectDocument.url)
      .then(() => {
        onUpdate();
        success(
          `${translate('Document :file was successfully removed from project', {
            file: document.title,
          })}.`,
        );
      })
      .catch(() => {
        error(`${translate('Error with removing document from project')}.`);
      });
  };

  return (
    <>
      <div className="border border-gray-200 p-6 mb-4 rounded-xl flex">
        {icon}
        <div className="pl-3">
          <Link to={`/projects/${project.id}/documents/${projectDocument.id}`}>
            <div className="leading-tighter">{projectDocument.title}</div>
            <div className="text-gray-600 text-sm pt-2">
              {translate('Version')} {projectDocument.versions.length} -{' '}
              {format(parseISO(latestVersion.created_at), 'dd. MMM')}
            </div>
          </Link>
        </div>
        <div className="grow flex justify-end">
          {permissionRequest(project, 'documents.view') && (
            <span data-tip={'Preview file'}>
              <PreviewIcon
                fileUrl={projectDocument.preview_url}
                modalPreview={projectDocument.modal_preview}
                type={projectDocument.type}
                documentId={projectDocument.id}
              />
            </span>
          )}
          {permissionRequest(project, 'documents.view') && (
            <a
              href={sign(projectDocument.download_url)}
              target="_blank"
              rel="noopener noreferrer"
              data-tip={'Download file'}
            >
              <Icon name="get_app" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
          {config.config.office365 &&
            permissionRequest(project, 'documents.edit') && (
              <span className="flex">
                <a
                  href={sign(projectDocument.edit_url)}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={'Edit online'}
                >
                  <Icon
                    name="edit"
                    size={6}
                    className="ml-3 mr-3 text-gray-500"
                  />
                </a>
                <span
                  className="cursor-pointer"
                  data-tip={'New version online'}
                  onClick={() => {
                    addVersion(projectDocument, onUpdate);
                  }}
                >
                  <Icon name="duplicate" size={6} className="text-gray-500" />
                </span>
                <ReactTooltip className="react-tooltip" />
              </span>
            )}
          {permissionRequest(project, 'documents.delete') && (
            <Confirmation
              onConfirm={() => {
                deleteAttachment(projectDocument);
              }}
              trigger={({ onClick }) => (
                <span
                  onClick={onClick}
                  className="cursor-pointer focus:outline-none"
                  data-tip={'Delete file'}
                >
                  <Icon name="delete" size={6} className="ml-3 text-gray-500" />
                </span>
              )}
              heading={translate('Remove the contract')}
              buttonText={translate('Yes, remove')}
              color="yellow"
            >
              {translate('Are you sure you want to remove the contract?')}
            </Confirmation>
          )}
        </div>
      </div>
    </>
  );
};
