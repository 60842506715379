import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Cell } from 'react-table';

import { TableWithPagination } from 'components/Table';
import { Menu, MenuItem } from 'components/Menu';
import { Icon } from 'components/Icon';

import Condition from './Label/Condition';
import Result from './Label/Result';

import { FilterTemplate } from '@contractool/schema';
import { URL_EDIT_WORKFLOW_RULE } from '@constants/workflow';

import { translate } from 'utils/translations';
import {
  AutomaticResult,
  Condition as ConditionType,
  RulesForm,
} from 'types/output/rules';

type Props = {
  rules: RulesForm[];
  isLoading: boolean;
  templates: FilterTemplate[];
  integrationId: string;
  workflowId: string;
  onDelete: (id: number | undefined) => void;
};

const Rule: React.FC<Props> = ({
  rules,
  isLoading,
  templates,
  integrationId,
  workflowId,
  onDelete,
}) => {
  const history = useHistory();

  const columns = [
    {
      Header: translate('Name'),
      accessor: 'name',
    },
    {
      Header: translate('Condition'),
      accessor: 'conditions',
      Cell: ({ value }: { value: ConditionType[] }) =>
        value
          .filter(
            (condition) =>
              // Exceptions: Display only
              condition.subject == 'projects.state' ||
              condition.subject === 'projects.fields->region' ||
              condition.subject === 'projects.fields->cost_center_location' ||
              condition.subject === 'projects.fields->commodity_lone' ||
              condition.subject === 'projects.fields->commodity_ltwo' ||
              condition.subject === 'projects.fields->commodity_lthree' ||
              condition.subject === 'projects.fields->approved_budget',
          )
          .map((condition) => (
            <Condition
              key={condition.subject}
              condition={condition}
              templates={templates}
            />
          )),
    },
    {
      Header: translate('Result'),
      accessor: 'results.automatic',
      Cell: ({ value }: { value: AutomaticResult[] }) =>
        value.map((automatic) => (
          <Result key={automatic.action} automatic={automatic} />
        )),
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      width: 50,
      Cell: ({ value }: { value: number }) => (
        <Menu
          handle={({ toggleMenu }) => (
            <div
              onClick={toggleMenu}
              className="flex items-center cursor-pointer"
            >
              <Icon
                name="more_horiz"
                className="text-gray-600 cursor-pointer"
                size={6}
              />
            </div>
          )}
        >
          <MenuItem icon="remove_circle" onClick={() => onDelete(value)}>
            {translate('Delete')}
          </MenuItem>
        </Menu>
      ),
    },
  ];

  const openModal = (rid: number | undefined) => {
    history.push(
      generatePath(URL_EDIT_WORKFLOW_RULE, {
        id: integrationId,
        wid: workflowId,
        rid,
      }),
    );
  };

  const handleClick = ({ row, column }: Cell<object, any>) => {
    if (column.id === 'id') return;

    const original = row.original as RulesForm;
    openModal(original.id);
  };

  return (
    <TableWithPagination
      columns={columns}
      data={rules}
      isLoading={isLoading}
      onClick={handleClick}
    />
  );
};

export default Rule;
