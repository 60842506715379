import * as React from 'react';
import { useHistory } from 'react-router';

import { Context as ReportTabContext } from 'contexts/reports/tab';
import { Confirmation } from 'components/Confirmation';
import { Menu as CommonMenu, MenuItem } from 'components/Menu';
import { Context as ReportContext } from 'contexts/reports/tab/report';
import { translate } from 'utils/translations';

const Menu = () => {
  const history = useHistory();
  const { idx: tabIdx, tab } = React.useContext(ReportTabContext);
  const { idx } = React.useContext(ReportContext);

  const goToEdit = React.useCallback(() => {
    history.push(`/reports/${tabIdx}/${idx}/edit`);
  }, [history, tabIdx, idx]);

  const { remove: removeReport } = React.useContext(ReportContext);

  return (
    <CommonMenu handle="more_vert">
      <Confirmation
        onConfirm={removeReport}
        trigger={({ onClick }) => (
          <MenuItem
            icon="remove_circle"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {translate('Delete')}
          </MenuItem>
        )}
        heading={translate('Delete report')}
        buttonText={translate('Yes, delete')}
        color="red"
      >
        {translate('Are you sure you want to delete this report?')}
      </Confirmation>
      <MenuItem icon="edit" onClick={goToEdit}>
        {translate('Edit')}
      </MenuItem>
      <a
        href={`/report-tabs/${tab.id}/${idx}/export`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <MenuItem icon="arrow_downward">{translate('Export')}</MenuItem>
      </a>
    </CommonMenu>
  );
};

export default Menu;
