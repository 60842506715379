import React, { useState } from 'react';
import { generatePath, Route } from 'react-router-dom';

import { Page } from 'components/layout/Page';
import Right from 'components/Integration/Right';
import Integration from 'components/Integration';

import { translate } from 'utils/translations';

import {
  URL_CREATE_INTEGRATION_WORKFLOW,
  URL_INTEGRATION_WORKFLOW_DASHBOARD,
} from '@constants/integration';

const IntegrationPage: React.FC = () => {
  const [searchInput, setSearchInput] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  return (
    <Page
      heading={translate('Integration Detail')}
      right={
        <Route
          exact
          path={URL_INTEGRATION_WORKFLOW_DASHBOARD}
          render={({ match }) => (
            <Right
              onChange={setSearchInput}
              value={searchInput}
              buttonUrl={generatePath(URL_CREATE_INTEGRATION_WORKFLOW, {
                id: match.params.id,
              })}
              buttonName={translate('New workflow')}
              onClick={() => setIsCreateModalOpen(true)}
            />
          )}
        />
      }
    >
      <Integration
        searchInput={searchInput}
        isCreateModalOpen={isCreateModalOpen}
        setIsCreateModalOpen={setIsCreateModalOpen}
      />
    </Page>
  );
};

export default IntegrationPage;
