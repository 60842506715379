import { createContext } from 'react';

const ProjectFormContext = createContext<{
  create: boolean;
  supplierTitle: string;
  updateSupplierTitle: (value: any) => void;
}>({
  create: false,
  supplierTitle: '',
  updateSupplierTitle: () => {},
});

export default ProjectFormContext;
