import { useCallback, useEffect, useState } from 'react';
import {
  BoldExtension,
  HeadingExtension,
  ItalicExtension,
  MentionAtomExtension,
  UnderlineExtension,
  PlaceholderExtension,
  ImageExtension,
  DropCursorExtension,
} from 'remirror/extensions';
import { CountExtension } from '@remirror/extension-count';
import { ThemeProvider, Remirror, useRemirror } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';

import EditorComponent from './EditorComponent';

import { translate } from 'utils/translations';

import './style.scss';
import { Workflow } from '@contractool/schema/Workflow';
import { InvalidContentHandler } from 'remirror';

type Props = {
  type: 'input' | 'textarea';
  onSubmit: (json: any) => void;
  onChange?: (json: any) => void;
  value?: any;
  hideButton?: boolean;
  workflow: Workflow;
};

const RemirrorEditor: React.FC<Props> = ({
  type,
  onSubmit,
  onChange,
  value,
  hideButton,
  workflow,
}) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const normalizedValue = value;
  if (
    value &&
    value.content &&
    value.content.length > 0 &&
    value.content[0].content
  ) {
    normalizedValue.content[0].content = value.content[0].content.filter(
      (item) => {
        if (item.type === 'text' && !item.text) {
          return false;
        }
        return true;
      },
    );
  }

  const onError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      console.log('InvalidContentHandler', json, invalidContent);
      return transformers.remove(json, invalidContent);
    },
    [],
  );
  const { manager, state } = useRemirror({
    extensions: () => [
      new HeadingExtension({ levels: [1, 2, 3] }),
      new BoldExtension({ weight: 'bold' }),
      new ItalicExtension(),
      new UnderlineExtension(),
      new PlaceholderExtension({
        placeholder: translate(
          'Type here, use @ to mention somebody or # to tag',
        ),
      }),
      new MentionAtomExtension({
        matchers: [
          {
            name: 'at',
            char: '@',
            matchOffset: 0,
            mentionClassName: 'text-gray-700, font-bold',
          },
          {
            name: 'hash',
            char: '#',
            matchOffset: 0,
            mentionClassName: 'text-gray-700, font-bold',
          },
        ],
      }),
      new CountExtension({}),
      new ImageExtension({ enableResizing: true }),
      new DropCursorExtension(),
    ],
    content: normalizedValue,
    selection: 'end',
    stringHandler: 'html',
    onError,
  });

  const typeClass = {
    input: 'input',
    textarea: 'textarea',
  };

  //   useEffect(() => {
  //     const timeoutId = setTimeout(() => {
  //       document.querySelector('main')?.scrollTo(0, 0);
  //     });

  //     return () => clearTimeout(timeoutId);
  //   }, []);

  const onFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={state}
          classNames={[typeClass[type]]}
          onChange={(parameter) => {
            onChange?.(parameter.helpers.getJSON());
          }}
          onFocus={onFocus}
        >
          <EditorComponent
            hideButton={hideButton}
            onSubmit={onSubmit}
            isFocused={inputFocused}
            workflow={workflow}
          />
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
};

export default RemirrorEditor;
