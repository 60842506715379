import React, { useEffect, useState } from 'react';
import { MentionAtomPopupComponent, MentionState } from '@remirror/react';

import { AvatarLink } from 'components/Avatar';
import { User } from '@contractool/schema';

import { translate } from 'utils/translations';
import { useRequest } from 'hooks/useRequest';
import { Workflow } from '@contractool/schema/Workflow';

const Mention: React.FC<{ workflow: Workflow }> = ({ workflow }) => {
  const [mentionState, setMentionState] = useState<MentionState | null>();
  const [items, setItems] = useState([]);

  const [response] = useRequest<User[]>('api/users', [], {
    params: { phrase: mentionState?.query?.full },
  });

  useEffect(() => {
    if (!mentionState) {
      return;
    }

    if (mentionState.name === 'at') {
      const users = response.map((user) => ({
        id: user.username,
        url: user.avatar_url,
        label: user.name,
      }));
      setItems(users);
    }

    if (mentionState.name === 'hash') {
      // @ts-ignore
      const meta = workflow?.fields.meta_data;
      const roles = workflow?.roles;
      const tags = [
        ...meta.map((meta) => ({ label: meta.label, id: meta.name })),
        ...roles.map((role) => ({
          label: `${translate('Roles')} - ${role.label}`,
          id: 'role-' + role.key,
        })),
      ];
      const filter = tags.filter((tag) =>
        tag.label.toLowerCase().includes(mentionState.query.full),
      );
      setItems(filter);
    }
  }, [mentionState, workflow, response]);

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ zIndex: 1, position: 'absolute' }}>
        <MentionAtomPopupComponent
          onChange={setMentionState}
          items={items}
          ItemComponent={({ item, state }) => (
            <div className="flex items-center text-gray-700">
              {state?.name === 'at' && (
                <>
                  <AvatarLink
                    avatar_url={item.url}
                    name={item.label}
                    className="w-7 h-7 mr-3"
                  />
                </>
              )}
              {item.label}
            </div>
          )}
          ZeroItemsComponent={() => (
            <div className="text-gray-700 pl-4 pr-4">
              {translate('No items available')}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Mention;
