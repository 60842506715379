import { WorkflowResponseApiType } from 'types/api/workflow';
import { Workflow } from 'types/output/workflow';

export const createWorkflow = (workflow: WorkflowResponseApiType): Workflow => {
  if (workflow) {
    return {
      id: workflow.id,
      integrationId: Number(workflow.integration_id),
      title: workflow.title,
      key: workflow.key,
      isDefault: workflow.is_default,
      settings: workflow.settings_config,
      fields: workflow.fields_config,
      roles: workflow.roles_config,
      states: workflow.states_config,
    };
  } else {
    return null;
  }
};
