import React from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';

import {
  URL_INTEGRATIONS,
  URL_INTEGRATION_WORKFLOW,
} from '@constants/integration';

import { translate } from 'utils/translations';
import { Workflow } from 'types/output/workflow';

type Props = {
  workflow: Workflow;
  integrationTitle: string;
};

const Breadcrumb: React.FC<Props> = ({ workflow, integrationTitle }) => {
  const { id }: { id: string } = useParams();

  return (
    <div className="flex text-gray-900 mb-5 text-md">
      <Link to={URL_INTEGRATIONS} className="underline">
        {translate('Integrations')}
      </Link>
      <div className="pl-3 pr-3">{'>'}</div>
      <Link
        to={generatePath(URL_INTEGRATION_WORKFLOW, { id })}
        className="underline"
      >
        {integrationTitle}
      </Link>
      {workflow?.title && (
        <>
          <div className="pl-3 pr-3">{'>'}</div>
          {workflow?.title}
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
