import React, { useContext } from 'react';

import { FormRenderer } from 'components/Formio';

import { AppContext } from 'contexts';

type Props = {
  onSubmit: (values: object) => Promise<void>;
  value: object;
};

const State: React.FC<Props> = ({ onSubmit, value }) => {
  const { refresh } = useContext(AppContext);

  return (
    <FormRenderer
      submission={{ data: value }}
      form={{
        display: 'form',
        components: [
          {
            label: 'States',
            labelPosition: 'top',
            description: '',
            tooltip: '',
            disableAddingRemovingRows: false,
            conditionalAddButton: '',
            reorder: true,
            addAnother: '',
            addAnotherPosition: 'bottom',
            layoutFixed: false,
            enableRowGroups: false,
            initEmpty: false,
            customClass: '',
            tabindex: '',
            hidden: false,
            hideLabel: false,
            autofocus: false,
            disabled: false,
            tableView: false,
            modalEdit: false,
            defaultValue: [],
            persistent: true,
            protected: false,
            dbIndex: false,
            encrypted: false,
            redrawOn: '',
            clearOnHide: true,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            allowCalculateOverride: false,
            validateOn: 'change',
            validate: {
              required: false,
              minLength: '',
              maxLength: '',
              customMessage: '',
              custom: '',
              customPrivate: false,
              json: '',
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            errorLabel: '',
            errors: '',
            key: 'states',
            tags: [],
            properties: {},
            conditional: { show: null, when: null, eq: '', json: '' },
            customConditional: '',
            logic: [],
            attributes: {},
            overlay: {
              style: '',
              page: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            type: 'datagrid',
            input: true,
            components: [
              {
                label: 'State title',
                tableView: true,
                key: 'stateTitle',
                type: 'textfield',
                input: true,
                id: 'ebfyibk000000000',
                placeholder: '',
                prefix: '',
                customClass: '',
                suffix: '',
                multiple: false,
                defaultValue: null,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                refreshOn: '',
                redrawOn: '',
                modalEdit: false,
                dataGridLabel: false,
                labelPosition: 'top',
                description: '',
                errorLabel: '',
                tooltip: '',
                hideLabel: false,
                tabindex: '',
                disabled: false,
                autofocus: false,
                dbIndex: false,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                widget: { type: 'input' },
                attributes: {},
                validateOn: 'change',
                validate: {
                  required: true,
                  custom: '',
                  customPrivate: false,
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                  minLength: '',
                  maxLength: '',
                  pattern: '',
                },
                conditional: { show: null, when: null, eq: '' },
                overlay: {
                  style: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                allowCalculateOverride: false,
                encrypted: false,
                showCharCount: false,
                showWordCount: false,
                properties: {},
                allowMultipleMasks: false,
                addons: [],
                mask: false,
                inputType: 'text',
                inputFormat: 'plain',
                inputMask: '',
                displayMask: '',
                spellcheck: true,
                truncateMultipleSpaces: false,
              },
              {
                label: 'State tag',
                tableView: true,
                key: 'stateTag',
                type: 'textfield',
                input: true,
                id: 'e3bdehk000000000',
                placeholder: '',
                prefix: '',
                customClass: '',
                suffix: '',
                multiple: false,
                defaultValue: null,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                refreshOn: '',
                redrawOn: '',
                modalEdit: false,
                dataGridLabel: false,
                labelPosition: 'top',
                description: '',
                errorLabel: '',
                tooltip: '',
                hideLabel: false,
                tabindex: '',
                disabled: false,
                autofocus: false,
                dbIndex: false,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                widget: { type: 'input' },
                attributes: {},
                validateOn: 'change',
                validate: {
                  required: true,
                  custom: '',
                  customPrivate: false,
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                  minLength: '',
                  maxLength: '',
                  pattern: '',
                },
                conditional: { show: null, when: null, eq: '' },
                overlay: {
                  style: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                allowCalculateOverride: false,
                encrypted: false,
                showCharCount: false,
                showWordCount: false,
                properties: {},
                allowMultipleMasks: false,
                addons: [],
                mask: false,
                inputType: 'text',
                inputFormat: 'plain',
                inputMask: '',
                displayMask: '',
                spellcheck: true,
                truncateMultipleSpaces: false,
              },
              {
                label: 'State color',
                labelPosition: 'top',
                widget: 'choicesjs',
                placeholder: '',
                description: '',
                tooltip: '',
                customClass: '',
                tabindex: '',
                hidden: false,
                hideLabel: false,
                uniqueOptions: false,
                autofocus: false,
                dataGridLabel: false,
                disabled: false,
                tableView: true,
                modalEdit: false,
                multiple: false,
                dataSrc: 'values',
                data: {
                  values: [
                    { label: 'orange', value: 'orange' },
                    { label: 'green', value: 'green' },
                    { label: 'red', value: 'red' },
                    { label: 'blue', value: 'blue' },
                    { label: 'violet', value: 'violet' },
                    { label: 'cyan', value: 'cyan' },
                    { label: 'pink', value: 'pink' },
                    { label: 'yellow', value: 'yellow' },
                    { label: 'teal', value: 'teal' },
                    { label: 'lightgray', value: 'lightgray' },
                  ],
                  resource: '',
                  json: '',
                  url: '',
                  custom: '',
                },
                dataType: '',
                idPath: 'id',
                valueProperty: '',
                template: '<span>{{ item.label }}</span>',
                refreshOn: '',
                refreshOnBlur: '',
                clearOnRefresh: false,
                searchEnabled: true,
                selectThreshold: 0.3,
                readOnlyValue: false,
                customOptions: {},
                useExactSearch: false,
                persistent: true,
                protected: false,
                dbIndex: false,
                encrypted: false,
                clearOnHide: true,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                allowCalculateOverride: false,
                validateOn: 'change',
                validate: {
                  required: false,
                  onlyAvailableItems: false,
                  customMessage: '',
                  custom: '',
                  customPrivate: false,
                  json: '',
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                },
                unique: false,
                errorLabel: '',
                errors: '',
                key: 'color',
                tags: [],
                properties: {},
                conditional: { show: null, when: null, eq: '', json: '' },
                customConditional: '',
                logic: [],
                attributes: {},
                overlay: {
                  style: '',
                  page: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                type: 'select',
                indexeddb: { filter: {} },
                selectFields: '',
                searchField: '',
                searchDebounce: 0.3,
                minSearch: 0,
                filter: '',
                limit: 100,
                redrawOn: '',
                input: true,
                prefix: '',
                suffix: '',
                showCharCount: false,
                showWordCount: false,
                allowMultipleMasks: false,
                addons: [],
                lazyLoad: true,
                authenticate: false,
                ignoreCache: false,
                fuseOptions: { include: 'score', threshold: 0.3 },
                id: 'ezo45t0000',
                defaultValue: '',
              },
              {
                label: 'Icon name',
                labelPosition: 'top',
                placeholder: '',
                description: '',
                tooltip: '',
                prefix: '',
                suffix: '',
                widget: { type: 'input' },
                inputMask: '',
                displayMask: '',
                allowMultipleMasks: false,
                customClass: '',
                tabindex: '',
                autocomplete: '',
                hidden: false,
                hideLabel: false,
                showWordCount: false,
                showCharCount: false,
                mask: false,
                autofocus: false,
                spellcheck: true,
                dataGridLabel: false,
                disabled: false,
                tableView: true,
                modalEdit: false,
                multiple: false,
                persistent: true,
                inputFormat: 'plain',
                protected: false,
                dbIndex: false,
                case: '',
                truncateMultipleSpaces: false,
                encrypted: false,
                redrawOn: '',
                clearOnHide: true,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                allowCalculateOverride: false,
                validateOn: 'change',
                validate: {
                  required: false,
                  pattern: '',
                  customMessage: '',
                  custom: '',
                  customPrivate: false,
                  json: '',
                  minLength: '',
                  maxLength: '',
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                },
                unique: false,
                errorLabel: '',
                errors: '',
                key: 'icon',
                tags: [],
                properties: {},
                conditional: { show: null, when: null, eq: '', json: '' },
                customConditional: '',
                logic: [],
                attributes: {},
                overlay: {
                  style: '',
                  page: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                type: 'textfield',
                input: true,
                refreshOn: '',
                addons: [],
                inputType: 'text',
                id: 'eked1r00000',
                defaultValue: '',
              },
              {
                label: 'Icon color',
                labelPosition: 'top',
                widget: 'choicesjs',
                placeholder: '',
                description: '',
                tooltip: '',
                customClass: '',
                tabindex: '',
                hidden: false,
                hideLabel: false,
                uniqueOptions: false,
                autofocus: false,
                dataGridLabel: false,
                disabled: false,
                tableView: true,
                modalEdit: false,
                multiple: false,
                dataSrc: 'values',
                data: {
                  values: [
                    {
                      label: "Purple '#F3F0FF', '#7048E8'",
                      value: '#F3F0FF_#7048E8',
                      bg: '#F3F0FF',
                      color: '#7048E8',
                    },
                    {
                      label: "Blue '#829FFF', '#001F85'",
                      value: '#829FFF_#001F85',
                      bg: '#829FFF',
                      color: '#001F85',
                    },
                    {
                      label: 'Gray #F5F6F7 #495057',
                      value: '#F5F6F7_#495057',
                      bg: '#F5F6F7',
                      color: '#495057',
                    },
                    {
                      label: 'Orange #FFF4E6 #F76707',
                      value: '#FFF4E6_#F76707',
                      bg: '#FFF4E6',
                      color: '#F76707',
                    },
                    {
                      label: 'Green #E6FCF5 0CA678#',
                      value: '#E6FCF5_0CA678#',
                      bg: '#E6FCF5',
                      color: '#0CA678',
                    },
                    {
                      label: 'Yellow #FFF9DB #F59F00',
                      value: '#FFF9DB_#F59F00',
                      bg: '#FFF9DB',
                      color: '#F59F00',
                    },
                    {
                      label: 'Red #FFF5F5 #F03E3E',
                      value: '#FFF5F5_#F03E3E',
                      bg: '#FFF5F5',
                      color: '#F03E3E',
                    },
                    {
                      label: 'Cyan #E3FAFC #45B0C1',
                      value: '#E3FAFC_#45B0C1',
                      bg: '#E3FAFC',
                      color: '#45B0C1',
                    },
                  ],
                  resource: '',
                  json: '',
                  url: '',
                  custom: '',
                },
                dataType: '',
                idPath: 'id',
                valueProperty: '',
                template:
                  '<span style="color: {{item.color}}; background-color: {{item.bg}};padding: 3px;">{{ item.label }}</span>',
                refreshOn: '',
                refreshOnBlur: '',
                clearOnRefresh: false,
                searchEnabled: true,
                selectThreshold: 0.3,
                readOnlyValue: false,
                customOptions: {},
                useExactSearch: false,
                persistent: true,
                protected: false,
                dbIndex: false,
                encrypted: false,
                clearOnHide: true,
                customDefaultValue: '',
                calculateValue: '',
                calculateServer: false,
                allowCalculateOverride: false,
                validateOn: 'change',
                validate: {
                  required: false,
                  onlyAvailableItems: false,
                  customMessage: '',
                  custom: '',
                  customPrivate: false,
                  json: '',
                  strictDateValidation: false,
                  multiple: false,
                  unique: false,
                },
                unique: false,
                errorLabel: '',
                errors: '',
                key: 'iconColor',
                tags: [],
                properties: {},
                conditional: { show: null, when: null, eq: '', json: '' },
                customConditional: '',
                logic: [],
                attributes: {},
                overlay: {
                  style: '',
                  page: '',
                  left: '',
                  top: '',
                  width: '',
                  height: '',
                },
                type: 'select',
                indexeddb: { filter: {} },
                selectFields: '',
                searchField: '',
                searchDebounce: 0.3,
                minSearch: 0,
                filter: '',
                limit: 100,
                redrawOn: '',
                input: true,
                prefix: '',
                suffix: '',
                showCharCount: false,
                showWordCount: false,
                allowMultipleMasks: false,
                addons: [],
                lazyLoad: true,
                authenticate: false,
                ignoreCache: false,
                fuseOptions: { include: 'score', threshold: 0.3 },
                id: 'ep4zmvu0',
                defaultValue: '',
              },
            ],
            placeholder: '',
            prefix: '',
            suffix: '',
            multiple: false,
            unique: false,
            refreshOn: '',
            dataGridLabel: false,
            widget: null,
            showCharCount: false,
            showWordCount: false,
            allowMultipleMasks: false,
            addons: [],
            tree: true,
            lazyLoad: false,
            id: 'edr88j4',
          },
          {
            type: 'button',
            label: 'Submit',
            key: 'submit',
            size: 'md',
            block: false,
            action: 'submit',
            disableOnInvalid: true,
            theme: 'primary',
            input: true,
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            dataGridLabel: true,
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: { type: 'input' },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: { show: null, when: null, eq: '' },
            overlay: { style: '', left: '', top: '', width: '', height: '' },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            addons: [],
            leftIcon: '',
            rightIcon: '',
            id: 'eqd8u4m',
          },
        ],
      }}
      onSubmit={async ({ data }) => {
        await onSubmit(data);
        refresh();
      }}
    />
  );
};

export default State;
