import Fieldset from 'formiojs/components/fieldset/Fieldset';
import config from './config';

class DataExtraction extends Fieldset {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Data Extraction AI',
      icon: 'file',
      group: 'custom',
      documentation: '',
      weight: 0,
      schema: DataExtraction.schema(),
    };
  }

  /**
     * This is the default schema of your custom component. It will "derive"
     * from the base class "schema" and extend it with its default JSON schema
     * properties. The most important are "type" which will be your component
     * type when defining new components.
     *
     * @param extend - This allows classes deriving from this component to
     *                 override the schema of the overridden class.
     *
     * {
  "label": "Data Grid",
  "reorder": false,
  "addAnotherPosition": "bottom",
  "layoutFixed": false,
  "enableRowGroups": false,
  "initEmpty": false,
  "tableView": false,
  "defaultValue": [
    {}
  ],
  "key": "dataGrid",
  "type": "datagrid",
  "input": true,
  "components": []
}
     */

  static schema() {
    return Fieldset.schema({
      type: 'fieldset',
      label: 'Data Extraction AI',
      key: 'dataExtraction',
      components: [
        {
          key: 'dataExtractionFileUpload1',
          type: 'dataExtractionFileUpload',
          input: true,
          label: 'Data Extraction File Upload',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            customMessage: null,
          },
          refreshOn: null,
          tableView: false,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: 'entity-extraction',
          description: null,
          placeholder: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'fileID',
          case: null,
          type: 'textfield',
          input: true,
          label: 'File ID',
          errors: null,
          hidden: true,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          clearOnHide: false,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
          inputMaskPlaceholderChar: null,
        },
        {
          key: 'ContractTitle',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Contract Title',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'EffectiveDate',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Effective Date',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
          inputMaskPlaceholderChar: null,
        },
        {
          key: 'ExpirationDate',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Expiration Date',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'PartiesInvolved',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Parties Involved',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'DescriptionofServices',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Description of Services',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'PaymentTerms',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Payment Terms',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'TerminationClauses',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Termination Clauses',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'GoverningLaw',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Governing Law',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'RepresentationsandWarranties',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Representations and Warranties',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'IndemnificationClauses',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Indemnification Clauses',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'DisputeResolution',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Dispute Resolution',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'Non-DisclosureAgreement',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Non-Disclosure Agreement',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'InsuranceProvisions',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Insurance Provisions',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'PermitsandLicenses',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Permits and Licenses',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'Notices',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Notices',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'Assignment',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Assignment',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
        {
          key: 'Signatures',
          case: null,
          type: 'textfield',
          input: true,
          label: 'Signatures',
          errors: null,
          prefix: null,
          suffix: null,
          overlay: {
            top: null,
            left: null,
            page: null,
            style: null,
            width: null,
            height: null,
          },
          tooltip: null,
          redrawOn: null,
          tabindex: null,
          validate: {
            json: null,
            custom: null,
            pattern: null,
            maxLength: null,
            minLength: null,
            customMessage: null,
          },
          inputMask: null,
          refreshOn: null,
          tableView: true,
          errorLabel: null,
          conditional: {
            eq: null,
            json: null,
          },
          customClass: null,
          description: null,
          displayMask: null,
          placeholder: null,
          autocomplete: null,
          calculateValue: null,
          customConditional: null,
          customDefaultValue: null,
        },
      ],
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = config;

  /**
   * Called when the component has been instantiated. This is useful to define
   * default instance variable values.
   *
   * @param component - The JSON representation of the component created.
   * @param options - The global options for the renderer
   * @param data - The contextual data object (model) used for this component.
   */
  constructor(component, options, data) {
    super(component, options, data);
  }

  /**
   * Called immediately after the component has been instantiated to initialize
   * the component.
   */
  init() {
    super.init();
  }

  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
}

export default DataExtraction;
