import React, { FC } from 'react';

import {
  AdvancedRadioValue,
  FieldTemplate,
  Project,
} from '@contractool/schema';

import { getWorkflow } from 'hooks/workflows';

import Badge from 'components/Badge';
import { Icon, Icons } from 'components/Icon';

export const ProjectLabels: FC<{ project: Project }> = ({ project }) => {
  let fields = getWorkflow(project.workflow).fields.meta_data.filter(
    (field: FieldTemplate) => {
      return field.showInProjectHeader;
    },
  );

  return (
    <>
      {project.state.extra.icon && (
        <span>
          <div className="pr-3">
            <Badge
              icon={
                <Icon name={project.state.extra.icon?.name as Icons} size={4} />
              }
              colors={{
                backgroundColor: project.state.extra.icon.background,
                color: project.state.extra.icon.foreground,
              }}
            >
              {project.state.label}
            </Badge>
          </div>
        </span>
      )}
      {fields.map((field: FieldTemplate) => {
        let selected = field.advancedRadioValues.filter(
          (option: AdvancedRadioValue) => {
            return option.value === project.fields[field.name];
          },
        );
        if (selected.length === 0) {
          return null;
        }

        return (
          <span key={field.name}>
            {selected[0]?.icon && (
              <div className="pr-3">
                <Badge
                  icon={<Icon name={selected[0].icon} size={4} />}
                  color={selected[0].color}
                >
                  {selected[0].title}
                </Badge>
              </div>
            )}
          </span>
        );
      })}
    </>
  );
};
