import React, { useCallback, useContext, useEffect } from 'react';
import { Button } from '../components/Button';
import { Form, getValue } from '../components/Form';
import { http } from '../utils/http';
import { Delegation, User, UserGroup } from '@contractool/schema';
import { Tabs, Tab, RouteTabsV2 } from '../components/Tabs';
import { AuthContext, GuardContext } from 'contexts';
import { useToasts } from 'hooks';
import { Modal } from '../components/Modal';
import { timezones } from '../utils/timezones';
import { translate } from 'utils/translations';
import { AvatarUpload } from '../components/Avatar';
import { useHistory } from 'react-router-dom';
import { useBigLoader } from 'components/Loader';
import { UserGroupChip } from 'components/UserGroupChip';
import { Message } from 'components/Message';
import moment from 'moment';
import { getBackupDelegation, getFilterDelegations } from 'utils/delegation';
import { Confirmation } from 'components/Confirmation';

export function Profile() {
  const { success, error } = useToasts();
  const { update: updateUser } = useContext(AuthContext);
  const { user } = useContext(GuardContext);

  const history = useHistory<{ prevLocation: string }>();
  const bigLoader = useBigLoader();
  const onUpdate = useCallback(
    (user) => {
      updateUser(user);
    },
    [updateUser],
  );

  // update user on mount for request delegation
  useEffect(() => {
    http.get<User>('/api/auth/profile').then((res) => updateUser(res.data));
  }, []);

  const onCancel = useCallback(() => {
    if (history.location.state === undefined) {
      history.goBack();
    } else {
      history.push(history.location.state.prevLocation);
    }
  }, [history]);

  const handleCancelRequest = useCallback(async (id: number) => {
    bigLoader.start(translate('Canceling your delegation request...'));

    // Reject delegation request
    http
      .post(`/api/delegation/cancel/${id}`)
      .then(() => {
        bigLoader.stop();
        success(`${translate('Your delegation request was canceled')}.`);
        // Update user with new delegation
        http.get<User>('/api/auth/profile').then((res) => updateUser(res.data));
      })
      .catch(() => {
        bigLoader.stop();
        error(`${translate('Your delegation request was not canceled')}.`);
      });
  }, []);

  const handleDenyRequest = useCallback(async (id: number) => {
    bigLoader.start(translate('Denying your delegation request...'));

    // Reject delegation request
    http
      .post(`/api/delegation/deny/${id}`)
      .then(() => {
        bigLoader.stop();
        success(`${translate('You denied this delegation request')}.`);
        // Update user with new delegation
        http.get<User>('/api/auth/profile').then((res) => updateUser(res.data));
      })
      .catch(() => {
        bigLoader.stop();
        error(`${translate('Your delegation request was not denied')}.`);
      });
  }, []);

  const handleApproveRequest = useCallback(async (id: number) => {
    bigLoader.start(translate('Accepting your delegation request...'));

    // Approve delegation request
    http
      .post(`/api/delegation/approve/${id}`)
      .then(() => {
        bigLoader.stop();
        success(`${translate('You accepted this delegation request')}.`);
        // Update user with new delegation
        http.get<User>('/api/auth/profile').then((res) => updateUser(res.data));
      })
      .catch(() => {
        bigLoader.stop();
        error(
          `${translate(
            'Action was not successful. Please contact administrator. ',
          )}.`,
        );
      });
  }, []);

  const getStatusMessage = (delegations: Delegation[]) => {
    if (delegations.length > 0) {
      if (delegations?.[0]?.is_active == false) {
        switch (delegations?.[0]?.state) {
          case 'pending':
            return (
              <div className="mb-6">
                <Message.Warning>Delegation Status: Pending</Message.Warning>
              </div>
            );

          case 'approved':
            return (
              <div className="mb-6">
                <Message.Success>Delegation Status: Approved</Message.Success>
              </div>
            );

          case 'denied':
            return (
              <div className="mb-6">
                <Message.Error>Delegation Status: Denied</Message.Error>
              </div>
            );

          default:
            return <></>;
        }
      }
      switch (delegations?.[0]?.state) {
        case 'approved':
          return (
            <div className="mb-6">
              <Message.Success>Delegation Status: Active</Message.Success>
            </div>
          );
        default:
          return <></>;
      }
    }
  };

  const delegations = user?.delegations.filter(
    (item: Delegation) => item != null,
  );

  const approvedOrActiveDelegations = delegations.filter(
    (item: Delegation) =>
      item.backup.id == user.id &&
      item.starts_at <= moment().format('YYYY-MM-DD') &&
      (item.state == 'approved' || item.is_active == true),
  );

  const delegatorDelegations = delegations.filter(
    (item: Delegation) => item.delegator.id == user.id,
  );

  const formInitialValues = () => {
    const delegation = getFilterDelegations(delegations, user.id);
    const isPendingOrApproved =
      delegation?.state == 'pending' || delegation?.state == 'approved';

    return isPendingOrApproved
      ? {
          starts_at: delegation.starts_at,
          ends_at: delegation.ends_at,
          backup_id: delegation.backup,
        }
      : {
          starts_at: null,
          ends_at: null,
          backup_id: null,
        };
  };

  const isFormReadOnly = () => {
    const delegation = getFilterDelegations(delegations, user.id);
    return delegation?.state == 'pending' || delegation?.state == 'approved';
  };

  const pendingDelegations = delegations.filter(
    (item: Delegation) => item.backup.id == user.id && item.state == 'pending',
  );

  const approvedDelegations = delegations.filter(
    (item: Delegation) =>
      item.backup.id == user.id &&
      item.state == 'approved' &&
      item.is_active == false,
  );

  const activeDelegations = delegations.filter(
    (item: Delegation) =>
      item.backup.id == user.id &&
      item.state == 'approved' &&
      item.is_active == true,
  );

  const hasDelegationRequests =
    pendingDelegations.length > 0 ||
    approvedDelegations.length > 0 ||
    activeDelegations.length > 0;

  const renderNotificationBadge = () => (
    <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-semibold bg-blue-700 text-white rounded-full">
      1
    </span>
  );

  const renderDelegationInfo = (delegation: Delegation) => (
    <>
      <strong>{delegation?.delegator.name}</strong> set their status to Away
      from <strong>{delegation?.starts_at}</strong> until{' '}
      <strong>{delegation?.ends_at}</strong> and would like to you to take over
      their current ongoing projects and approval tasks.{' '}
    </>
  );

  // TODO: reset form after cancel delegation request

  return (
    <Modal
      compact={true}
      onClose={onCancel}
      heading={translate('Profile')}
      contentClassName="h-149"
    >
      <div className="flex items-center mb-11">
        <div>
          <AvatarUpload
            user={user}
            onUpload={(user) => {
              success(`${translate('Your avatar was successfully changed')}.`);
              onUpdate(user);
            }}
            className="w-32 h-32"
          />
        </div>

        <div className="ml-11">
          <h2 className="text-xl">{user.name}</h2>
          <div
            className="text-gray-600 overflow-auto nice-horizontal-scroll-bar pt-2"
            style={{ maxWidth: '450px' }}
          >
            <div className="flex">
              {user.groups.map((group: UserGroup) => (
                <UserGroupChip key={`chip-${group.key}`} group={group} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <RouteTabsV2>
        <Tab name="general" heading={translate('General')} className="pt-8">
          <Form
            initialValues={user}
            onSubmit={(values) => {
              bigLoader.start(translate('Updating your profile...'));

              return http.put<User>('/api/auth/profile', values);
            }}
            onError={() => {
              bigLoader.stop();
            }}
            onSuccess={(user: User) => {
              bigLoader.stop();
              success(`${translate('Your profile was successfully updated')}.`);
              onUpdate(user);
            }}
          >
            <Form.TextInput
              name="name"
              label={translate('Name')}
              className="mb-6"
              autoFocus={true}
            />
            <Form.TextInput name="email" label="Email" className="mb-6" />
            <Form.Dropdown
              name="timezone"
              label={translate('Timezone')}
              options={timezones}
              autocomplete
            />

            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Cancel')}
              </Button>

              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </Form>
        </Tab>
        <Tab name="password" heading={translate('Password')} className="pt-8">
          <Form
            initialValues={{ password: '', password_confirmation: '' }}
            onSubmit={(values) => {
              bigLoader.start(translate('Updating your password...'));

              return http.put('/api/auth/password', values);
            }}
            onError={() => {
              bigLoader.stop();
            }}
            onSuccess={(user: User) => {
              bigLoader.stop();
              success(
                `${translate('Your password was successfully updated')}.`,
              );
              onUpdate(user);
            }}
            clearAtSuccess={true}
          >
            <Form.TextInput
              name="current_password"
              type="password"
              label={translate('Current password')}
              className="mb-6"
              autoFocus={true}
            />
            <Form.TextInput
              name="password"
              type="password"
              label={translate('New password')}
              className="mb-6"
            />
            <Form.TextInput
              name="password_confirmation"
              type="password"
              label={translate('Confirm new password')}
            />

            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Cancel')}
              </Button>

              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </Form>
        </Tab>
        <Tab
          name="away_status"
          heading={`${translate('Delegate Authority')}`}
          className="pt-8"
          hidden={approvedOrActiveDelegations.length > 0}
        >
          <Form
            name="awayStatus"
            initialValues={formInitialValues()}
            onSubmit={(values) => {
              bigLoader.start(translate('Sending invitation...'));
              return http.post('/api/delegation', {
                starts_at: values.starts_at,
                ends_at: values.ends_at,
                // @ts-ignore
                backup_id: values?.backup_id?.value,
              });
            }}
            onSuccess={async () => {
              bigLoader.stop();
              success(
                `${translate('Your delegation was successfully created')}.`,
              );
              await http.get<User>('/api/auth/profile').then((res) => {
                updateUser(res.data);
              });
            }}
            onError={(data) => {
              bigLoader.stop();
              if (data.message) {
                error(`${translate(data.message)}`);
              }
            }}
          >
            <Form.Context>
              {({ values, clearForm }) => (
                <>
                  {getStatusMessage(delegatorDelegations)}
                  {approvedOrActiveDelegations.length > 0 ? (
                    <p>
                      <strong>{delegations?.[0]?.backup.name}</strong> confirmed
                      to be your backup from{' '}
                      <strong>{delegations?.[0]?.starts_at}</strong> until{' '}
                      <strong>{delegations?.[0]?.ends_at}</strong> and will over
                      your current ongoing projects and approval tasks.
                    </p>
                  ) : (
                    <>
                      <Form.DatePicker
                        name="starts_at"
                        label={translate('Start date')}
                        placeholder={translate('Select a date')}
                        formatInput="dd MMM yyyy"
                        required
                        since={moment().format('YYYY-MM-DD')}
                        readOnly={isFormReadOnly()}
                      />
                      <Form.DatePicker
                        name="ends_at"
                        label={translate('End date')}
                        placeholder={translate('Select a date')}
                        formatInput="dd MMM yyyy"
                        since={
                          values.starts_at
                            ? moment(values.starts_at).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD')
                        }
                        required
                        readOnly={isFormReadOnly()}
                      />

                      <Form.UserDropdown2
                        endpoint="api/users/autocomplete"
                        params={{ deleted: false }}
                        name="backup_id"
                        label={translate('Backup user')}
                        placeholder={translate('Select a user')}
                        autocomplete
                        required
                        readOnly={isFormReadOnly()}
                        isMulti={false}
                        options={[]}
                      />
                    </>
                  )}

                  <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={onCancel}>
                      {translate('Cancel')}
                    </Button>

                    {delegations.filter(
                      (item: Delegation) =>
                        item.delegator.id == user.id &&
                        (item.state == 'approved' ||
                          item.state == 'pending' ||
                          item.is_active == true),
                    ).length > 0 ? (
                      <Confirmation
                        onConfirm={() => {
                          handleCancelRequest(delegations?.[0].id);
                          clearForm();
                        }}
                        trigger={({ onClick }) => (
                          <Button onClick={onClick} color="red">
                            Cancel request
                          </Button>
                        )}
                        heading="Are you sure?"
                        buttonText="Yes, cancel"
                        color="red"
                      >
                        Do you really want to cancel this delegation request?
                      </Confirmation>
                    ) : (
                      <Form.Submit>{translate('Send request')}</Form.Submit>
                    )}
                  </Modal.Footer>
                </>
              )}
            </Form.Context>
          </Form>
        </Tab>

        <Tab
          name="delegation"
          heading={
            <>
              {translate('Delegation requests')}{' '}
              {pendingDelegations.length > 0 && renderNotificationBadge()}
            </>
          }
          className="pt-8"
        >
          <div>
            {pendingDelegations.length > 0 &&
              renderDelegationInfo(pendingDelegations[0])}
          </div>

          {approvedDelegations.map((delegation) => (
            <p key={delegation.id}>
              You confirmed to be a backup for{' '}
              <strong>{delegation.delegator.name}</strong> from{' '}
              <strong>{delegation.starts_at}</strong> until{' '}
              <strong>{delegation.ends_at}</strong>.
            </p>
          ))}

          {activeDelegations.map((delegation) => (
            <p key={delegation.id}>
              You confirmed to be a backup for{' '}
              <strong>{delegation.delegator.name}</strong> from{' '}
              <strong>{delegation.starts_at}</strong> until{' '}
              <strong>{delegation.ends_at}</strong>. In case of any change
              needed, please contact your admin
            </p>
          ))}

          {hasDelegationRequests ? <></> : <>No delegation request</>}

          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={onCancel}>
              {translate('Cancel')}
            </Button>
            {delegations.filter(
              (item) => item.backup.id == user.id && item.state == 'pending',
            ).length > 0 && (
              <div className="flex space-x-3">
                <Confirmation
                  onConfirm={() => handleDenyRequest(delegations?.[0].id)}
                  trigger={({ onClick }) => (
                    <Button onClick={onClick} color="red">
                      {translate('Deny')}
                    </Button>
                  )}
                  heading="Are you sure?"
                  buttonText="Yes, deny"
                  color="red"
                >
                  Do you really want to deny this delegation request?
                </Confirmation>

                <Confirmation
                  onConfirm={() => handleApproveRequest(delegations?.[0].id)}
                  trigger={({ onClick }) => (
                    <Button onClick={onClick}>{translate('Accept')}</Button>
                  )}
                  heading="Are you sure?"
                  buttonText="Yes, accept"
                  color="yellow"
                >
                  Do you really want to accept this delegation request?
                </Confirmation>
              </div>
            )}
            {getBackupDelegation(delegations, user.id)?.is_active == false &&
              getBackupDelegation(delegations, user.id)?.state ==
                'approved' && (
                <Confirmation
                  onConfirm={() => handleCancelRequest(delegations?.[0].id)}
                  trigger={({ onClick }) => (
                    <Button onClick={onClick} color="red">
                      Cancel delegation
                    </Button>
                  )}
                  heading="Are you sure?"
                  buttonText="Yes, cancel"
                  color="red"
                >
                  Do you really want to cancel this delegation request?
                </Confirmation>
              )}
          </Modal.Footer>
        </Tab>
      </RouteTabsV2>
    </Modal>
  );
}
