import { Workflow } from '@contractool/schema/Workflow';
import { Workspace } from '@contractool/schema/Workspace';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  workspace: Workspace;
  children?: ReactNode;
  workflow: Workflow;
  numberOfWorkflows: number;
};

const NewProjectLink: FC<Props> = ({
  workspace,
  children,
  workflow,
  numberOfWorkflows,
}) => {
  const serialize: any = function (obj: any, prefix: any) {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v),
        );
      }
    }
    return str.join('&');
  };
  if (numberOfWorkflows > 1) {
    return (
      <Link
        key={`link${0}`}
        to={{
          pathname: '/projects/new/workflow',
          search: `?workspace=${workspace.key}`,
          state: workspace.key,
        }}
        className="group cursor-pointer border rounded-lg p-8 col-span-${span} flex flex-col justify-between h-full hover:text-blue-700 hover:border-blue-700 focus:outline-none focus:text-blue-700 focus:border-blue-700"
      >
        {children}
      </Link>
    );
  }

  const button = workflow?.kinds?.[0]?.buttons?.[0];
  let searchParams = serialize({
    ...button?.extra,
    kind: button?.label,
  });
  if (!button) {
    searchParams = `workflow=${workflow?.key}`;
  }

  return (
    <Link
      key={`link${0}`}
      to={{
        pathname: '/projects/new/form',
        search: `?${searchParams}`,
        state: button?.extra?.workflow || workflow.key,
      }}
      className="group cursor-pointer border rounded-lg p-8 col-span-${span} flex flex-col justify-between h-full hover:text-blue-700 hover:border-blue-700 focus:outline-none focus:text-blue-700 focus:border-blue-700"
    >
      {children}
    </Link>
  );
};
export default NewProjectLink;
