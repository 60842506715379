import React, { useContext } from 'react';
import { Components } from '@formio/react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { RouteTabs, Tab } from 'components/Tabs';
import { Message } from 'components/Message';
import components from 'components/Formio/Custom';

import Breadcrumb from './Breadcrumb';
import Role from './Role';
import State from './State';
import Settings from './Settings';
import Rule from '../Rule';
import Delay from '../Delay';

import { URL_API_WORKFLOW } from '@constants/workflow';
import { URL_API_INTEGRATION } from '@constants/integration';
import { URL_API_RULES } from '@constants/rule';
import { FilterTemplate } from '@contractool/schema';

import { mutation } from 'utils';
import { createWorkflow } from 'utils/integration/workflow';
import { translate } from 'utils/translations';
import { contentFilter } from 'utils/search';
import { useRequest } from 'hooks/useRequest';

import { RulesForm } from 'types/output/rules';
import { WorkflowResponseApiType } from 'types/api/workflow';
import { IntegrationResponseApiType } from 'types/api/integration';
import { AppContext } from 'contexts';
import FieldsBuilder from 'components/Integration/FieldsBuilder';

type Props = {
  rules: RulesForm[];
  refreshRules: () => void;
  searchQuery: string;
  isLoading: boolean;
  templates: FilterTemplate[];
};

const Workflow: React.FC<Props> = ({
  rules,
  refreshRules,
  searchQuery,
  isLoading,
  templates,
}) => {
  Components.setComponents(components);

  const { refresh: refreshConfig } = useContext(AppContext);

  const { id, wid }: { id: string; wid: string } = useParams();
  const { url } = useRouteMatch();

  const [integration, { isLoading: isLoadingIntegration }] =
    useRequest<IntegrationResponseApiType>(
      `${URL_API_INTEGRATION}/${id}`,
      null,
    );
  const [
    workflowData,
    { refresh: refreshWorkflow, isLoading: isLoadingWorkflow },
  ] = useRequest<WorkflowResponseApiType>(`${URL_API_WORKFLOW}/${wid}`, null);

  const workflow = createWorkflow(workflowData);

  const wrongPath =
    !integration || !workflow || integration?.id !== workflow?.integrationId;

  if (isLoadingWorkflow || isLoadingIntegration) {
    return <>{translate('Loading workflow...')}</>;
  }

  if (wrongPath) {
    return (
      <Message.Error>{translate('The workflow was not found.')}</Message.Error>
    );
  }

  const { states = [] } = workflow?.states;

  const rulesTab = (
    <Tab name="rule" heading={translate('Rules')} className="py-8">
      <Rule
        rules={contentFilter<RulesForm>(rules, searchQuery, workflow?.key)}
        isLoading={isLoading}
        templates={templates}
        integrationId={id}
        workflowId={wid}
        onDelete={(id) =>
          mutation({
            url: `${URL_API_RULES}/${id}`,
            method: 'DELETE',
            refresh: refreshRules,
            message: 'The rule has been deleted',
          })
        }
      />
    </Tab>
  );

  const settingsTab = (
    <Tab name="settings" heading={translate('Settings')} className="py-8">
      <Delay>
        <Settings
          onSubmit={(settings_config) =>
            mutation({
              url: `${URL_API_WORKFLOW}/${wid}`,
              method: 'PUT',
              payload: { settings_config },
              refresh: refreshWorkflow,
              message: 'The workflow has been edited',
            })
          }
          value={workflow?.settings}
        />
      </Delay>
    </Tab>
  );

  const fieldsTab = (
    <Tab name="field" heading={translate('Fields')} className="py-8">
      <FieldsBuilder
        onSubmit={(fields_config) =>
          mutation({
            url: `${URL_API_WORKFLOW}/${wid}`,
            method: 'PUT',
            payload: { fields_config },
            refresh: () => {
              refreshWorkflow();
              refreshConfig();
            },
            message: 'The workflow has been edited',
          })
        }
        value={workflow?.fields}
      />
    </Tab>
  );

  const rolesTab = (
    <Tab name="role" heading={translate('Roles')} className="py-8">
      <Delay>
        <Role
          onSubmit={(roles_config) =>
            mutation({
              url: `${URL_API_WORKFLOW}/${wid}`,
              method: 'PUT',
              payload: { roles_config },
              refresh: refreshWorkflow,
              message: 'The workflow has been edited',
            })
          }
          value={workflow?.roles}
          states={states}
        />
      </Delay>
    </Tab>
  );

  const statesTab = (
    <Tab name="state" heading={translate('States')} className="py-8">
      <Delay>
        <State
          onSubmit={(states_config) =>
            mutation({
              url: `${URL_API_WORKFLOW}/${wid}`,
              method: 'PUT',
              payload: { states_config },
              refresh: refreshWorkflow,
              message: 'The workflow has been edited',
            })
          }
          value={workflow?.states}
        />
      </Delay>
    </Tab>
  );

  return (
    <>
      <Breadcrumb workflow={workflow} integrationTitle={integration?.title} />
      <RouteTabs match={`${url}/:tab`}>
        {integration?.is_legacy
          ? [rulesTab, settingsTab].map((tab) => tab)
          : [rulesTab, settingsTab, fieldsTab, rolesTab, statesTab].map(
              (tab) => tab,
            )}
      </RouteTabs>
    </>
  );
};

export default Workflow;
