import React from 'react';
import { useTable, useSortBy, usePagination, Cell, Row } from 'react-table';

import Table from './components/Table';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import TableRow from './components/TableRow';
import NoResults from './components/NoResults';
import Loading from './components/Loading';
import { Pagination } from 'components/Pagination';

type Props = {
  columns: any;
  data: object[];
  isLoading: boolean;
  pageSize?: number;
  onClick?: (e: Cell<object, any>) => void;
};

const TableWithPagination: React.FC<Props> = ({
  columns,
  data,
  isLoading,
  pageSize = 10,
  onClick,
}) => {
  const {
    headerGroups,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex },
    getTableProps,
    getTableBodyProps,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageSize } as any,
      defaultColumn: {
        width: 'auto',
      },
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <Table getTableProps={getTableProps}>
        <TableHead headerGroups={headerGroups} />
        <TableBody getTableBodyProps={getTableBodyProps}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {page.length === 0 ? (
                <NoResults />
              ) : (
                page.map((row: Row) => (
                  <TableRow
                    key={row.id}
                    row={row}
                    prepareRow={prepareRow}
                    onClick={onClick}
                  />
                ))
              )}
            </>
          )}
        </TableBody>
      </Table>
      {pageOptions.length > 1 && (
        <div className="mt-3">
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={pageOptions.length}
            totalBlocks={7}
            onSelect={(page) => gotoPage(page - 1)}
          />
        </div>
      )}
    </>
  );
};

export default TableWithPagination;
