import {
  URL_CREATE_INTEGRATION_WORKFLOW,
  URL_EDIT_INTEGRATION_WORKFLOW,
} from './integration';

// URL - Workflow Detail
export const URL_WORKFLOW_DETAIL = '/integration/:id/workflow/:wid';
// URL - Workflow Settings
// URL - Workflow Fields
// URL - Workflow Roles
// URL - Workflow States
// URL - Workflow Rules
export const URL_WORKFLOW_RULE = '/integration/:id/workflow/:wid/rule';
export const URL_CREATE_WORKFLOW_RULE =
  '/integration/:id/workflow/:wid/rule/create';
export const URL_EDIT_WORKFLOW_RULE =
  '/integration/:id/workflow/:wid/rule/:rid/edit';
export const URL_WORKFLOW_RULE_ATTACHMENTS =
  '/integration/:id/workflow/:wid/rule/create/attachments';
export const URL_WORKFLOW_RULE_DASHBOARD = [
  URL_WORKFLOW_RULE,
  URL_CREATE_WORKFLOW_RULE,
  URL_EDIT_WORKFLOW_RULE,
];

export const URL_WORKFLOW_DASHBOARD = [
  URL_WORKFLOW_DETAIL,
  URL_CREATE_INTEGRATION_WORKFLOW,
  URL_EDIT_INTEGRATION_WORKFLOW,
];

// API
export const URL_API_WORKFLOWS = '/api/workflows';
export const URL_API_WORKFLOW = '/api/workflow';
